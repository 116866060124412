const OrderListType = {
  ORDERLIST_UPDATE_LIST: "ORDERLIST_UPDATE_LIST",
  ORDERLIST_UPDATE_NOT_FINISHED_LIST: "ORDERLIST_UPDATE_NOT_FINISHED_LIST",
  ORDERLIST_ADD_ORDER: "ORDERLIST_ADD_ORDER",
  ORDERLIST_UPDATE_AN_ORDER: "ORDERLIST_UPDATE_AN_ORDER",
  ORDERLIST_ADD_ORDERS_TO_THE_LIST: "ORDERLIST_ADD_ORDERS_TO_THE_LIST",
  ORDERLIST_UPDATE_PAGE_REFRESH: "ORDERLIST_UPDATE_PAGE_REFRESH",
  ORDERLIST_UPDATE_SEARCH_LIST: "ORDERLIST_UPDATE_SEARCH_LIST"
}

export default OrderListType;
