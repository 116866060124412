import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

import './styles.scss';

import Input from '../../components/Input';
import Button from '../../components/Buttons/FormButton';
import SubmitButton from '../../components/Buttons/SubmitButton';

import Template from '../../templates/Backoffice';
import Form from '../../templates/Form';
import DetailsView from '../../templates/Details';

import User from '../../core/User';
import UsersTypes from '../../redux/types/users';

class UsersUpdatePage extends React.Component{

  constructor(props){
    super(props);
    this.state = {
      id: this.props.route.split('/')[2],
      plainRole: _.get(this.props, 'user.roles[0]', undefined)
    }
  }

  componentDidMount(){
    new User({}).setToken(this.props.token).findOne(this.state.id).then(data => this.setState(data)).catch(err => this.props.push("/user"));
  }

  checkValues = () => {
    let errors = {};
    let noError = true;
    // if(!this.state.name || this.state.name === ""){
    //   errors.name = this.context.t("is_required");
    //   noError = false;
    // }
    // if(!this.state.firstname || this.state.firstname === ""){
    //   errors.firstname = this.context.t("is_required");
    //   noError = false;
    // }
    // if(!this.state.email || this.state.email === "" || !(/^[a-zA-Z0-9.-_]+@[a-zA-Z0-9.-_]+\.[a-z]+/.test(this.state.email))){
    //   errors.email = this.context.t("is_required");
    //   noError = false;
    // }
    // if(!this.state.phonenumber || this.state.phonenumber === "" || this.state.phonenumber.length < 10){
    //   errors.phonenumber = this.context.t("is_required");
    //   noError = false;
    // }
    if(this.state.password1 !== this.state.password2 || (this.state.password1.length > 0 && this.state.password1.length <= 8)){
      errors.password = this.context.t("is_required_and_differents");
      noError = false;
    }
    // if(!this.state.company || !this.state.company.name || !this.state.company.id){
    //   errors.company = this.context.t("is_required");
    //   noError = false;
    // }
    if(!(new User().getAvailableRoles().includes(this.state.plainRole)) ){
      errors.role = this.context.t("is_required");
      noError = false;
    }
    // if(!this.state.birth || !(/(01|02|03|04|05|06|07|08|09|10|11|12|13|14|15|16|17|18|19|20|21|22|23|24|25|26|27|28|29|30|31)\/(01|02|03|04|05|06|07|08|09|10|11|12)\/(1|2)[0-9]{3}/.test(this.state.birth)) || this.state.birth.length !== 10){
    //   errors.birth = this.context.t("is_required");
    //   noError = false;
    // }
    this.setState({errors: errors})
    return noError;
  }

  onClickConfirm = (e) => {
    let user = this.state;
    this.checkValues();
    if(this.props.token && this.props.user.roles.includes("ROLE_ADMIN")){
      new User({
        password: user.password1 ? user.password1 : undefined,
        role: user.plainRole ? user.plainRole : undefined
      }).setToken(this.props.token).update(this.state.id).then(data => {
        this.props.updateUserList(this.props.token);
        this.props.push('/user/' + this.state.id);
      });
    }
  }

  render(){
    if(this.state.fullname === undefined || this.state.fullname === null || this.state.fullname === ""){
      return(
        <Template>
          <DetailsView isLoading />
        </Template>
      );
    }
    return(
      <Template>
        <Form className="users-pages">
          <h1>{this.state.fullname}</h1>
          <div className="section">
            <div>
              <h2>{this.context.t('basic_infos')}</h2>
              {/*<Input
                getValue={value => this.setState({name: value}, () => { if(this.state.clicked) this.checkValues() })}
                placeholder={this.context.t('name')}
                priorityValue={_.get(this.state, 'name', false) ? this.state.name : ""}
                error={_.get(this.state, 'errors.name', false) ? true : false}
                autoComplete={false}
              />
              <Input
                getValue={value => this.setState({firstname: value}, () => { if(this.state.clicked) this.checkValues() })}
                placeholder={this.context.t('firstname')}
                priorityValue={_.get(this.state, 'firstname', false) ? this.state.firstname : ""}
                error={_.get(this.state, 'errors.firstname', false) ? true : false}
                autoComplete={false}
              />
              <Input
                getValue={value => this.setState({phonenumber: value}, () => { if(this.state.clicked) this.checkValues() })}
                placeholder={this.context.t('phonenumber')}
                priorityValue={_.get(this.state, 'phonenumber', false) ? this.state.phonenumber : ""}
                error={_.get(this.state, 'errors.phonenumber', false) ? true : false}
                autoComplete={false}
              />
              <Input
                getValue={value => this.setState({email: value}, () => { if(this.state.clicked) this.checkValues() })}
                placeholder={this.context.t('email')}
                priorityValue={_.get(this.state, 'email', false) ? this.state.email : ""}
                error={_.get(this.state, 'errors.email', false) ? true : false}
                autoComplete={false}
              />
              <Input
                getValue={value => this.setState({birth: value}, () => { if(this.state.clicked) this.checkValues() })}
                placeholder={this.context.t('birthdate_format')}
                priorityValue={_.get(this.state, 'birth', false) ? this.state.birth : ""}
                error={_.get(this.state, 'errors.birth', false) ? true : false}
                autoComplete={false}
              />*/}
              <Input
                type="password"
                getValue={value => this.setState({password1: value}, () => { if(this.state.clicked) this.checkValues() })}
                placeholder={this.context.t('password')}
                priorityValue={_.get(this.state, 'password1', false) ? this.state.password1 : ""}
                error={_.get(this.state, 'errors.password', false) ? true : false}
                autoComplete={false}
              />
              <Input
                type="password"
                getValue={value => this.setState({password2: value}, () => { if(this.state.clicked) this.checkValues() })}
                placeholder={this.context.t('rewrite_password')}
                priorityValue={_.get(this.state, 'password2', false) ? this.state.password2 : ""}
                error={_.get(this.state, 'errors.password', false) ? true : false}
                autoComplete={false}
              />
            </div>
            {/*<div>
              <h2>{this.context.t('company')}</h2>
              <CompanyAutocomplete
                placeholder={this.context.t('company_name')}
                getValue={(value) => {
                  this.setState({company: {
                    id: value.id,
                    name: value.name
                  }}, () => { if(this.state.clicked) this.checkValues() })
                }}
                priorityValue={_.get(this.state, 'company.name', undefined)}
                token={this.props.token}
                error={_.get(this.state, 'errors.company', false) ? true : false}
              />
              <div>
                <Button
                  onClick={() => this.setState({white_card: true})}
                  label={this.context.t('white_card')}
                  selected={_.get(this.state, 'white_card') === true ? true : false}
                />
                <Button
                  onClick={() => this.setState({white_card: false})}
                  label={this.context.t('no_white_card')}
                  selected={_.get(this.state, 'white_card') === false ? true : false}
                />
              </div>
            </div>*/}
            <div>
              <h2>{this.context.t('user_role')}</h2>
              <div>
                <Button
                  onClick={() => this.setState({plainRole: "ROLE_ADMIN"}, () => { if(this.state.clicked) this.checkValues() })}
                  label={this.context.t('role_admin')}
                  selected={_.get(this.state, 'plainRole') === "ROLE_ADMIN" ? true : false}
                  error={_.get(this.state, 'errors.role', false) ? true : false}
                />
                <Button
                  onClick={() => this.setState({plainRole: "ROLE_DISPATCH"}, () => { if(this.state.clicked) this.checkValues() })}
                  label={this.context.t('role_dispatch')}
                  selected={_.get(this.state, 'plainRole') === "ROLE_DISPATCH" ? true : false}
                  error={_.get(this.state, 'errors.role', false) ? true : false}
                />
              </div>
              <div>
                <Button
                  onClick={() => this.setState({plainRole: "ROLE_DRIVER"}, () => { if(this.state.clicked) this.checkValues() })}
                  label={this.context.t('role_driver')}
                  selected={_.get(this.state, 'plainRole') === "ROLE_DRIVER" ? true : false}
                  error={_.get(this.state, 'errors.role', false) ? true : false}
                />
                <Button
                  onClick={() => this.setState({plainRole: "ROLE_USER"}, () => { if(this.state.clicked) this.checkValues() })}
                  label={this.context.t('role_user')}
                  selected={_.get(this.state, 'plainRole') === "ROLE_USER" ? true : false}
                  error={_.get(this.state, 'errors.role', false) ? true : false}
                />
              </div>
            </div>
            <div>
              <div>
                <SubmitButton
                  isCancel
                  label={this.context.t("cancel")}
                  onClick={() => this.props.push("/" + this.props.route.split('/')[1] + "/" + this.props.route.split('/')[2])}
                />
                <SubmitButton
                  label={this.context.t("confirm")}
                  onClick={(e) => {this.onClickConfirm(e); this.setState({clicked: true})}}
                />
              </div>
            </div>
          </div>
        </Form>
      </Template>
    );
  }
}

UsersUpdatePage.contextTypes = {
  t: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
  route: state.routing.locationBeforeTransitions.pathname,
  token: state.currentUser.token,
  user: state.currentUser.user,
})
const mapDispatchToProps = (dispatch) => ({
  push: (route) => dispatch(push(route)),
  updateUserList: (token) => {
    new User().setToken(token).getAllUsers().then(data => {
      dispatch({
        type: UsersTypes.USERS_UPDATE_LIST,
        payload: data
      })
    })
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(UsersUpdatePage);
