import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';

import Input from '../../components/Input';
import Label from '../../components/Label';

import B2BCreateType from '../../redux/types/b2b_create_type';

import './styles.scss';

class B2BPrices extends React.Component{

  constructor(props){
      super(props);
      this.state = {};
  }

  render(){
      return(
        <>
        <div>
          <h2>{this.context.t('question_b2b_company_prices_vehicles_rolling')}</h2>
          <div className="flex">
            <Input type="number" priorityValue={this.props.b2b.rollingLightVL > 0 && this.props.b2b.rollingLightVL} placeholder={this.context.t("light_vehicle")} getValue={(value) => this.props.updateB2B("rollingLightVL", value)} />
            <Input type="number" priorityValue={this.props.b2b.rollingVanVehicle > 0 && this.props.b2b.rollingVanVehicle} placeholder={this.context.t("van_vehicle")} getValue={(value) => this.props.updateB2B("rollingVanVehicle", value)} />
          </div>
          <div className="flex">
            <Input type="number" priorityValue={this.props.b2b.rollingCommercial > 0 && this.props.b2b.rollingCommercial} placeholder={this.context.t("commercial_vehicle")} getValue={(value) => this.props.updateB2B("rollingCommercial", value)} />
            <Input type="number" priorityValue={this.props.b2b.rollingLightPL > 0 && this.props.b2b.rollingLightPL} placeholder={this.context.t("light_pl")} getValue={(value) => this.props.updateB2B("rollingLightPL", value)} />
          </div>
          <div className="flex">
            <Input type="number" priorityValue={this.props.b2b.rollingMediumPL > 0 && this.props.b2b.rollingMediumPL} placeholder={this.context.t("medium_pl")} getValue={(value) => this.props.updateB2B("rollingMediumPL", value)} />
            <Input type="number" priorityValue={this.props.b2b.rollingHeavyPL > 0 && this.props.b2b.rollingHeavyPL} placeholder={this.context.t("heavy_pl")} getValue={(value) => this.props.updateB2B("rollingHeavyPL", value)} />
          </div>
          <Label value={this.context.t("b2b_prices_vehicles_explanation")} />
        </div>
        <div>
          <h2>{this.context.t('question_b2b_company_prices_vehicles_not_rolling')}</h2>
          <div className="flex">
            <Input type="number" priorityValue={this.props.b2b.notRollingLightVL > 0 && this.props.b2b.notRollingLightVL} placeholder={this.context.t("light_vehicle")} getValue={(value) => this.props.updateB2B("notRollingLightVL", value)} />
            <Input type="number" priorityValue={this.props.b2b.notRollingVanVehicle > 0 && this.props.b2b.notRollingVanVehicle} placeholder={this.context.t("van_vehicle")} getValue={(value) => this.props.updateB2B("notRollingVanVehicle", value)} />
          </div>
          <div className="flex">
            <Input type="number" priorityValue={this.props.b2b.notRollingCommercial > 0 && this.props.b2b.notRollingCommercial} placeholder={this.context.t("commercial_vehicle")} getValue={(value) => this.props.updateB2B("notRollingCommercial", value)} />
            <Input type="number" priorityValue={this.props.b2b.notRollingLightPL > 0 && this.props.b2b.notRollingLightPL} placeholder={this.context.t("light_pl")} getValue={(value) => this.props.updateB2B("notRollingLightPL", value)} />
          </div>
          <div className="flex">
            <Input type="number" priorityValue={this.props.b2b.notRollingMediumPL > 0 && this.props.b2b.notRollingMediumPL} placeholder={this.context.t("medium_pl")} getValue={(value) => this.props.updateB2B("notRollingMediumPL", value)} />
            <Input type="number" priorityValue={this.props.b2b.notRollingHeavyPL > 0 && this.props.b2b.notRollingHeavyPL} placeholder={this.context.t("heavy_pl")} getValue={(value) => this.props.updateB2B("notRollingHeavyPL", value)} />
          </div>
          <Label value={this.context.t("b2b_prices_vehicles_explanation")} />
        </div>
        <div>
          <h2>{this.context.t('question_b2b_company_prices_vehicles_redelivery')}</h2>
          <div className="flex">
            <Input type="number" priorityValue={this.props.b2b.redeliveryLightVL > 0 && this.props.b2b.redeliveryLightVL} placeholder={this.context.t("light_vehicle")} getValue={(value) => this.props.updateB2B("redeliveryLightVL", value)} />
            <Input type="number" priorityValue={this.props.b2b.redeliveryVanVehicle > 0 && this.props.b2b.redeliveryVanVehicle} placeholder={this.context.t("van_vehicle")} getValue={(value) => this.props.updateB2B("redeliveryVanVehicle", value)} />
          </div>
          <div className="flex">
            <Input type="number" priorityValue={this.props.b2b.redeliveryCommercial > 0 && this.props.b2b.redeliveryCommercial} placeholder={this.context.t("commercial_vehicle")} getValue={(value) => this.props.updateB2B("redeliveryCommercial", value)} />
            <Input type="number" priorityValue={this.props.b2b.redeliveryLightPL > 0 && this.props.b2b.redeliveryLightPL} placeholder={this.context.t("light_pl")} getValue={(value) => this.props.updateB2B("redeliveryLightPL", value)} />
          </div>
          <div className="flex">
            <Input type="number" priorityValue={this.props.b2b.redeliveryMediumPL > 0 && this.props.b2b.redeliveryMediumPL} placeholder={this.context.t("medium_pl")} getValue={(value) => this.props.updateB2B("redeliveryMediumPL", value)} />
            <Input type="number" priorityValue={this.props.b2b.redeliveryHeavyPL > 0 && this.props.b2b.redeliveryHeavyPL} placeholder={this.context.t("heavy_pl")} getValue={(value) => this.props.updateB2B("redeliveryHeavyPL", value)} />
          </div>
          <Label value={this.context.t("b2b_prices_vehicles_explanation")} />
        </div>
        </>
      );
    }
}

B2BPrices.contextTypes = {
  t: PropTypes.func.isRequired
}

export default B2BPrices;
