import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';

import Input from '../../components/Input';
import Autocomplete from '../../components/Autocomplete';
import B2BAutocomplete from '../../components/Autocomplete/b2bcompany';
import Button from '../../components/Buttons/FormButton';

import './styles.scss';
import OrderType from '../../redux/types/order';

class CustomerInfos extends React.Component{

  constructor(props){
    super(props);
    this.state = {
    };
  }

  _nameOnBlur = (e) => {
      if (this.state.error_name && /[a-zA-Z ]{2,}/.test(this.props.name)) {
          this.setState({ error_name: false })
      } else {
          if (!/[a-zA-Z ]{2,}/.test(this.props.name)) {
              this.setState({ error_name: true })
          } else {
              this.setState({ error_name: false })
          }
      }
  }

  _firstnameOnBlur = (e) => {
      if (this.state.error_firstname && /[a-zA-Z ]{2,}/.test(this.props.firstname)) {
          this.setState({ error_firstname: false })
      } else {
          if (!/[a-zA-Z ]{2,}/.test(this.props.firstname)) {
              this.setState({ error_firstname: true })
          } else {
              this.setState({ error_firstname: false })
          }
      }
  }

  _phonenumberOnBlur = (e) => {
      if (this.state.error_phonenumber && /[+0-9]{10,12}/.test(this.props.phonenumber)) {
          this.setState({ error_phonenumber: false })
      } else {
          if (!/[+0-9]{10,12}/.test(this.props.phonenumber)) {
              this.setState({ error_phonenumber: true })
          } else {
              this.setState({ error_phonenumber: false })
          }
      }
  }

  _emailOnBlur = (e) => {
      if (this.state.error_email && /\S+@\S+\.\S{1,4}/.test(this.props.email)) {
          this.setState({ error_email: false })
      } else {
          if (!/\S+@\S+\.\S{1,4}/.test(this.props.email)) {
              this.setState({ error_email: true })
          } else {
              this.setState({ error_email: false })
          }
      }
  }

  _b2bCompanyNameOnBlur = (e) => {
    if (this.state.b2b_company_name && /[a-zA-Z0-9-_. ]{2,}/.test(this.props.b2b_company_name)) {
      this.setState({ error_b2b_company_name: false })
    } else {
        if (!/\S+@\S+\.\S{1,4}/.test(this.props.b2b_company_name)) {
            this.setState({ error_b2b_company_name: true })
        } else {
            this.setState({ error_b2b_company_name: false })
        }
    }
  }

  render(){
      return(
        <div>
          <h2>{this.context.t('question_customer_info')}</h2>
          {
            !this.props.is_b2b && (
              <>
                {
                  this.state.error_firstname && <span className="error-message">{this.context.t('error_firstname')}</span>
                }
                <Input
                  getValue={value => this.setState({firstnumber: value}, () => this.props.setCustomerFirstname(value))}
                  placeholder={this.context.t('customer_firstname')}
                  priorityValue={this.props.firstname}
                  onBlur={this._firstnameOnBlur}
                  error={this.state.error_firstname}
                />
                {
                  this.state.error_name && <span className="error-message">{this.context.t('error_name')}</span>
                }
                <Input
                  getValue={value => this.setState({name: value}, () => this.props.setCustomerName(value))}
                  placeholder={this.context.t('customer_name')}
                  priorityValue={this.props.name}
                  onBlur={this._nameOnBlur}
                  error={this.state.error_name}
                />
              </>
            )
          }
          {
            this.props.is_b2b && (
              <>
                {
                  this.state.error_company_name && <span className="error-message">{this.context.t('error_b2b_company_name')}</span>
                }
                <B2BAutocomplete
                  getValue={value => this.setState({b2b_company_name: value}, () => this.props.setB2BCompany(value))}
                  placeholder={this.context.t('customer_b2b_company_name')}
                  priorityValue={this.props.b2b_company_name}
                  onBlur={this._b2bCompanyNameOnBlur}
                  error={this.state.error_b2b_company_name}
                  token={this.props.token}
                />
              </>
            )
          }
          {
            this.state.error_email && <span className="error-message">{this.context.t('error_email')}</span>
          }
          <Input
            getValue={value => this.setState({email: value}, () => this.props.setCustomerEmail(value))}
            placeholder={this.context.t('customer_email')}
            priorityValue={this.props.email}
            onBlur={this._emailOnBlur}
            error={this.state.error_email}
          />
          {
            this.state.error_phonenumber && <span className="error-message">{this.context.t('error_phonenumber')}</span>
          }
          <Input
            getValue={value => this.setState({phonenumber: value}, () => this.props.setCustomerPhonenumber(value))}
            placeholder={this.context.t('customer_phonenumber')}
            priorityValue={this.props.phonenumber}
            onBlur={this._phonenumberOnBlur}
            error={this.state.error_phonenumber}
          />
          <Autocomplete
            placeholder={this.context.t("bill_address")}
            getValue={(value) => { if(value && value.address) this.props.setCustomerBillAddress(value.address) }}
            defaultValue={this.props.bill_address ? this.props.bill_address : ""}
          />
        </div>
      );
    }
}

CustomerInfos.contextTypes = {
  t: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
  b2b_company_name: _.get(state, 'order.customer_infos.b2b_company_name', ""),
  firstname: _.get(state, 'order.customer_infos.firstname', ""),
  name: _.get(state, 'order.customer_infos.name', ""),
  phonenumber: _.get(state, "order.customer_infos.phonenumber", ""),
  email: _.get(state, "order.customer_infos.email", ""),
  bill_address: _.get(state, "order.customer_infos.bill_address", ""),
  is_b2b: state.order.is_b2b,
  token: state.currentUser.token
})

const mapDispatchToProps = (dispatch) => ({
  setB2BCompany: (btob) => dispatch({
    type: OrderType.ORDER_SELECT_B2B_COMPANY_INFOS,
    payload: btob
  }),
  setCustomerFirstname: (firstname) => dispatch({
    type: OrderType.ORDER_SELECT_CUSTOMER_FIRSTNAME,
    payload: {
      firstname: firstname
    }
  }),
  setCustomerName: (name) => dispatch({
    type: OrderType.ORDER_SELECT_CUSTOMER_NAME,
    payload: {
      name: name
    }
  }),
  setCustomerEmail: (email) => dispatch({
    type: OrderType.ORDER_SELECT_CUSTOMER_EMAIL,
    payload: {
      email: email
    }
  }),
  setCustomerPhonenumber: (phonenumber) => dispatch({
    type: OrderType.ORDER_SELECT_CUSTOMER_PHONENUMBER,
    payload: {
      phonenumber: phonenumber
    }
  }),
    setCustomerBillAddress: (bill_address) => dispatch({
      type: OrderType.ORDER_SELECT_CUSTOMER_BILL_ADDRESS,
      payload: {
        bill_address: bill_address
      }
  })
})

export default connect(mapStateToProps, mapDispatchToProps)(CustomerInfos);
