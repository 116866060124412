import VehicleToUpdateType from '../types/vehicles_to_update.js';

export default (state = [], action) => {
  switch (action.type){
    case VehicleToUpdateType.SET_VEHICLES_TO_UPDATE_LIST:
      return action.payload;
    default:
      return state;
  }
}
