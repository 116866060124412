import B2BCreateType from '../types/b2b_create_type';

const initialState = {full_distance: 0, infos: {},rolling:{},not_rolling:{},redelivery:{},other:{}} 

export default (state = initialState, {type, payload}) => {
  switch (type) {
    case B2BCreateType.B2B_CREATE_SET_COMPANY_NAME :
      return { ...state, infos: { ...state.infos, companyName: payload } }
    case B2BCreateType.B2B_CREATE_SET_EMAIL :
      return { ...state, infos: { ...state.infos, email: payload } }
    case B2BCreateType.B2B_CREATE_SET_PHONENUMBER :
      return { ...state, infos: { ...state.infos, phonenumber: payload } }
    case B2BCreateType.B2B_CREATE_SET_LOCATION :
      return { ...state, infos: { ...state.infos, location: payload } }
    case B2BCreateType.B2B_CREATE_SET_TVA_NUMBER : 
      return { ...state, infos: { ...state.infos, tvaNumber: payload }}

    case B2BCreateType.B2B_CREATE_SET_PRICE_VL_ROLLING :
      return { ...state, rolling: { ...state.rolling, light_vehicle: (payload >= 0 ? payload : 0) } }
    case B2BCreateType.B2B_CREATE_SET_PRICE_4X4_ROLLING :
      return { ...state, rolling: { ...state.rolling, van_vehicle: (payload >= 0 ? payload : 0) } }
    case B2BCreateType.B2B_CREATE_SET_PRICE_VU_ROLLING :
      return { ...state, rolling: { ...state.rolling, commercial_vehicle: (payload >= 0 ? payload : 0) } }
    case B2BCreateType.B2B_CREATE_SET_PRICE_LIGHT_PL_ROLLING :
      return { ...state, rolling: { ...state.rolling, light_pl: (payload >= 0 ? payload : 0) } }
    case B2BCreateType.B2B_CREATE_SET_PRICE_MEDIUM_PL_ROLLING :
      return { ...state, rolling: { ...state.rolling, medium_pl: (payload >= 0 ? payload : 0) } }
    case B2BCreateType.B2B_CREATE_SET_PRICE_HEAVY_PL_ROLLING :
      return { ...state, rolling: { ...state.rolling, heavy_pl: (payload >= 0 ? payload : 0) } }

    case B2BCreateType.B2B_CREATE_SET_PRICE_VL_NOT_ROLLING :
      return { ...state, not_rolling: { ...state.not_rolling, light_vehicle: (payload >= 0 ? payload : 0) } }
    case B2BCreateType.B2B_CREATE_SET_PRICE_4X4_NOT_ROLLING :
      return { ...state, not_rolling: { ...state.not_rolling, van_vehicle: (payload >= 0 ? payload : 0) } }
    case B2BCreateType.B2B_CREATE_SET_PRICE_VU_NOT_ROLLING :
      return { ...state, not_rolling: { ...state.not_rolling, commercial_vehicle: (payload >= 0 ? payload : 0) } }
    case B2BCreateType.B2B_CREATE_SET_PRICE_LIGHT_PL_NOT_ROLLING :
      return { ...state, not_rolling: { ...state.not_rolling, light_pl: (payload >= 0 ? payload : 0) } }
    case B2BCreateType.B2B_CREATE_SET_PRICE_MEDIUM_PL_NOT_ROLLING :
      return { ...state, not_rolling: { ...state.not_rolling, medium_pl: (payload >= 0 ? payload : 0) } }
    case B2BCreateType.B2B_CREATE_SET_PRICE_HEAVY_PL_NOT_ROLLING :
      return { ...state, not_rolling: { ...state.not_rolling, heavy_pl: (payload >= 0 ? payload : 0) } }

    case B2BCreateType.B2B_CREATE_SET_PRICE_VL_REDELIVERY :
      return { ...state, redelivery: { ...state.redelivery, light_vehicle: (payload >= 0 ? payload : 0) } }
    case B2BCreateType.B2B_CREATE_SET_PRICE_4X4_REDELIVERY :
      return { ...state, redelivery: { ...state.redelivery, van_vehicle: (payload >= 0 ? payload : 0) } }
    case B2BCreateType.B2B_CREATE_SET_PRICE_VU_REDELIVERY :
      return { ...state, redelivery: { ...state.redelivery, commercial_vehicle: (payload >= 0 ? payload : 0) } }
    case B2BCreateType.B2B_CREATE_SET_PRICE_LIGHT_PL_REDELIVERY :
      return { ...state, redelivery: { ...state.redelivery, light_pl: (payload >= 0 ? payload : 0) } }
    case B2BCreateType.B2B_CREATE_SET_PRICE_MEDIUM_PL_REDELIVERY :
      return { ...state, redelivery: { ...state.redelivery, medium_pl: (payload >= 0 ? payload : 0) } }
    case B2BCreateType.B2B_CREATE_SET_PRICE_HEAVY_PL_REDELIVERY :
      return { ...state, redelivery: { ...state.redelivery, heavy_pl: (payload >= 0 ? payload : 0) } }

    case B2BCreateType.B2B_CREATE_SET_PRICE_POLICE_STATION :
      return { ...state, other: { ...state.other, police_station: (payload >= 0 ? payload : 0) } }
    case B2BCreateType.B2B_CREATE_SET_PRICE_BASEMENT :
      return { ...state, other: { ...state.other, basement: (payload >= 0 ? payload : 0) } }
    case B2BCreateType.B2B_CREATE_SET_PRICE_HANDLING :
      return { ...state, other: { ...state.other, handling: (payload >= 0 ? payload : 0) } }
    case B2BCreateType.B2B_CREATE_SET_PRICE_ADVANCE_OF_COST :
      return { ...state, other: { ...state.other, advance_of_cost: (payload >= 0 ? payload : 0) } }
    case B2BCreateType.B2B_CREATE_SET_PRICE_CLEANING :
      return { ...state, other: { ...state.other, cleaning: (payload >= 0 ? payload : 0) } }

    case B2BCreateType.B2B_CREATE_SET_DISTANCE_MODE_FULL :
      return { ...state, full_distance: payload }
    case B2BCreateType.B2B_CREATE_SET_KM_INCLUDED :
      return { ...state, km_included: (payload >= 0 ? payload : 0) }

    case B2BCreateType.B2B_CREATE_RESET_STATE :
      return initialState;

    default :
      return state;
  }
}
