import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import MaterialIcon from 'material-icons-react';

import './styles.scss';

import Label from '../../components/Label';
import Button from '../../components/Buttons/FormButton';

import Company from '../../core/Company';
import User from '../../core/User';
import TowTruck from '../../core/TowTruck';
import Mercure from '../../core/Mercure';

import TowTruckTypes from '../../redux/types/towtruck';
import env from '../../env.js'

import Template from '../../templates/Backoffice';
import DetailsView from '../../templates/Details';

class OrderDetails extends React.Component{

  constructor(props){
    super(props);
    this.state = {
      id: undefined,
      eventSourceUser: new Mercure().updateCurrentUser(this.props.params.id)
    }
  }

  componentDidUpdate(prevProps){
    if(prevProps.user.token !== this.props.user.token || this.state.eventSourceUser.onmessage === undefined) {

    }
  }

  componentWillUnmount(){
    this.state.eventSourceUser.close();
  }

  componentDidMount(){
    if(_.get(this.props, 'user.token', undefined) !== undefined){
      let eventSourceUser = this.state.eventSourceUser;
      eventSourceUser.onmessage = (e) => {
        new Company({}).setToken(this.props.user.token).getTowTrucks(JSON.parse(e.data).company.id).then(tow_trucks => {
          this.setState({
            ...this.state,
            company: {
              ...this.state.company,
              tow_trucks: tow_trucks
            }
          }, () => this.props.updateTowTruckList(this.props.user.token))
        })
      }
      this.setState({ eventSourceUser }, () => {
          new User({}).setToken(this.props.user.token).findOne(this.props.params.id).then(user => {
              this.setState(user, () => {
                new Company({}).setToken(this.props.user.token).getTowTrucks(user.company.id).then(tow_trucks => {
                  this.setState({
                    ...this.state,
                    company: {
                      ...this.state.company,
                      tow_trucks: tow_trucks
                    }
                  })
                });
                new User({}).setToken(this.props.user.token).getOrders(this.props.params.id).then(orders => {
                  this.setState({
                    ...this.state,
                    orders: orders
                  })
                })
              });
          });
      });
    }
  }

  renderTowTrucks = () => {
    let tabRes = [this.state.company.tow_trucks.length%2 === 0 ? this.state.company.tow_trucks.length/2 : (this.state.company.tow_trucks.length/2)+1];
    let index = -1;
    for(let i = 0; i < this.state.company.tow_trucks.length; i++){
      if(i%2 === 0){
        index++;
        tabRes[index] = [];
        tabRes[index].push(this.state.company.tow_trucks[i])
      } else {
        tabRes[index].push(this.state.company.tow_trucks[i])
      }
    }
    return tabRes.map((parentElem, i) => {
      return (
        <div>
          {
            tabRes[i] ? tabRes[i].map((elem, j) => {
              return (
                <Button
                  label={elem.model + " (" + elem.registration + ")"}
                  selected={_.get(this.state, 'tow_truck.id', -1) === elem.id}
                  onClick={() => this.setState({tow_truck: this.state.tow_truck && this.state.tow_truck.id === elem.id ? undefined : elem}, () => {
                    new User({towTruckIdentifier: elem.id}).setToken(this.props.user.token).update(this.state.id).then(data => {
                      this.props.updateTowTruckList(this.props.user.token);
                    });
                  })}
                />
              );
            }) : ""
          }
        </div>
      );
    });
  }

  render(){
    if(this.state.id === undefined || this.state.id === null || this.state.id === -1 || isNaN(this.state.id)){
      return(
        <Template>
          <DetailsView isLoading />
        </Template>
      );
    }

    let orders = this.state.orders ? this.state.orders : []
    let globalCA = 0, globalCount = 0, monthlyCA = 0, monthlyCount = 0, annualCA = 0, annualCount = 0;
    orders.forEach(elem => {
      globalCA += elem.price;
      globalCount++;
      if(new Date(elem.wished_intervention_date).getMonth() === new Date().getMonth()){
        monthlyCA += elem.price;
        monthlyCount++;
      }
      if(new Date(elem.wished_intervention_date).getYear() === new Date().getYear()){
        annualCA += elem.price;
        annualCount++;
      }
    })
    let date = this.state.birth.split('T');
    let day = date[0].split("-")[2];
    let month = date[0].split("-")[1];
    let year = date[0].split("-")[0];

    if(env.debug){
      // console.log("User : ", this.state);
      // console.log("Vehicles : ", this.state.company.tow_trucks ? this.state.company.tow_trucks : []);
    }

    return(
        <Template>
          <DetailsView className="user-details">
            <h2>{this.state.firstname + " " + this.state.name}</h2>
            <Label value={this.context.t('basic_infos')} />
            <ul>
              <li>
                <span><MaterialIcon icon="email" size={"tiny"} color="#1c6bb1" /></span>{this.state.email}
              </li>
              <li>
                <span><MaterialIcon icon="call" size={"tiny"} color="#1c6bb1" /></span>{this.state.phonenumber}
              </li>
              <li>
                <span><MaterialIcon icon="book" size={"tiny"} color="#1c6bb1" /></span>{day + "/" + month + "/" + year}
              </li>
            </ul>
            <Label value={this.context.t('user_role')} />
            <ul>
              <li>
                <span><MaterialIcon icon="book" size={"tiny"} color="#1c6bb1" /></span>{this.context.t(this.state.roles[0].toLowerCase())}
              </li>
            </ul>
            <Label value={this.context.t('economic_infos')} />
            <ul>
              <li>
                <span><MaterialIcon icon="room" size={"tiny"} color="#1c6bb1" /></span>{this.state.company.name}
              </li>
              <li>
                <span><MaterialIcon icon="explore" size={"tiny"} color="#1c6bb1" /></span>{this.context.t(this.state.white_card ? "white_card" : "no_white_card")}
              </li>
              <li>
                <span><MaterialIcon icon="show_chart" size={"tiny"} color="#1c6bb1" /></span>
                <ul>
                  <li><span>{this.context.t('global_CA') + " " + globalCA + "€"}</span><span>{" - " + globalCount + " " + this.context.t('intervention' + (globalCount > 1 ? "s" : ""))}</span></li>
                  <li><span>{this.context.t('annual_CA') + " " + annualCA + "€"}</span><span>{" - " + annualCount + " " + this.context.t('intervention' + (annualCount > 1 ? "s" : ""))}</span></li>
                  <li><span>{this.context.t('monthly_CA') + " " + monthlyCA + "€"}</span><span>{" - " + monthlyCount + " " + this.context.t('intervention' + (monthlyCount > 1 ? "s" : ""))}</span></li>
                </ul>
              </li>
              {this.state.company.tow_trucks ? this.renderTowTrucks() : "Chargement des dépanneuses..."}
            </ul>
            <Button
              onClick={() => this.props.push('/user/' + this.state.id + '/edit')}
              label={this.context.t('edit')}
            />
          </DetailsView>
        </Template>
    );
  }
}

OrderDetails.contextTypes = {
  t: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
  user: state.currentUser,
  userId: state.currentUser.id
})
const mapDispatchToProps = (dispatch) => ({
  push: (route) => dispatch(push(route)),
  updateTowTruckList: (token) => {
    new TowTruck({}).setToken(token).findAll().then(data => {
      dispatch({
        type: TowTruckTypes.TOWTRUCK_UPDATE_LIST,
        payload: data
      })
    });
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(OrderDetails);
