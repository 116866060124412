import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Input from '../../components/Input';

import './styles.scss';
import B2BCreateType from '../../redux/types/b2b_create_type';

class B2BPrices extends React.Component{

  constructor(props){
      super(props);
      this.state = {};
  }

  render(){
      return(
        <div>
          <h2>{this.context.t('question_b2b_company_prices_options')}</h2>
          <Input type="number" min={0} placeholder={this.context.t("hour_police_station")} getValue={this.props.setPricePoliceStation} />
          <Input type="number" min={0} placeholder={this.context.t("price_basement")} getValue={this.props.setPriceBasement} />
          <Input type="number" min={0} placeholder={this.context.t("price_cleaning")} getValue={this.props.setPriceCleaning} />
          <Input type="number" min={0} placeholder={this.context.t("hour_handling")} getValue={this.props.setPriceHandling} />
          <Input type="number" min={0} placeholder={this.context.t("commission_on_advance_of_costs")} getValue={this.props.setPriceCommission} />
        </div>
      );
    }
}

B2BPrices.contextTypes = {
  t: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = (dispatch) => ({
  setPricePoliceStation: (price) => dispatch({ type: B2BCreateType.B2B_CREATE_SET_PRICE_POLICE_STATION, payload: price }),
  setPriceHandling: (price) => dispatch({ type: B2BCreateType.B2B_CREATE_SET_PRICE_HANDLING, payload: price }),
  setPriceBasement: (price) => dispatch({ type: B2BCreateType.B2B_CREATE_SET_PRICE_BASEMENT, payload: price }),
  setPriceCommission: (price) => dispatch({ type: B2BCreateType.B2B_CREATE_SET_PRICE_ADVANCE_OF_COST, payload: price }),
  setPriceCleaning: (price) => dispatch({ type: B2BCreateType.B2B_CREATE_SET_PRICE_CLEANING, payload: price })
})

export default connect(mapStateToProps, mapDispatchToProps)(B2BPrices);
