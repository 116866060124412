const CompanyType = {
  COMPANY_UPDATE_LIST: "COMPANY_UPDATE_LIST",
  COMPANY_CREATE_CLEAR_CURRENT: "COMPANY_CREATE_CLEAR_CURRENT",
  COMPANY_CREATE_SET_NAME: "COMPANY_CREATE_SET_NAME",
  COMPANY_CREATE_SET_PHONENUMBER: "COMPANY_CREATE_SET_PHONENUMBER",
  COMPANY_CREATE_SET_EMAIL: "COMPANY_CREATE_SET_EMAIL",
  COMPANY_CREATE_SET_SIRET: "COMPANY_CREATE_SET_SIRET",
  COMPANY_CREATE_SET_LOCATION: "COMPANY_CREATE_SET_LOCATION",
  COMPANY_CREATE_SET_GARAGE: "COMPANY_CREATE_SET_GARAGE",
  COMPANY_CREATE_SET_BODYWORK: "COMPANY_CREATE_SET_BODYWORK",
  COMPANY_CREATE_SET_MECHANICS: "COMPANY_CREATE_SET_MECHANICS",
  COMPANY_CREATE_SET_PAINT: "COMPANY_CREATE_SET_PAINT",
  COMPANY_CREATE_SET_RENTALCAR: "COMPANY_CREATE_SET_RENTALCAR",
  COMPANY_CREATE_SET_IBAN: "COMPANY_CREATE_SET_IBAN",
  COMPANY_CREATE_SET_LOGO: "COMPANY_CREATE_SET_LOGO",
  COMPANY_CREATE_SET_DEFAULT_COMMISSION: "COMPANY_CREATE_SET_DEFAULT_COMMISSION"
}

export default CompanyType;
