import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import TowTruck from '../../core/TowTruck';
import TowTruckTypes from '../../redux/types/towtruck';
import MaterialIcon from 'material-icons-react';

import './styles.scss';

import HeaderButtons from '../../components/HeaderButtons';
import SearchBar from '../../components/SearchBar';
import Label from '../../components/Label';
import Template from '../../templates/Backoffice';
// import FormButton from '../../components/Buttons/FormButton';

class VehiclePage extends React.Component{

  constructor(props){
    super(props);
    this.state = {
      searchOption: {label: "company_name", action: this.searchCompanyName},
      searchValue: "",
      list: [],
      searchList: undefined
    }
  }

  componentDidMount(){
    this.setState({list: this.props.towtruck.list})
  }

  componentDidUpdate(prevProps){
    if (prevProps.towtruck.list.length !== this.props.towtruck.list.length) {
      this.setState({list: this.props.towtruck.list})
    }
  }

  searchRegistration = (registration) => {
    if (registration !== null && registration !== undefined && registration !== "") {
      this.setState({ loading: true }, () => {
          new TowTruck().setToken(this.props.token).searchRegistration(registration).then(trucks => {
            this.setState({ searchList: trucks, loading: false });
          })
      })
    } else {
      this.setState({ searchList: undefined, loading: false });
    }
  }

  searchCompanyName = (companyName) => {
    if (companyName !== null && companyName !== undefined && companyName !== "") {
      this.setState({ loading: true }, () => {
          new TowTruck().setToken(this.props.token).searchCompanyName(companyName).then(trucks => {
              console.log(trucks)
            this.setState({ searchList: trucks, loading: false });
          })
      })
    } else {
      this.setState({ searchList: undefined, loading: false });
    }
  }

  renderTowTruck = (elem, i) => {
      let regString = elem.brand + " " + elem.model;
      // if ((elem.company.siret === this.props.user.company.siret && !this.state.allVehicles) || this.state.allVehicles) {
        if(new RegExp(_.get(this.state, 'searchValue', ""), "i").test(regString)){
          return (
            <div className="truck" key={i} onClick={() => this.props.push("/vehicle/" + elem.id)}>
              <div>
                {
                  elem.driver && <div><MaterialIcon color="rgb(34, 109, 175)" icon="person"/></div>
                }
                {
                  !elem.driver && <div><MaterialIcon color="rgb(34, 109, 175)" icon="person_outline"/></div>
                }
                <div>
                  {elem.brand + " " + elem.model + " (" + elem.company.name + ")"}
                </div>
              </div>
              <div>
                <a target="_blank" rel="noopener noreferrer" href={"https://maps.google.com/?q=" + elem.company.latitude + "," + elem.company.longitude}>
                  <MaterialIcon color="rgb(34, 109, 175)" icon="place" />
                </a>
              </div>
            </div>
          )
        // }
      }
      return (<div></div>);
  }

  renderList = () => {
      console.log("Search ", this.state.searchList)
    return (
      <div>
        {
          this.state.loading && <Label value={this.context.t('loading')} />
        }
        {
          !this.state.loading && !this.state.searchList && this.state.list.length === 0 && <Label value={this.context.t('no_vehicule')} />
        }
        {
          !this.state.searchList && !this.state.loading && this.state.list.map(this.renderTowTruck)
        }
        {
          this.state.searchList && !this.state.loading && this.state.searchList.length > 0 && this.state.searchList.map(this.renderTowTruck)
        }
        {
          this.state.searchList && !this.state.loading && this.state.searchList.length === 0 && <Label value={this.context.t('no_vehicule_in_search')} />
        }
      </div>
    )
  }

  render(){
    return(
      <Template>
        <div className="vehicles-pages">
          <HeaderButtons
            tabList={[
              {
                label: this.context.t('list_of_vehicles'),
                route: 'vehicle',
                onClick: () => this.props.push("/vehicle")
              },
              {
                label: this.context.t('create_vehicle'),
                route: 'vehicle/create',
                onClick: () => this.props.push("/vehicle/create")
              }
            ]}
          />
          <SearchBar
            searchOptions={[
                {label: "company_name", action: this.searchCompanyName},
                {label: "vehicle_registration", action: this.searchRegistration}
            ]}
            onChooseSearchOption={(option) => this.setState({searchOption: option})}
            getSearchValue={(value) => this.setState({searchValue: value})}
            current={this.state.searchOption}
          />
          {
            this.props.towtruck.list === undefined && <Label value={this.context.t('loading')} />
          }
          {
            this.renderList()
          }
        </div>
      </Template>
    );
  }
}

VehiclePage.contextTypes = {
  t: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
  token: state.currentUser.token,
  user: state.currentUser.user,
  towtruck: state.towtruck
})
const mapDispatchToProps = (dispatch) => ({
  push: (route) => dispatch(push(route)),
  updateTowTruckList: (token) => {
    new TowTruck({}).setToken(token).findAll().then(data => {
      dispatch({
        type: TowTruckTypes.TOWTRUCK_UPDATE_LIST,
        payload: data
      })
    });
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(VehiclePage);
