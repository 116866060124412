import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';

import Button from '../../components/Buttons/FormButton';

import './styles.scss';
import CompanyType from '../../redux/types/company';

class CompanyGarage extends React.Component{

  constructor(props){
    super(props);
    this.state = {
      garage: this.props.garage
    }
  }

  componentDidUpdate(prevProps){
    if(this.props.garage !== prevProps.garage){
      this.setState({
        garage: this.props.garage
      })
    }
  }

  render(){
      return(
        <div>
          <h2>{this.context.t('question_company_garage')}</h2>
          <Button
            onClick={() => this.props.setGarage(!this.props.garage ? 1 : 0)}
            label={this.context.t(!this.props.garage ? 'company_is_not_garage' : 'company_is_garage')}
            selected={this.props.garage ? true : false}
          />
        </div>
      );
    }
}

CompanyGarage.contextTypes = {
  t: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
  currentCompany: state.company.currentCompany,
  garage: _.get(state, 'company.currentCompany.garage') ? 1 : 0,
})

const mapDispatchToProps = (dispatch) => ({
  setGarage: (garageBool) => dispatch({
    type: CompanyType.COMPANY_CREATE_SET_GARAGE,
    payload: garageBool
  })
})

export default connect(mapStateToProps, mapDispatchToProps)(CompanyGarage);
