import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';

import Button from '../../components/Buttons/FormButton';

import './styles.scss';
import CompanyType from '../../redux/types/company';

class CompanyActivity extends React.Component{
  render(){
      if(!this.props.garage)
        return( <div style={{margin: 0}}></div> );
      return(
        <div>
          <h2>{this.context.t('question_company_activity')}</h2>
          <div>
            <Button
              onClick={() => this.props.setBodyWork(!this.props.bodyWork)}
              label={this.context.t('company_bodyWork')}
              selected={this.props.bodyWork ? true : false}
            />
            <Button
              onClick={() => this.props.setMechanics(!this.props.mechanics)}
              label={this.context.t('company_mechanics')}
              selected={this.props.mechanics? true : false}
            />
          </div>
          <div>
            <Button
              onClick={() => this.props.setPaint(!this.props.paint)}
              label={this.context.t('company_paint')}
              selected={this.props.paint ? true : false}
            />
            <Button
              onClick={() => this.props.setRentalCar(!this.props.rentalCar)}
              label={this.context.t('company_rentalCar')}
              selected={this.props.rentalCar ? true : false}
            />
          </div>
        </div>
      );
    }
}

CompanyActivity.contextTypes = {
  t: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
  garage: _.get(state, 'company.currentCompany.garage') ? 1 : 0,
  bodyWork: _.get(state, 'company.currentCompany.bodyWork') ? 1 : 0,
  mechanics: _.get(state, 'company.currentCompany.mechanics') ? 1 : 0,
  paint: _.get(state, 'company.currentCompany.paint') ? 1 : 0,
  rentalCar: _.get(state, 'company.currentCompany.rentalCar') ? 1 : 0,
})

const mapDispatchToProps = (dispatch) => ({
  setBodyWork: (bool) => dispatch({
    type: CompanyType.COMPANY_CREATE_SET_BODYWORK,
    payload: bool
  }),
  setMechanics: (bool) => dispatch({
    type: CompanyType.COMPANY_CREATE_SET_MECHANICS,
    payload: bool
  }),
  setPaint: (bool) => dispatch({
    type: CompanyType.COMPANY_CREATE_SET_PAINT,
    payload: bool
  }),
  setRentalCar: (bool) => dispatch({
    type: CompanyType.COMPANY_CREATE_SET_RENTALCAR,
    payload: bool
  })
})

export default connect(mapStateToProps, mapDispatchToProps)(CompanyActivity);
