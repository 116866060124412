import React from 'react';
import PropTypes from "prop-types";

import Input from "../Input";

import './styles.scss';

class Autocomplete extends React.Component{

  constructor(props){
    super(props);
    this.state = {
      value: this.props.valueText !== undefined || this.props.valueText !== null ? this.props.valueText : "",
      places: []
    }
  }

  handleOnClickSuggestion = (elem) => {
    this.props.getValue(elem);
    this.setState({visible: false, value: elem.address});
  }

  componentDidUpdate(prevProps) {
    if(this.props.priorityValue !== prevProps.priorityValue){
      this.setState({value: this.props.priorityValue});
    }
  }

  onChangeValue = (value) => {
    this.setState({visible: true}, () => {
      if(value.length > 2){
        fetch("https://api.mapbox.com/geocoding/v5/mapbox.places/" + this.state.value + ".json?language=fr&limit=3&country=FR&access_token=pk.eyJ1IjoicG94aWxpdW0iLCJhIjoiY2pxZXdsNWs0NGpqYTQzcHA4OXlxMWE4cyJ9.nra8KC_MUGMPuGMUKUQCRw")
        .then((data) => data.json())
        .then((json) => {
          var elemTab = []
          if(json && json.features) {
            json.features.forEach((elem) => {
              elemTab.push({
                address: elem.place_name,
                coords: elem.geometry.coordinates
              })
            });
          }
          this.setState({places: elemTab});
        });
    } else {
        this.props.getValue(undefined);
    }
    this.setState({value: value, places: []});
    });
  }

  render(){
    var places = this.state.places;
    let value = "";
    value = this.props.defaultValue && (this.state.value === "" || !this.state.value) ? this.props.defaultValue : this.state.value

    return(
      <div className="autocomplete-container">
        <Input
          priorityValue={value}
          placeholder={this.props.placeholder}
          getValue={this.onChangeValue}
          autoComplete={false}
          onBlur={() => setTimeout(() => this.setState({ visible: false }, this.props.onBlur), 200)}
          error={this.props.error ? true : false}
        />
        <div>
          <ul>
            {
              places.map((elem, i) => (
                this.state.visible === true ? <li key={i} onClick={(e) => this.handleOnClickSuggestion(elem)}>{elem.address}</li> : ""
              ))
            }
          </ul>
        </div>
      </div>
    );
  }
}

Autocomplete.contextTypes = {
  t: PropTypes.func.isRequired
}

export default Autocomplete;
