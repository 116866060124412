import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';

import Order from '../../core/Order';
import Input from '../../components/Input';
import Button from '../../components/Buttons/FormButton';

import './styles.scss';
import OrderType from '../../redux/types/order';

import { paymentMethod } from '../../constants';

class Price extends React.Component{

  constructor(props){
    super(props);
    this.state = {
      price: "",
      manual: false
    };
  }

  componentDidUpdate(prevProps){
    if (
      _.get(this.props, 'order.problem', undefined) >= 0 &&
      _.get(this.props, 'order.reason', undefined) >= 0 &&
      _.get(this.props, "order.vehicle_type", undefined) >= 0 &&
      _.get(this.props, "order.limited_height", undefined) >= 0 &&
      _.get(this.props, "order.vehicle_infos.brand", undefined) &&
      _.get(this.props, "order.vehicle_infos.model", undefined) &&
      _.get(this.props, "order.path.departure.address", undefined) &&
      _.get(this.props, "order.vehicle_infos.registration", undefined) &&
      _.get(this.props, "order.path.departure.coords.lat", undefined) &&
      _.get(this.props, "order.path.departure.coords.lng", undefined) &&
      _.get(this.props, "order.path.arrival.address", undefined) &&
      _.get(this.props, "order.path.arrival.coords.lat", undefined) &&
      _.get(this.props, "order.path.arrival.coords.lng", undefined) &&
      // _.get(this.props, "order.customer_infos.firstname", undefined) &&
      // _.get(this.props, "order.customer_infos.name", undefined) &&
      _.get(this.props, "order.customer_infos.email", undefined) &&
      _.get(this.props, "order.customer_infos.phonenumber", undefined) &&
      (
        this.state.price === "" ||
        this.props.order.limited_height !== prevProps.order.limited_height ||
        this.props.order.path.departure.address !== prevProps.order.path.departure.address ||
        this.props.order.path.arrival.address !== prevProps.order.path.arrival.address ||
        this.props.order.problem !== prevProps.order.problem ||
        this.props.order.reason !== prevProps.order.reason ||
        this.props.order.vehicle_type !== prevProps.order.vehicle_type ||
        _.get(this.props, 'order.path.police_station.address', undefined) !== _.get(prevProps, 'order.path.police_station.address', undefined) ||
        _.get(this.props, 'order.advance_of_cost', 0) !== _.get(prevProps, 'order.advance_of_cost', 0) ||
        (this.props.order.discount !== prevProps.order.discount && !Number.isNaN(this.props.order.discount))
      )
    ) {
      new Order({
        vehicleBrand: this.props.order.vehicle_infos.brand,
        vehicleModel: this.props.order.vehicle_infos.model,
        vehicleRegistration: this.props.order.vehicle_infos.registration,
        problem: this.props.order.problem,
        reason: this.props.order.reason,
        vehicleType: this.props.order.vehicle_type,
        departureAddress: this.props.order.path.departure.address,
        departureLat: this.props.order.path.departure.coords.lat,
        departureLng: this.props.order.path.departure.coords.lng,
        arrivalAddress: this.props.order.path.arrival.address,
        arrivalLat: this.props.order.path.arrival.coords.lat,
        arrivalLng: this.props.order.path.arrival.coords.lng,
        limitedHeight: this.props.order.limited_height ? 1 : 0,
        firstname: this.props.order.customer_infos.firstname,
        name: this.props.order.customer_infos.name,
        b2b_company_name: this.props.order.customer_infos.b2b_company_name,
        email: this.props.order.customer_infos.email,
        phonenumber: this.props.order.customer_infos.phonenumber,
        addressPoliceStation: _.get(this.props, 'order.path.police_station.address', undefined),
        policeLng: _.get(this.props, 'order.path.police_station.coords.lng', undefined),
        policeLat: _.get(this.props, 'order.path.police_station.coords.lat', undefined),
        b2bCompanyIdentifier: _.get(this.props, 'order.customer_infos.b2b_company_id', undefined),
        rolling: this.props.order.rolling,
        advanceOfCost: _.get(this.props, 'order.advance_of_cost', 0),
        discount: _.get(this.props, 'order.discount', 0)
      }).calculatePrice().then(price => {
        this.setState({
          price: price
        }, () => {
          this.props.setOrderPrice(this.state.price);
        });
      }).catch(err => console.log("Erreur dans le calcul du prix : ", err));
    }
  }

  render(){
      let advance_of_cost = 0;
      if (_.get(this.props, 'order.advance_of_cost', false)) {
        advance_of_cost = this.props.order.advance_of_cost * _.get(this.props, 'order.customer_infos.b2b_company.advance_of_cost_commission', 0) / 100;
      }

      let hour_police_station  = !_.get(this.props, 'order.customer_infos.b2b_company.hour_police_station', false) ? 66 : _.get(this.props, 'order.customer_infos.b2b_company.hour_police_station', 66)
      let hour_basement = !_.get(this.props, 'order.customer_infos.b2b_company.hour_basement', false) ? 37.50 : _.get(this.props, 'order.customer_infos.b2b_company.hour_basement', 37.50)

      return(
        <div>
          <h2>{this.context.t('question_price')}</h2>
          <Input
            getValue={value => this.setState({discount: value}, () => this.props.setOrderDiscount(value))}
            placeholder={this.context.t('discount')}
            priorityValue={this.state.discount}
            type="number"
          />
          <Input
            getValue={value => this.setState({price: value}, () => this.props.setOrderPrice(value))}
            placeholder={this.context.t('price')}
            priorityValue={this.state.price}
            type="number"
          />
          { this.state.price && (
            <>
              <h2>{ this.context.t('question_price_details') }</h2>
              <p>Le prix TTC est : {Math.round(this.state.price * 1.2 * 100) / 100} €</p>
              {_.get(this.props, 'order.path.police_station.address', undefined) && (
                <p>1 heure de commissariat à {hour_police_station} € HT soit {Math.round(hour_police_station*100*1.2)/100} € TTC </p> 
              )}
              {_.get(this.props, 'order.limited_height', undefined) && (
                <p>Forfait hauteur limitée à {hour_basement} € HT soit {Math.round(hour_basement*100*1.2)/100} € TTC </p> 
              )}
              {advance_of_cost > 0 && (
                <p>Commission sur avance de frais de {advance_of_cost} € HT soit {Math.round(advance_of_cost*100*1.2)/100} € TTC </p> 
              )}
              {
                _.get(this.props, 'order.discount', 0) > 0 && (
                  <p>Remise commerciale de {Math.round(this.props.order.discount*100)/100} € HT soit {Math.round(this.props.order.discount*100*1.2)/100} € TTC </p> 
                )
              }
            </>
          )} 
          <h2>{this.context.t('way_to_pay')}</h2>
          <div>
            <Button
              onClick={() => this.props.updatePaymentMethod(paymentMethod.PAYMENT_CARD)}
              label={this.context.t('payment_card')}
              selected={_.get(this.props, 'order.payment_method') === paymentMethod.PAYMENT_CARD ? true : false}
            />
            <Button
              onClick={() => this.props.updatePaymentMethod(paymentMethod.PAYMENT_SPECIES)}
              label={this.context.t('payment_species')}
              selected={_.get(this.props, 'order.payment_method') === paymentMethod.PAYMENT_SPECIES ? true : false}
            />
          </div>
          <div>
            <Button
              onClick={() => this.props.updatePaymentMethod(paymentMethod.PAYMENT_CHECK)}
              label={this.context.t('payment_check')}
              selected={_.get(this.props, 'order.payment_method') === paymentMethod.PAYMENT_CHECK ? true : false}
            />
            <Button
              onClick={() => this.props.updatePaymentMethod(paymentMethod.PAYMENT_TRANSFER)}
              label={this.context.t('payment_transfer')}
              selected={_.get(this.props, 'order.payment_method') === paymentMethod.PAYMENT_TRANSFER ? true : false}
            />
          </div>
          <div className="one" style={{marginTop: 20}}>
            <Button
              onClick={() => this.props.orderIsPaid(this.props.order.paid ? 0 : 1)}
              label={this.props.order.paid ? this.context.t('payment_is_done') : this.context.t('payment_on_site')}
              selected={this.props.order.paid ? true : false}
            />
          </div>
        </div>
      );
    }
}

Price.contextTypes = {
  t: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
  token: state.currentUser.token,
  order: state.order,
  price: state.order.price
})

const mapDispatchToProps = (dispatch) => ({
  setOrderPrice: (price) => dispatch({
    type: OrderType.ORDER_SELECT_FINAL_PRICE,
    payload: {
      price: price
    }
  }),
  setOrderDiscount: (price) => dispatch({
    type: OrderType.ORDER_SELECT_DISCOUNT,
    payload: price
  }),
  updatePaymentMethod: (method) => dispatch({
    type: OrderType.ORDER_SELECT_PAYMENT_METHOD,
    payload: {
      payment_method: method
    }
  }),
  orderIsPaid: (paid) => dispatch({
    type: OrderType.ORDER_SELECT_IF_ITS_PAID,
    payload: {
      paid: paid ? 1 : 0
    }
  })
})

export default connect(mapStateToProps, mapDispatchToProps)(Price);
