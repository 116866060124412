import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';

import './styles.scss';
import Button from '../../components/Buttons/FormButton';
import Label from '../../components/Label';
import Input from '../../components/Input';
import OrderType from '../../redux/types/order';

class DateInfos extends React.Component{

  constructor(props){
    super(props);
    this.state = {
        now: true,
        // date: moment(moment(new Date()).format('YYYY-MM-DDThh:mm')).utc().format()
        // date: moment(new Date()).format('YYYY-MM-DDThh:mm:ss')
    };
  }

  componentDidMount(){
      this.props.setDate(this.state.date);
  }

  render(){
    console.log(this.props.date)
    return(
      <div className="date_infos">
        <h2>{this.context.t('question_company_intervention_date')}</h2>
        <div>
            <Button selected={!this.state.now} label={this.state.now ? this.context.t('now') : this.context.t('not_now')} onClick={() => this.setState({ now: !this.state.now }, () => {
              if (this.state.now) this.props.setDate();
            })} />
        </div>
        { !this.state.now && (
                <>
                    <Label value={this.context.t('choose_date')} />
                    <Input placeholder={this.context.t("intervention_date")} min={moment(new Date()).format('YYYY-MM-DD HH:mm+01:00')} type={"datetime-local"} getValue={ date => {
                      date = moment(date).format('YYYY-MM-DD HH:mm+01:00');
                      this.props.setDate(date);
                    }}/>
                </>
            )}
      </div>
    )
  }
}

DateInfos.contextTypes = {
  t: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
  token: state.currentUser.token,
  date: _.get(state, 'order.date', undefined) ? state.order.date : "",
})

const mapDispatchToProps = (dispatch) => ({
    setDate:  (date = moment(new Date()).format('YYYY-MM-DD HH:mm+01:00')) => dispatch({
        type: OrderType.ORDER_SELECT_DATE,
        payload: date
    })
})

export default connect(mapStateToProps, mapDispatchToProps)(DateInfos);
