import React from 'react';
import MaterialIcon from 'material-icons-react';

const navButtonListe = [
  {
    label: <MaterialIcon icon="home" />,
    route: "/"
  },
  {
    label: <MaterialIcon icon="local_shipping" />,
    route: "/order"
  },
  // {
  //   label: <MaterialIcon icon="group" />,
  //   route: "/clients"
  // },
  // {
  //   label: <MaterialIcon icon="account_balance" />,
  //   route: "/comptabilité"
  // },
  // {
  //   label: <MaterialIcon icon="message" />,
  //   route: "/messages"
  // },
  {
    label: <MaterialIcon icon="settings" />,
    route: "/settings"
  }
]

export default (state = {buttons: navButtonListe, active: 0}, {type, payload}) => {
  return state;
}
