import env from "../../env";
import $ from 'jquery';

class Bill {
    setToken(token) {
        this.token = token;
        return this;
    }

    create(billObject) {
        return new Promise((resolve, reject) => {
            $.ajax(env.backendUrl + "api/v1/bills", {
                type: 'POST',
                crossDomain: true,
                headers: {
                  "Authorization": "Bearer " + this.token
                },
                data: billObject
            }).done(data => resolve(data));
        });
    }

    addCredit(billNumber, amount) {
        return new Promise((resolve, reject) => {
            $.ajax(env.backendUrl + "api/v1/bills/"+billNumber+"/credit", {
                type: 'POST',
                crossDomain: true,
                headers: {
                    "Authorization": "Bearer " + this.token
                },
                data: {
                    amount
                }
            }).done(data => resolve(data));
        })
    }

    cancel(billNumber) {
        return new Promise((resolve, reject) => {
          $.ajax(env.backendUrl + "api/v1/bills/"+billNumber+"/cancel", {
            method: "GET",
            headers: {
                "Authorization": "Bearer " + this.token
            }
          }).done(bill => resolve(bill));
        })
    }

    findOneByBillNumber(billNumber) {
        return new Promise((resolve, reject) => {
            fetch(env.backendUrl + "api/v1/bills/"+billNumber, {
                method: "GET",
                headers: {
                    "Authorization": "Bearer " + this.token
                },
            }).then(data => data.json()).then(bill => resolve(bill))
        })
    }

    downloadBill = (billNumber = undefined) => {
        return new Promise((resolve, reject) => {
          fetch(env.backendUrl + "api/v1/bills/"+billNumber+"/download", {
            headers: {
              "Content-Type": "application/pdf",
              "Authorization": "Bearer " + this.token
            }
          })
            .then(response => response.blob())
            .then(data => resolve(URL.createObjectURL(data)))
        });
    }

    downloadCredit = (billNumber, creditNumber = undefined) => {
        return new Promise((resolve, reject) => {
            fetch(env.backendUrl + "api/v1/bills/"+billNumber+"/credit/"+creditNumber, {
              headers: {
                "Content-Type": "application/pdf",
                "Authorization": "Bearer " + this.token
              }
            })
              .then(response => response.blob())
              .then(data => resolve(URL.createObjectURL(data)))
        });
    }

    searchByBillNumber(billNumber){
        return new Promise((resolve, reject) => {
            fetch(env.backendUrl + "api/v1/bills?billNumber="+billNumber, {
                method: "GET",
                headers: {
                    "Authorization": "Bearer " + this.token
                },
            }).then(data => data.json()).then(bills => resolve(bills))
        })
    }

    findAll(){
        return new Promise((resolve, reject) => {
            fetch(env.backendUrl + "api/v1/bills", {
                method: "GET",
                headers: {
                    "Authorization": "Bearer " + this.token
                },
            }).then(data => data.json()).then(bills => resolve(bills))
        })
    }
}

export default Bill;