import $ from 'jquery';
import { camelCase } from 'lodash';

import env from "../../env";

class B2BCompany {

    setToken(token){
        this.token = token;
        return this;
    }

    update(id, b2bData) {
        return new Promise((resolve, reject) => {
            delete b2bData.id;
            delete b2bData.orders;
            delete b2bData.deleted;
            $.ajax(env.backendUrl + "api/v1/b2b/" + id, {
                method: 'POST',
                crossDomain: true,
                headers: {
                  "Authorization": "Bearer " + this.token,
                  "Content-Type": "application/x-www-form-urlencoded"
                },
                data: b2bData
            }).done((b2b) => {
                for(let b in b2b) {
                    let tmp = b2b[b];
                    let tmpKey = b;
                    delete b2b[b];
                    let tmpCamelKey = camelCase(tmpKey);
                    if (tmpCamelKey.endsWith('Pl') || tmpCamelKey.endsWith('Vl')) {
                        tmpCamelKey = tmpCamelKey.slice(0, tmpCamelKey.length-1) + 'L'
                    } 
                    b2b[tmpCamelKey] = tmp;
                }
                resolve(b2b);
            }).catch(reject);
        });
    }

    search(companyName){
        return new Promise((resolve, reject) => {
            $.ajax( env.backendUrl + "api/v1/b2b?companyName="+companyName, {
                method: 'GET',
                crossDomain: true,
                headers: {
                    "Authorization": "Bearer " + this.token
                },
            }).done(companies => resolve(companies)).catch(err => console.error("Une erreur est survenue : ", err));
        })
    }

    delete(id) {
        return new Promise((resolve, reject) => {
            $.ajax( env.backendUrl + "api/v1/b2b/"+id, {
                method: "DELETE",
                crossDomain: true,
                headers: {
                  "Authorization": "Bearer " + this.token,
                  "Content-Type": "application/x-www-form-urlencoded"
                }
            }).done(resolve).catch(reject);
        })
    }

    find(id) {
        return new Promise((resolve, reject) => {
            $.ajax( env.backendUrl + "api/v1/b2b/" + id, {
                method: 'GET',
                crossDomain: true,
                headers: {
                  "Authorization": "Bearer " + this.token,
                  "Content-Type": "application/x-www-form-urlencoded"
                },
            }).done((b2b) => {
                for(let b in b2b) {
                    let tmp = b2b[b];
                    let tmpKey = b;
                    delete b2b[b];
                    let tmpCamelKey = camelCase(tmpKey);
                    if (tmpCamelKey.endsWith('Pl') || tmpCamelKey.endsWith('Vl')) {
                        tmpCamelKey = tmpCamelKey.slice(0, tmpCamelKey.length-1) + 'L'
                    } 
                    b2b[tmpCamelKey] = tmp;
                }
                resolve(b2b)
            }).catch(reject);
        })
    }

    findAll(){
        return new Promise((resolve, reject) => {
            $.ajax( env.backendUrl + "api/v1/b2b", {
                method: 'GET',
                crossDomain: true,
                headers: {
                  "Authorization": "Bearer " + this.token,
                  "Content-Type": "application/x-www-form-urlencoded"
                },
            }).done(resolve).catch(reject);
        })
    }

    create(params){
        return new Promise((resolve, reject) => {
            $.ajax( env.backendUrl + "api/v1/b2b", {
                method: 'POST',
                crossDomain: true,
                headers: {
                  "Authorization": "Bearer " + this.token,
                  "Content-Type": "application/x-www-form-urlencoded"
                },
                data: {
                    companyName: params.companyName,
                    phonenumber: params.phonenumber,
                    email: params.email,
                    address: params.address,
                    tvaNumber: params.tvaNumber,

                    rollingLightVL: params.rollingLightVL === "" ? undefined : params.rollingLightVL,
                    rollingVanVehicle: params.rollingVanVehicle === "" ? undefined : params.rollingVanVehicle,
                    rollingCommercial: params.rollingCommercial === "" ? undefined : params.rollingCommercial,
                    rollingLightPL: params.rollingLightPL === "" ? undefined : params.rollingLightPL,
                    rollingMediumPL: params.rollingMediumPL === "" ? undefined : params.rollingMediumPL,
                    rollingHeavyPL: params.rollingHeavyPL === "" ? undefined : params.rollingHeavyPL,

                    notRollingLightVL: params.notRollingLightVL === "" ? undefined : params.notRollingLightVL,
                    notRollingVanVehicle: params.notRollingVanVehicle === "" ? undefined : params.notRollingVanVehicle,
                    notRollingCommercial: params.notRollingCommercial === "" ? undefined : params.notRollingCommercial,
                    notRollingLightPL: params.notRollingLightPL === "" ? undefined : params.notRollingLightPL,
                    notRollingMediumPL: params.notRollingMediumPL === "" ? undefined : params.notRollingMediumPL,
                    notRollingHeavyPL: params.notRollingHeavyPL === "" ? undefined : params.notRollingHeavyPL,

                    redeliveryLightVL: params.redeliveryLightVL === "" ? undefined : params.redeliveryLightVL,
                    redeliveryVanVehicle: params.redeliveryVanVehicle === "" ? undefined : params.redeliveryVanVehicle,
                    redeliveryCommercial: params.redeliveryCommercial === "" ? undefined : params.redeliveryCommercial,
                    redeliveryLightPL: params.redeliveryLightPL === "" ? undefined : params.redeliveryLightPL,
                    redeliveryMediumPL: params.redeliveryMediumPL === "" ? undefined : params.redeliveryMediumPL,
                    redeliveryHeavyPL: params.redeliveryHeavyPL === "" ? undefined : params.redeliveryHeavyPL,

                    hourPoliceStation: params.hourPoliceStation === "" ? undefined : params.hourPoliceStation,
                    hourBasement: params.hourBasement === "" ? undefined : params.hourBasement,
                    hourHandling: params.hourHandling === "" ? undefined : params.hourHandling,
                    advanceOfCostCommission: params.advanceOfCostCommission === "" ? undefined : params.advanceOfCostCommission,
                    kmIncluded: params.kmIncluded === "" ? undefined : params.kmIncluded,
                    fullDistanceCalculation: params.fullDistanceCalculation ? 1 : 0
                }
              }).done(data => resolve(data)).catch(err => console.error("Une erreur est survenue : ", err));
        })
    }
}

export default B2BCompany;