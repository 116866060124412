import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

class LeftColumn extends Component {
    render(){
        return (
            <ul className="left-column">
                <li className={this.props.active === 0 ? "active" : ""} onClick={() => this.props.onChangeActive(0)}>
                    <h1>Nouveau message</h1>
                </li>
                {
                    this.props.conversations && this.props.conversations.map((conv, i) => (
                        <li key={conv.id} className={this.props.active === conv.id ? "active" : ""} onClick={() => this.props.onChangeActive(conv.id)}>
                            <h1>{ conv.title }</h1>
                            {
                                conv.messages && conv.messages[conv.messages.length-1] && <p>{ conv.messages[conv.messages.length-1].message }</p>
                            }
                        </li>
                    ))
                }
                {/* <li>
                    <h1>Alex Pontes</h1>
                    <p>Ceci est un aperçu du message...</p>
                </li>
                <li>
                    <h1>Alex Pontes</h1>
                    <p>Ceci est un aperçu du message...</p>
                </li> */}
            </ul>
        )
    }
} 

// LeftColumn.contextType = {
//     t: PropTypes.func.isRequired
// }

const mapStateToProps = (state) => ({
    conversations: state.messages.conversations
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(LeftColumn);