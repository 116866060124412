import React from 'react';
import _ from 'lodash';

import './styles.scss';

class FormButton extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      selected: _.get(this.props, 'selected', false)
    }
  }

  componentDidUpdate(prevProps){
    if(prevProps.selected !== this.props.selected){
      this.forceUpdate();
    }
  }

  render(){
    return(
      <button
        className={(this.props.error ? "common-formbutton error" : "common-formbutton") + (this.props.selected ? " selected" : "")}
        onClick={() => this.props.onClick()}
        style={{background: (this.props.selected ? "#226daf" : "white"), color: (this.props.selected ? "white" : "#c6c5cb"), border: (this.props.selected ? "1px solid #226daf" : "1px solid #c6c5cb"), ...this.props.style}}
      >
        {this.props.label}
      </button>
    );
  }
}

export default FormButton;
