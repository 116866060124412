import React from 'react';
import './styles.scss';

class Input extends React.Component{

  constructor(props){
    super(props);
    this.state = {
      value: this.props.defaultValue ? this.props.defaultValue : ""
    }
  }

  // componentDidMount(){
  //   this.props.getValue(this.state.value);
  // }

  componentDidUpdate(prevProps) {
    if(this.props.priorityValue !== prevProps.priorityValue){
      this.setState({value: this.props.priorityValue});
    }
  }

  handleOnChange = (value) => {
    value = (this.props.type === "number" && !Number.isNaN(Number(value)) ? Number(value) : this.props.type === "number" ? 0 : value);
    this.setState({
      value: value
    }, () => this.props.getValue(value))
  }

  render(){
    if (this.props.type === "file") {
      // console.log(this.state.value)
    }
    
    let value = "";

    if(this.state.priorityValue !== undefined && this.state.priorityValue !== null && this.state.priorityValue !== ""){
      value = this.state.priorityValue;
    } else{
      value = this.props.defaultValue && this.state.value === ""  ? this.props.defaultValue : this.state.value
    }
    return(
      <div className={this.props.error ? "basic-input error" : "basic-input"} style={this.props.style}>
        {
          this.props.placeholder && <span>{this.props.placeholder}</span>
        }
        <input
          type={this.props.type === "password" || this.props.type === "file" || this.props.type === "datetime-local" || this.props.type === "number" ? this.props.type : "text"}
          name={this.props.name}
          value={this.props.type === "file" ? undefined : this.props.priorityValue}
          // placeholder={this.props.placeholder}
          onChange={(event) => this.handleOnChange(this.props.type === "file" ? event.target.files[0] : event.target.value)}
          onBlur={() => this.props.onBlur ? this.props.onBlur() : {}}
          onFocus={() => this.props.onFocus ? this.props.onFocus() : {}}
          min={this.props.min}
          autoComplete={this.props.autoComplete === undefined || this.props.autoComplete === null || this.props.autoComplete === true ? undefined : "nope"}
        />
      </div>
    );
  }
}

export default Input;
