import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';

import { vehicleType } from '../../constants';
import Button from '../../components/Buttons/FormButton';

import './styles.scss';
import OrderType from '../../redux/types/order';

class VehicleType extends React.Component{
  render(){
    return(
      <div>
        <h2>{this.context.t('vehicle_type_question')}</h2>
        <div>
          <Button
            onClick={() => this.props.updateOrder(vehicleType.LIGHT_VEHICLE)}
            label={this.context.t('light_vehicle')}
            selected={_.get(this.props, 'order.vehicle_type') === vehicleType.LIGHT_VEHICLE ? true : false}
          />
          <Button
            onClick={() => this.props.updateOrder(vehicleType.VAN_VEHICLE)}
            label={this.context.t('van_vehicle')}
            selected={_.get(this.props, 'order.vehicle_type') === vehicleType.VAN_VEHICLE ? true : false}
          />
        </div>
        <div>
          <Button
            onClick={() => this.props.updateOrder(vehicleType.COMMERCIAL)}
            label={this.context.t('commercial_vehicle')}
            selected={_.get(this.props, 'order.vehicle_type') === vehicleType.COMMERCIAL ? true : false}
          />
          <Button
            onClick={() => this.props.updateOrder(vehicleType.LIGHT_PL)}
            label={this.context.t('light_pl')}
            selected={_.get(this.props, 'order.vehicle_type') === vehicleType.LIGHT_PL ? true : false}
          />
        </div>
        <div>
          <Button
            onClick={() => this.props.updateOrder(vehicleType.MEDIUM_PL)}
            label={this.context.t('medium_pl')}
            selected={_.get(this.props, 'order.vehicle_type') === vehicleType.MEDIUM_PL ? true : false}
          />
          <Button
            onClick={() => this.props.updateOrder(vehicleType.HEAVY_PL)}
            label={this.context.t('heavy_pl')}
            selected={_.get(this.props, 'order.vehicle_type') === vehicleType.HEAVY_PL ? true : false}
          />
        </div>
      </div>
    );
  }
}

VehicleType.contextTypes = {
  t: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
  order: state.order,
  user: state.currentUser
})

const mapDispatchToProps = (dispatch) => ({
  updateOrder: (type) => dispatch(
    {
      type: OrderType.ORDER_SELECT_VEHICLE_TYPE,
      payload: {vehicle_type: type}
    }
  )
})

export default connect(mapStateToProps, mapDispatchToProps)(VehicleType);
