import React from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import PropTypes from 'prop-types';
import './styles.scss';

import Template from '../../templates/Backoffice';
import Button from '../../components/Buttons/SubmitButton';

import Infos from './infos';
import VehiclePrices from './pricesVehicle';
import Prices from './prices';
import Distance from './distance';
import B2BCreateType from '../../redux/types/b2b_create_type';
import B2BCompany from '../../core/B2BCompany';

class B2BCreate extends React.Component{

  constructor(props){
      super(props);
      this.state = {
          searchOption: {label: "company_name", action: this.searchCompanyName},
          searchList: undefined
      }
  }

  _handleConfirm = () => {
    new B2BCompany()
      .setToken(this.props.user.token)
      .update(this.state.b2b.id, {
        ...this.state.b2b,
        redeliveryCommercial: this.state.b2b.redeliveryCommercial,
        redeliveryHeavyPL: this.state.b2b.redeliveryHeavyPL,
        redeliveryLightPL: this.state.b2b.redeliveryLightPL,
        redeliveryLightVL: this.state.b2b.redeliveryLightVL,
        redeliveryMediumPL: this.state.b2b.redeliveryMediumPL,
        redeliveryVanVehicle: this.state.b2b.redeliveryVanVehicle,
        rollingCommercial: this.state.b2b.rollingCommercial,
        rollingHeavyPL: this.state.b2b.rollingHeavyPL,
        rollingLightPL: this.state.b2b.rollingLightPL,
        rollingLightVL: this.state.b2b.rollingLightVL,
        rollingMediumPL: this.state.b2b.rollingMediumPL,
        rollingVanVehicle: this.state.b2b.rollingVanVehicle,
        notRollingCommercial: this.state.b2b.notRollingCommercial,
        notRollingHeavyPL: this.state.b2b.notRollingHeavyPL,
        notRollingLightPL: this.state.b2b.notRollingLightPL,
        notRollingLightVL: this.state.b2b.notRollingLightVL,
        notRollingMediumPL: this.state.b2b.notRollingMediumPL,
        notRollingVanVehicle: this.state.b2b.notRollingVanVehicle
      })
      .then(b2bcompany => {
        this.props.push("/b2b")
      })
  }

  componentDidMount(){
    this.props.b2b.then((b2b) => this.setState({ b2b }, () => console.log(this.state.b2b)));
  }

  updateB2B = (key, value) => {
    this.setState({ 
      ...this.state,
      b2b: {
        ...this.state.b2b,
        [key]: value 
      }
    });
  }

  render(){
    console.log(this.state.b2b)
    return(
      <Template>
        <div className="form b2b-create-page">
          <h1>{ this.context.t("b2b_details") }</h1>
          <div className="section">
            {
              this.state.b2b && (
                <>
                  <Infos b2b={this.state.b2b} updateB2B={this.updateB2B} />
                  <VehiclePrices b2b={this.state.b2b} updateB2B={this.updateB2B} />
                  <Prices b2b={this.state.b2b} updateB2B={this.updateB2B} />
                  <Distance b2b={this.state.b2b} updateB2B={this.updateB2B} />
                  <div style={{ paddingBottom: 20 }}>
                    <div>
                      <Button
                        isCancel
                        label={this.context.t("cancel")}
                        onClick={this.props.resetForm}
                      />
                      <Button
                        label={this.context.t("edit")}
                        onClick={this._handleConfirm}
                      />
                    </div>
                  </div>
                </>
              )
            }
          </div>
        </div>
      </Template>
    );
  }
}

B2BCreate.contextTypes = {
  t: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
  user: state.currentUser,
  b2b: async function(){
    let b2bCompanyId = parseInt(window.location.pathname.split('/')[2])
    return await new B2BCompany().setToken(state.currentUser.token).find(b2bCompanyId);
  }()

  // b2b: {
  //   companyName: _.get(state.b2bCreate, 'infos.companyName', undefined),
  //   phonenumber: _.get(state.b2bCreate, 'infos.phonenumber', undefined),
  //   email: _.get(state.b2bCreate, 'infos.email', undefined),
  //   address: _.get(state.b2bCreate, 'infos.location.address', undefined),
    
  //   rollingLightVL: _.get(state.b2bCreate, 'rolling.light_vehicle', undefined),
  //   rollingVanVehicle: _.get(state.b2bCreate, 'rolling.van_vehicle', undefined),
  //   rollingCommercial: _.get(state.b2bCreate, 'rolling.commercial_vehicle', undefined),
  //   rollingLightPL: _.get(state.b2bCreate, 'rolling.light_pl', undefined),
  //   rollingMediumPL: _.get(state.b2bCreate, 'rolling.medium_pl', undefined),
  //   rollingHeavyPL: _.get(state.b2bCreate, 'rolling.heavy_pl', undefined),
    
  //   notRollingLightVL: _.get(state.b2bCreate, 'not_rolling.light_vehicle', undefined),
  //   notRollingVanVehicle: _.get(state.b2bCreate, 'not_rolling.van_vehicle', undefined),
  //   notRollingCommercial: _.get(state.b2bCreate, 'not_rolling.commercial_vehicle', undefined),
  //   notRollingLightPL: _.get(state.b2bCreate, 'not_rolling.light_pl', undefined),
  //   notRollingMediumPL: _.get(state.b2bCreate, 'not_rolling.medium_pl', undefined),
  //   notRollingHeavyPL: _.get(state.b2bCreate, 'not_rolling.heavy_pl', undefined),

  //   redeliveryLightVL: _.get(state.b2bCreate, 'redelivery.light_vehicle', undefined),
  //   redeliveryVanVehicle: _.get(state.b2bCreate, 'redelivery.van_vehicle', undefined),
  //   redeliveryCommercial: _.get(state.b2bCreate, 'redelivery.commercial_vehicle', undefined),
  //   redeliveryLightPL: _.get(state.b2bCreate, 'redelivery.light_pl', undefined),
  //   redeliveryMediumPL: _.get(state.b2bCreate, 'redelivery.medium_pl', undefined),
  //   redeliveryHeavyPL: _.get(state.b2bCreate, 'redelivery.heavy_pl', undefined),

  //   hourPoliceStation: _.get(state.b2bCreate, 'other.police_station', undefined),
  //   hourBasement: _.get(state.b2bCreate, 'other.basement', undefined),
  //   hourHandling: _.get(state.b2bCreate, 'other.handling', undefined),
  //   advanceOfCostCommission: _.get(state.b2bCreate, 'other.advance_of_cost', undefined),
  //   kmIncluded: _.get(state.b2bCreate, 'km_included', undefined),
  //   fullDistanceCalculation: _.get(state.b2bCreate, 'full_distance', undefined)
  // }
})
const mapDispatchToProps = (dispatch) => ({
  resetForm: () => dispatch({ type: B2BCreateType.B2B_CREATE_RESET_STATE }),
  push: (route) => dispatch(push(route))
})

export default connect(mapStateToProps, mapDispatchToProps)(B2BCreate);
