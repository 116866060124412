import React from 'react';

import './styles.scss';

class Label extends React.Component{
  render(){
    return(
      <span className="common-basics-label" style={this.props.customStyle}>
        {this.props.value ? this.props.value : ""}
      </span>
    );
  }
}

export default Label
