import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import MaterialIcon from 'material-icons-react';

import './styles.scss';
import OrderListType from '../../redux/types/order_list';
import Order from '../../core/Order';
import { status } from '../../constants';

import HeaderButtons from '../../components/HeaderButtons';
import FormButton from '../../components/Buttons/FormButton';
import SubmitButton from '../../components/Buttons/SubmitButton';
import SearchBar from '../../components/SearchBar';
import Label from '../../components/Label';
import Template from '../../templates/Backoffice';

class OrderPage extends React.Component{

  constructor(props){
    super(props);
    this.state = {
      searchOption: {label: "vehicle_registration", action: this.searchForVehicleRegistration},
      searchValue: ""
    }
  }

  componentDidMount(){
    this.props.updateSearchOrdersList()
  }

  searchForVehicleRegistration = (registration) => {
    if (registration !== null && registration !== undefined && registration !== "") {
      new Order().setToken(this.props.token).search({registration}).then(orders => {
        this.props.updateSearchOrdersList(orders)
      })
    } else {
      this.props.updateSearchOrdersList(undefined);
    }
  }

  searchForBillNumber = (billNumber) => {
      if (billNumber !== null && billNumber !== undefined && billNumber !== "") {
        new Order().setToken(this.props.token).search({billNumber}).then(orders => {
          this.props.updateSearchOrdersList(orders)
        })
      } else {
        this.props.updateSearchOrdersList(undefined);
      }
  }

  searchForName = (name) => {
      if (name !== null && name !== undefined && name !== "") {
        new Order().setToken(this.props.token).search({name}).then(orders => {
          this.props.updateSearchOrdersList(orders)
        })
      } else {
        this.props.updateSearchOrdersList(undefined);
      }
  }

  renderSearchBar = () => (
    <SearchBar
      searchOptions={[
        {label: "vehicle_registration", action: this.searchForVehicleRegistration},
        {label: "name", action: this.searchForName},
        {label: "bill_number", action: this.searchForBillNumber},
      ]}
      onChooseSearchOption={(option) => this.setState({searchOption: option})}
      getSearchValue={(value) => this.setState({searchValue: value})}
      current={this.state.searchOption}
    />
  )

  _onClickCancel = (id) => new Order().setToken(this.props.token).cancelOrder(id);

  _onClickRefresh = () => {
    new Order().setToken(this.props.token).findAll((this.props.orderPage) + 1).then(orders => {
      if (orders.length < 10 && this.props.orderRefresh) {
        this.props.addOrdersToListOfOrders(orders);
        this.props.updatePageAndRefresh({refresh: false});
      } else if (orders.length === 10) {
        this.props.addOrdersToListOfOrders(orders);
        this.props.updatePageAndRefresh({page: (this.props.orderPage) + 1, refresh: true});
      }
    })
  }

  renderOrderInTheList = (elem) => {
    let date = elem.wished_intervention_date.split('T');
    let day = date[0].split("-")[2];
    let month = date[0].split("-")[1];
    let year = date[0].split("-")[0];
    let hours = date[1].split(":")[0];
    let minutes = date[1].split(":")[1];

    let color = elem.cancelled ? "red" : "rgb(34, 109, 175)"

    return (
      <div className="order" key={elem.id}>
        <div>
          {elem.status === status.RECEIVED ? <MaterialIcon color={color} icon="description" /> : ""}
          {elem.status === status.ON_THE_WAY ? <MaterialIcon color={color} icon="departure_board" /> : ""}
          {elem.status === status.IN_PROGRESS ? <MaterialIcon color={color} icon="cached" /> : ""}
          {elem.status === status.FINISHED ? <MaterialIcon color={color} icon="done" /> : ""}
        </div>
        <div onClick={() => this.props.push("/order/" + elem.id)} style={{color: elem.cancelled ? "red" : undefined}}>
          <span>{elem.vehicle_model}</span>
          <span>{"Le " + day + "/" + month + "/" + year + " à " + hours + "h" + minutes}</span>
          <span>{Math.round(elem.final_price*1.2*100)/100 + "€"}</span>
        </div>
        <div>
          <a rel="noopener noreferrer" target="_blank" href={"https://www.google.com/maps/dir/"+ elem.departure_lat + "," + elem.departure_lng + "/" + elem.arrival_lat + "," + elem.arrival_lng}><MaterialIcon color={color} icon="place" /></a>
          { !elem.cancelled && <span onClick={() => this._onClickCancel(elem.id)}><MaterialIcon color={"rgb(192, 57, 43)"} icon="clear" /></span> }
        </div>
      </div>
    );
  }

  showOrderList = () => {
    if (this.props.searchOrders) {
      return (
        <div>
          { this.renderSearchBar() }
          <FormButton
            selected={this.state.allOrders ? true : false}
            onClick={() => this.setState({allOrders: !this.state.allOrders})}
            label={this.state.allOrders ? this.context.t("all_orders") : this.context.t("all_orders_not_finished")}
          />
          {
            this.props.searchOrders.length > 0 ?
              this.props.searchOrders.map((elem, i) => {
                let regString = (this.state.searchOption === "vehicle_registration" ? elem.vehicle_registration : (this.state.searchOption === "name" ? elem.name : elem.departure_address));
                if(new RegExp(_.get(this.state, 'searchValue', ""), "i").test(regString)){
                  return this.renderOrderInTheList(elem);
                }
                return(<div></div>);
              }) : <Label value={this.context.t('no_order')} />
          }
        </div>
      );
    } else {

      if (this.state.allOrders) {
        return (
          <div>
            { this.renderSearchBar() }
            <FormButton
              selected={this.state.allOrders ? true : false}
              onClick={() => this.setState({allOrders: !this.state.allOrders})}
              label={this.state.allOrders ? this.context.t("all_orders") : this.context.t("all_orders_not_finished")}
            />
            {
              this.props.orderList.length > 0 ?
                this.props.orderList.map((elem, i) => {
                  let regString = (this.state.searchOption === "vehicle_registration" ? elem.vehicle_registration : (this.state.searchOption === "name" ? elem.name : elem.departure_address));
                  if(new RegExp(_.get(this.state, 'searchValue', ""), "i").test(regString)){
                    return this.renderOrderInTheList(elem);
                  }
                  return(<div></div>);
                }) : <Label value={this.context.t('no_order')} />
            }
            { this.props.orderRefresh ? <SubmitButton onClick={this._onClickRefresh} label={this.context.t("see_older_orders")}/> : "" }
          </div>
        );
      } else {
        if (this.props.orderListNotFinished.length === 0) {
          return (
            <div>
              { this.renderSearchBar() }
              <FormButton
                selected={this.state.allOrders ? true : false}
                onClick={() => this.setState({allOrders: !this.state.allOrders})}
                label={this.state.allOrders ? this.context.t("all_orders") : this.context.t("all_orders_not_finished")}
              />
              <Label value={this.context.t('no_order_in_progress')} />
            </div>
          );
        } else {
          return (
            <div>
              { this.renderSearchBar() }
              <FormButton
                selected={this.state.allOrders ? true : false}
                onClick={() => this.setState({allOrders: !this.state.allOrders})}
                label={this.state.allOrders ? this.context.t("all_orders") : this.context.t("all_orders_not_finished")}
              />
              <h2>{this.context.t('to_be_dispatched')}</h2>
              {
                this.props.orderListNotFinished.map((elem, i) => {
                  let regString = (this.state.searchOption === "vehicle_registration" ? elem.vehicle_registration : (this.state.searchOption === "name" ? elem.name : elem.departure_address));
                  if(new RegExp(_.get(this.state, 'searchValue', ""), "i").test(regString) && elem.company === undefined){
                    return this.renderOrderInTheList(elem);
                  }
                  return null;
                })
              }
              <h2>{this.context.t('without_driver')}</h2>
              {
                this.props.orderListNotFinished.map((elem, i) => {
                  let regString = (this.state.searchOption === "vehicle_registration" ? elem.vehicle_registration : (this.state.searchOption === "name" ? elem.name : elem.departure_address));
                  if(new RegExp(_.get(this.state, 'searchValue', ""), "i").test(regString) && elem.company !== undefined && elem.driver === undefined){
                    return this.renderOrderInTheList(elem);
                  }
                  return null;
                })
              }
              <h2>{this.context.t('is_dispatched')}</h2>
              {
                this.props.orderListNotFinished.map((elem, i) => {
                  let regString = (this.state.searchOption === "vehicle_registration" ? elem.vehicle_registration : (this.state.searchOption === "name" ? elem.name : elem.departure_address));
                  if(new RegExp(_.get(this.state, 'searchValue', ""), "i").test(regString) && elem.company !== undefined && elem.driver !== undefined){
                    return this.renderOrderInTheList(elem);
                  }
                  return null;
                })
              }
            </div>
          )
        }
      }
    }
  }

  render(){
    return(
      <Template>
        <div className="order-page">
          <HeaderButtons
            tabList={[
              {
                label: this.context.t('intervention_request'),
                route: 'order',
                onClick: () => this.props.push("/order")
              },
              {
                label: this.context.t('create_interventions'),
                route: 'order/create',
                onClick: () => this.props.push("/order/create")
              }
            ]}
          />
          {
            this.props.orderList === undefined ? <Label value={this.context.t('loading')} /> : this.showOrderList()
          }
        </div>
      </Template>
    );
  }
}

OrderPage.contextTypes = {
  t: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
  orderList: state.orderList.orders,
  orderPage: state.orderList.page,
  orderRefresh: state.orderList.refresh,
  searchOrders: state.orderList.searchOrders,
  orderListNotFinished: state.orderList.orders_not_finished,
  token: state.currentUser.token,
  user: state.currentUser
})
const mapDispatchToProps = (dispatch) => ({
  push: (route) => dispatch(push(route)),
  cancelOrder: (orders) => dispatch({
    type: OrderListType.ORDERLIST_UPDATE_LIST,
    payload: orders
  }),
  updateOrderList: (token) => {
    new Order().setToken(token).findAll().then(orders => {
      dispatch({
        type: OrderListType.ORDERLIST_UPDATE_LIST,
        payload: orders
      })
    })
  },
  addOrdersToListOfOrders: (orders) => dispatch({
    type: OrderListType.ORDERLIST_ADD_ORDERS_TO_THE_LIST,
    payload: orders
  }),
  updatePageAndRefresh: (payload) => dispatch({
    type: OrderListType.ORDERLIST_UPDATE_PAGE_REFRESH,
    payload: {
      page: (payload.page !== undefined ? payload.page : undefined),
      refresh: (payload.refresh !== undefined ? payload.refresh : undefined)
    }
  }),
  updateSearchOrdersList: (orders) => dispatch({
    type: OrderListType.ORDERLIST_UPDATE_SEARCH_LIST,
    payload: orders ? orders : undefined
  })
})

export default connect(mapStateToProps, mapDispatchToProps)(OrderPage);
