import $ from 'jquery';

import env from '../../env';

class TowTruck {

  constructor(towtruck = {}){
    this.companyIdentifier =  towtruck.companyIdentifier;
    this.driverIdentifier = towtruck.driverIdentifier;
    this.type =  towtruck.type;
    this.registration =  towtruck.registration;
    this.brand = towtruck.brand;
    this.model = towtruck.model;
  }

  setToken(token){
    this.token = token;
    return this;
  }

  update(id){
    return new Promise((resolve, reject) => {
      $.ajax(env.backendUrl + "api/v1/trucks/" + id, {
        type: "POST",
        crossDomain: true,
        headers: {
          "Authorization": "Bearer " + this.token
        },
        data: {
          driverIdentifier: this.driverIdentifier
        }
      }).done(data => resolve(data))
      .catch((xhr, status, error) => reject(status));
    })
  }

  remove(id){
    return new Promise((resolve, reject) => {
      $.ajax(env.backendUrl + "api/v1/trucks/" + id, {
        type: "DELETE",
        crossDomain: true,
        dataType: "json",
        headers: {
          "Authorization": "Bearer " + this.token
        },
      }).done(data => resolve(data)).catch(err => reject(err));
    })
  }

  create(){
    return new Promise((resolve, reject) => {
      $.ajax(env.backendUrl + "api/v1/trucks", {
        type: "POST",
        crossDomain: true,
        headers: {
          "Authorization": "Bearer " + this.token
        },
        data: {
          companyIdentifier: this.companyIdentifier,
          type: this.type,
          registration: this.registration,
          brand: this.brand,
          model: this.model
        }
      }).done(data => resolve(data))
      .catch((xhr, status, error) => reject(status));
    })
  }

  findAll(){
    return new Promise((resolve, reject) => {
      $.ajax(env.backendUrl + "api/v1/trucks", {
        crossDomain: true,
        headers: {
          "Authorization": "Bearer " + this.token
        }
      }).done(data => resolve(data))
      .catch((xhr, status, error) => reject(status));
    })
  }

  findOne(id){
    return new Promise((resolve, reject) => {
      $.ajax(env.backendUrl + "api/v1/trucks/"+id, {
        crossDomain: true,
        headers: {
          "Authorization": "Bearer " + this.token
        }
      }).done(data => resolve(data))
      .catch((xhr, status, error) => reject(status));
    })
  }

  searchRegistration(registration){
    return new Promise((resolve, reject) => {
      $.ajax(env.backendUrl + "api/v1/trucks?registration="+registration, {
        crossDomain: true,
        headers: {
          "Authorization": "Bearer " + this.token
        }
      }).done(data => resolve(data))
      .catch((xhr, status, error) => reject(status));
    })
  }

  searchCompanyName(companyName){
    return new Promise((resolve, reject) => {
      $.ajax(env.backendUrl + "api/v1/trucks?companyName="+companyName, {
        crossDomain: true,
        headers: {
          "Authorization": "Bearer " + this.token
        }
      }).done(data => resolve(data))
      .catch((xhr, status, error) => reject(status));
    })
  }

}

export default TowTruck;
