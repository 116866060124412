import React from 'react';

import './styles.scss';

class FormTemplate extends React.Component{
  render(){
    return(
      <div className={this.props.className ? "form " + this.props.className : "form"}>
        {this.props.children}
      </div>
    );
  }
}

export default FormTemplate;
