import $ from 'jquery';

import env from '../../env';
import { status } from '../../constants';

class Order {

  constructor(order = {}){
    this.vehicleBrand = order.vehicleBrand;
    this.vehicleModel = order.vehicleModel;
    this.vehicleRegistration = order.vehicleRegistration;
    this.problem = order.problem;
    this.reason = order.reason;
    this.vehicleType = order.vehicleType;
    this.departureAddress = order.departureAddress;
    this.departureLat = order.departureLat;
    this.departureLng = order.departureLng;
    this.arrivalAddress = order.arrivalAddress;
    this.arrivalLat = order.arrivalLat;
    this.arrivalLng = order.arrivalLng;
    this.limitedHeight = order.limitedHeight;
    this.firstname = order.firstname;
    this.name = order.name;
    this.email = order.email;
    this.phonenumber = order.phonenumber;
    this.price = isNaN(order.price) || order.price === undefined || order.price === null ? null : order.price;
    this.companyIdentifier = order.companyIdentifier;
    this.driverIdentifier = order.driverIdentifier;
    this.paymentMethod = order.paymentMethod;
    this.paid = order.paid ? 1 : 0;
    this.note = order.note;
    this.billAddress = order.billAddress;
    this.wishedInterventionDate = order.wishedInterventionDate;
    this.policeLat = order.policeLat;
    this.policeLng = order.policeLng;
    this.addressPoliceStation = order.addressPoliceStation;
    this.b2bCompanyIdentifier = order.b2bCompanyIdentifier;
    this.rolling = order.rolling ? 1 : 0;
    this.advanceOfCost = order.advanceOfCost;
    this.advanceOfCostTva = order.advanceOfCostTva;
    this.discount = order.discount;
    this.ref = order.ref;
  }

  setToken = (token) => {
    this.token = token;
    return this;
  }

  updateHourPoliceStation = (id, waitingTimePolice) => {
    return new Promise((resolve, reject) => {
      $.ajax(env.backendUrl + "api/v1/orders/" + id, {
        type: "POST",
        crossDomain: true,
        headers: {
          "Authorization": "Bearer " + this.token
        },
        data: {
          waitingTimePolice
        }
      }).done(data => resolve(data)).catch(data => reject(data));
    })
  }

  updateHourHandling = (id, hourHandling) => {
    return new Promise((resolve, reject) => {
      $.ajax(env.backendUrl + "api/v1/orders/" + id, {
        type: "POST",
        crossDomain: true,
        headers: {
          "Authorization": "Bearer " + this.token
        },
        data: {
          hourHandling
        }
      }).done(data => resolve(data)).catch(data => reject(data));
    })
  }

  updateAdditionalDisplacement = (id, additionalDisplacement) => {
    return new Promise((resolve, reject) => {
      $.ajax(env.backendUrl + "api/v1/orders/" + id, {
        type: "POST",
        crossDomain: true,
        headers: {
          "Authorization": "Bearer " + this.token
        },
        data: {
          additionalDisplacement
        }
      }).done(data => resolve(data)).catch(data => reject(data));
    })
  }
  updateCleaning = (id, cleaning) => {
    return new Promise((resolve, reject) => {
      $.ajax(env.backendUrl + "api/v1/orders/" + id, {
        type: "POST",
        crossDomain: true,
        headers: {
          "Authorization": "Bearer " + this.token
        },
        data: {
          cleaning
        }
      }).done(data => resolve(data)).catch(data => reject(data));
    })
  }
  updateTrolls = (id, tolls) => {
    return new Promise((resolve, reject) => {
      $.ajax(env.backendUrl + "api/v1/orders/" + id, {
        type: "POST",
        crossDomain: true,
        headers: {
          "Authorization": "Bearer " + this.token
        },
        data: {
          tolls
        }
      }).done(data => resolve(data)).catch(data => reject(data));
    })
  }

  downloadBill = (id, billNumber = undefined) => {
    return new Promise((resolve, reject) => {
      fetch(env.backendUrl + "api/v1/orders/"+id+"/bill"+(billNumber ? "?billNumber="+billNumber : ""), {
        headers: {
          "Content-Type": "application/pdf",
          "Authorization": "Bearer " + this.token
        }
      })
        .then(response => response.blob())
        .then(data => resolve(URL.createObjectURL(data)))
    });
  }

  downloadCredit = (id, creditNumber = undefined) => {
    return new Promise((resolve, reject) => {
      fetch(env.backendUrl + "api/v1/orders/"+id+"/credit"+(creditNumber ? "?creditNumber="+creditNumber : ""), {
        headers: {
          "Content-Type": "application/pdf",
          "Authorization": "Bearer " + this.token
        }
      })
        .then(response => response.blob())
        .then(data => resolve(URL.createObjectURL(data)))
    });
  }

  orderIsPaid = (id, paid = 1) => {
    return new Promise((resolve, reject) => {
      $.ajax(env.backendUrl + "api/v1/orders/" + id, {
        type: "POST",
        crossDomain: true,
        headers: {
          "Authorization": "Bearer " + this.token
        },
        data: {
          paid: paid
        }
      }).done(data => resolve(data)).catch(data => reject(data));
    })
  }

  calculatePrice(){
    return new Promise((resolve, reject) => {
      $.ajax( env.backendUrl + "api/v1/orders/price", {
        type: 'GET',
        crossDomain: true,
        data: {
          vehicleBrand: this.vehicleBrand,
          vehicleModel: this.vehicleModel,
          vehicleRegistration: this.vehicleRegistration,
          problem: this.problem,
          reason: this.reason,
          vehicleType: this.vehicleType,
          departureAddress: this.departureAddress,
          departureLat: this.departureLat,
          departureLng: this.departureLng,
          arrivalAddress: this.arrivalAddress,
          arrivalLat: this.arrivalLat,
          arrivalLng: this.arrivalLng,
          limitedHeight: this.limitedHeight ? 1 : 0,
          firstname: this.firstname === "" ? undefined : this.firstname,
          name: this.name === "" ? undefined : this.name,
          email: this.email,
          phonenumber: this.phonenumber,
          policeLat: this.policeLat,
          policeLng: this.policeLng,
          addressPoliceStation: this.addressPoliceStation,
          b2bCompanyIdentifier: this.b2bCompanyIdentifier ? this.b2bCompanyIdentifier : undefined,
          rolling: this.rolling,
          advanceOfCost: this.advanceOfCost && this.advanceOfCost >= 0 ? this.advanceOfCost : 0,
          discount: this.discount ? this.discount : 0
        }
      }).done(price => resolve(price))
    }).catch(err => console.error("Une erreur est survenue : ", err));
  }

  findAll(page = 0){
    return new Promise((resolve, reject) => {
      $.ajax( env.backendUrl + "api/v1/orders", {
        type: "GET",
        crossDomain: true,
        headers: {
          "Authorization": "Bearer " + this.token
        },
        data: {
          all: true,
          page: page
        }
      }).done(data => {
        resolve(data);
      })
    })
  }

  changeVehicleRegistration(id, vehicleRegistration){
    return new Promise((resolve, reject) => {
      $.ajax( env.backendUrl + "api/v1/orders/"+id, {
        type: "POST",
        crossDomain: true,
        headers: {
          "Authorization": "Bearer " + this.token
        },
        data: {vehicleRegistration}
      }).done(data => {
        resolve(data);
      })
    })
  }

  findAllNotFinished(){
    return new Promise((resolve, reject) => {
      $.ajax( env.backendUrl + "api/v1/orders", {
        type: "GET",
        crossDomain: true,
        headers: {
          "Authorization": "Bearer " + this.token
        },
        data: {}
      }).done(data => {
        resolve(data);
      })
    })
  }

  findOne(id = null){
    if(id === null || id === undefined || isNaN(id))
      return this.findAll();
    return new Promise((resolve, reject) => {
      $.ajax(env.backendUrl + "api/v1/orders/" + id, {
        type: "GET",
        crossDomain: true,
        headers: {
          "Authorization": "Bearer " + this.token
        }
    }).done(order => resolve(order));
    })
  }

  setPoliceStationIsDone(id){
    return new Promise((resolve, reject) => {
      $.ajax(env.backendUrl + "api/v1/orders/"+id, {
        type: "POST",
        crossDomain: true,
        headers: {
          "Authorization": "Bearer " + this.token
        },
        data: {
          policeStationIsDone: 1
        }
      })
    })
  }

  setAdvanceOfCostIsDone(id){
    return new Promise((resolve, reject) => {
      $.ajax(env.backendUrl + "api/v1/orders/"+id, {
        type: "POST",
        crossDomain: true,
        headers: {
          "Authorization": "Bearer " + this.token
        },
        data: {
          advanceOfCostIsDone: 1
        }
      })
    })
  }

  changeStatus(id, statusValue = status.FINISHED){
    const differentStatus = [status.RECEIVED, status.ON_THE_WAY, status.IN_PROGRESS, status.FINISHED];
    return new Promise((resolve, reject) => {
      if(!differentStatus.includes(statusValue))
        reject()
      $.ajax(env.backendUrl + "api/v1/orders/" + id, {
        type: "POST",
        crossDomain: true,
        headers: {
          "Authorization": "Bearer " + this.token
        },
        data: {
          status: statusValue
        }
      }).done(data => resolve(data)).catch(data => reject(data));
    })
  }

  generateNewBill(id, creditAmount = undefined){
    return new Promise((resolve, reject) => {
      $.ajax(env.backendUrl + "api/v1/orders/" + id + "/newbill", {
        type: "POST",
        crossDomain: true,
        headers: {
          "Authorization": "Bearer " + this.token
        },
        data: { creditAmount }
      }).done(data => resolve(data)).catch(data => reject(data));
    })
  }

  dispatchOrderToCompany(id, companyIdentifier){
    return new Promise((resolve, reject) => {
      $.ajax(env.backendUrl + "api/v1/orders/" + id, {
        type: "POST",
        crossDomain: true,
        headers: {
          "Authorization": "Bearer " + this.token
        },
        data: {
          companyIdentifier: companyIdentifier
        }
      }).done(data => resolve(data)).catch(data => reject(data));
    })
  }

  dispatchOrderToDriver(id, driverIdentifier){
    return new Promise((resolve, reject) => {
      $.ajax(env.backendUrl + "api/v1/orders/" + id, {
        type: "POST",
        crossDomain: true,
        headers: {
          "Authorization": "Bearer " + this.token
        },
        data: {
          driverIdentifier: driverIdentifier
        }
      }).done(data => resolve(data)).catch(data => reject(data));
    })
  }

  setPaymentMethod(id, paymentMethod){
    return new Promise((resolve, reject) => {
      $.ajax(env.backendUrl + "api/v1/orders/" + id, {
        type: "POST",
        crossDomain: true,
        headers: {
          "Authorization": "Bearer " + this.token
        },
        data: {
          paymentMethod: paymentMethod
        }
      }).done(data => resolve(data)).catch(data => reject(data));
    })
  }

  cancelOrder(id){
    return new Promise((resolve, reject) => {
      $.ajax(env.backendUrl + "api/v1/orders/" + id, {
        type: "POST",
        crossDomain: true,
        headers: {
          "Authorization": "Bearer " + this.token
        },
        data: {
          cancelled: 1
        }
      }).done(data => resolve(data)).catch(data => reject(data));
    })
  }

  deleteOrder(id){
      return new Promise((resolve, reject) => {
        $.ajax(env.backendUrl + "api/v1/orders/" + id, {
          type: "POST",
          crossDomain: true,
          headers: {
            "Authorization": "Bearer " + this.token
          },
          data: {
            deleted: 1
          }
        }).done(data => resolve(data)).catch(data => reject(data));
      })
  }

  createOrder(){
    if(this.price === null || this.price === undefined || isNaN(this.price)){
      return new Promise((resolve, reject) => {
        $.ajax(env.backendUrl + "api/v1/orders", {
          type: 'POST',
          crossDomain: true,
          headers: {
            "Authorization": "Bearer " + this.token
          },
          data: {
            vehicleBrand: this.vehicleBrand,
            vehicleModel: this.vehicleModel,
            vehicleRegistration: this.vehicleRegistration,
            problem: this.problem,
            reason: this.reason,
            vehicleType: this.vehicleType,
            departureAddress: this.departureAddress,
            departureLat: this.departureLat,
            departureLng: this.departureLng,
            arrivalAddress: this.arrivalAddress,
            arrivalLat: this.arrivalLat,
            arrivalLng: this.arrivalLng,
            limitedHeight: this.limitedHeight ? 1 : 0,
            firstname: this.firstname,
            name: this.name,
            email: this.email,
            phonenumber: this.phonenumber,
            companyIdentifier: this.companyIdentifier,
            driverIdentifier: this.driverIdentifier,
            paymentMethod: this.paymentMethod,
            paid: this.paid ? 1 : 0,
            note: this.note,
            billAddress: (this.billAddress ? this.billAddress : undefined),
            wishedInterventionDate: this.wishedInterventionDate,
            policeLat: this.policeLat,
            policeLng: this.policeLng,
            addressPoliceStation: this.addressPoliceStation,
            b2bCompanyIdentifier: this.b2bCompanyIdentifier ? this.b2bCompanyIdentifier : undefined,
            rolling: this.rolling,
            advanceOfCost: this.advanceOfCost && this.advanceOfCost >= 0 ? this.advanceOfCost : 0,
            discount: this.discount ? this.discount : 0,
            advanceOfCostTva: this.advanceOfCostTva ? 1 : 0,
            ref: this.ref
          }
        }).done(data => resolve(data));
      });
    }
    return new Promise((resolve, reject) => {
      $.ajax(env.backendUrl + "api/v1/orders", {
        type: 'POST',
        crossDomain: true,
        headers: {
          "Authorization": "Bearer " + this.token
        },
        data: {
          vehicleBrand: this.vehicleBrand,
          vehicleModel: this.vehicleModel,
          vehicleRegistration: this.vehicleRegistration,
          problem: this.problem,
          reason: this.reason,
          vehicleType: this.vehicleType,
          departureAddress: this.departureAddress,
          departureLat: this.departureLat,
          departureLng: this.departureLng,
          arrivalAddress: this.arrivalAddress,
          arrivalLat: this.arrivalLat,
          arrivalLng: this.arrivalLng,
          limitedHeight: this.limitedHeight ? 1 : 0,
          firstname: this.firstname,
          name: this.name,
          email: this.email,
          phonenumber: this.phonenumber,
          price: this.price,
          companyIdentifier: this.companyIdentifier,
          driverIdentifier: this.driverIdentifier,
          paymentMethod: this.paymentMethod,
          paid: this.paid ? 1 : 0,
          note: this.note,
          billAddress: (this.billAddress ? this.billAddress : undefined),
          wishedInterventionDate: this.wishedInterventionDate,
          policeLat: this.policeLat,
          policeLng: this.policeLng,
          addressPoliceStation: this.addressPoliceStation,
          b2bCompanyIdentifier: this.b2bCompanyIdentifier ? this.b2bCompanyIdentifier : undefined,
          rolling: this.rolling,
          advanceOfCost: this.advanceOfCost && this.advanceOfCost >= 0 ? this.advanceOfCost : 0,
          discount: this.discount ? this.discount : 0,
          advanceOfCostTva: this.advanceOfCostTva ? 1 : 0,
          ref: this.ref
        }
      }).done(data => resolve(data));
    });
  }

  search(searchValue = {}){
    return new Promise((resolve, reject) => {
      $.ajax(env.backendUrl + "api/v1/orders/search", {
        type: "GET",
        crossDomain: true,
        headers: {
          "Authorization": "Bearer " + this.token
        },
        data: {
          registration: searchValue.registration ? searchValue.registration : undefined,
          billNumber: searchValue.billNumber ? searchValue.billNumber : undefined,
          name: searchValue.name ? searchValue.name : undefined
        }
      }).done(data => resolve(data)).catch(data => reject(data));
    })
  }
}

export default Order;
