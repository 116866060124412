import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';

import Input from '../../components/Input';

import './styles.scss';
import CompanyType from '../../redux/types/company';

class CompanyBank extends React.Component{

  render(){
      return(
        <div>
          <h2>{this.context.t('question_company_bank')}</h2>
          <Input
            getValue={value => this.props.setIban(value)}
            placeholder={this.context.t('company_iban')}
            priorityValue={this.props.iban}
            autoComplete={false}
          />
          <Input
            getValue={value => this.props.setCommission(value)}
            placeholder={this.context.t('company_commission')}
            priorityValue={this.props.defaultCommission}
            autoComplete={false}
          />
        </div>
      );
    }
}

CompanyBank.contextTypes = {
  t: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
  iban: _.get(state, 'company.currentCompany.iban') ? state.company.currentCompany.iban : "",
  defaultCommission: _.get(state, 'company.currentCompany.defaultCommission') ? state.company.currentCompany.defaultCommission : ""
})

const mapDispatchToProps = (dispatch) => ({
  setIban: (iban) => dispatch({
    type: CompanyType.COMPANY_CREATE_SET_IBAN,
    payload: iban === "" || iban === null || iban === undefined ? undefined : iban
  }),
  setCommission: (commission) => dispatch({
    type: CompanyType.COMPANY_CREATE_SET_DEFAULT_COMMISSION,
    payload: commission === "" || commission === null || commission === undefined ? undefined : commission
  })
})

export default connect(mapStateToProps, mapDispatchToProps)(CompanyBank);
