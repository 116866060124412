import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import User from '../../core/User';
import UsersTypes from '../../redux/types/users';
import MaterialIcon from 'material-icons-react';

import './styles.scss';

import HeaderButtons from '../../components/HeaderButtons';
import SearchBar from '../../components/SearchBar';
import Label from '../../components/Label';
import Template from '../../templates/Backoffice';
import FormButton from '../../components/Buttons/FormButton';

class UsersPage extends React.Component{

  constructor(props){
    super(props);
    this.state = {
      searchOption: {label: "company_name", action: this.searchCompanyName},
      searchValue: "",
      searchList: undefined
    }
  }

  componentDidMount(){
    if(_.get(this.props, 'token', undefined) !== undefined)
      this.props.updateUserList(this.props.token);
  }

  searchCompanyName = (companyName) => {
    if (companyName !== null && companyName !== undefined && companyName !== "") {
      this.setState({ loading: true }, () => {
          new User().setToken(this.props.token).searchByCompanyName(companyName).then(users => {
            this.setState({ searchList: users, loading: false });
          })
      })
    } else {
      this.setState({ searchList: undefined, loading: false });
    }
  }

  searchName = (name) => {
    if (name !== null && name !== undefined && name !== "") {
      this.setState({ loading: true }, () => {
          new User().setToken(this.props.token).searchByName(name).then(users => {
            this.setState({ searchList: users, loading: false });
          })
      })
    } else {
      this.setState({ searchList: undefined, loading: false });
    }
  }

  renderUser = (elem, i) => {
    let regString = elem.fullname;
      if(new RegExp(_.get(this.state, 'searchValue', ""), "").test(regString)){
        return (
          <div className="users" key={i} onClick={() => this.props.push("/user/" + elem.id)}>
            <div>
              <div>
                {elem.roles.includes("ROLE_ADMIN") ? <MaterialIcon color="rgb(34, 109, 175)" icon="start" /> : elem.roles.includes("ROLE_DISPATCH") ? <MaterialIcon color="rgb(34, 109, 175)" icon="send" /> : elem.roles.includes("ROLE_DRIVER") ? <MaterialIcon color="rgb(34, 109, 175)" icon="local_shipping" /> : <MaterialIcon color="rgb(34, 109, 175)" icon="person" /> }
              </div>
              <div>
                {elem.fullname + " (" + elem.company.name + ")"}
              </div>
            </div>
            <div>
              <a target="_blank" rel="noopener noreferrer" href={"https://maps.google.com/?q=" + elem.company.latitude + "," + elem.company.longitude}>
                <MaterialIcon color="rgb(34, 109, 175)" icon="place" />
              </a>
            </div>
          </div>
        )
      }
    return (<div></div>);
  }

  renderList = () => {
    return (
      <div>
        {
          this.state.loading && <Label value={this.context.t('loading')} />
        }
        {
          !this.state.loading && !this.state.searchList && this.props.usersList.map(this.renderUser)
        }
        {
          !this.state.loading && this.state.searchList && this.state.searchList.length > 0 && this.state.searchList.map(this.renderUser)
        }
        {
          !this.state.loading && this.state.searchList && this.state.searchList.length === 0 && <Label value={this.context.t('no_user_in_search')} />
        }
      </div>
    )
  }

  render(){
    return(
      <Template>
        <div className="users-pages">
          <HeaderButtons
            tabList={[
              {
                label: this.context.t('list_of_users'),
                route: 'user',
                onClick: () => this.props.push("/user")
              },
              {
                label: this.context.t('create_user'),
                route: 'user/create',
                onClick: () => this.props.push("/user/create")
              }
            ]}
          />
          <SearchBar
            searchOptions={[
                {label: "company_name", action: this.searchCompanyName},
                {label: "name", action: this.searchName}
            ]}
            onChooseSearchOption={(option) => this.setState({searchOption: option})}
            getSearchValue={(value) => this.setState({searchValue: value})}
            current={this.state.searchOption}
          />
          {
            this.props.usersList === undefined ? <Label value={this.context.t('loading')} /> :
            this.props.usersList.length === 0 ? <Label value={this.context.t('no_order')} /> : this.renderList()
          }
        </div>
      </Template>
    );
  }
}

UsersPage.contextTypes = {
  t: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
  token: state.currentUser.token,
  user: state.currentUser.user,
  usersList: state.users.list
})
const mapDispatchToProps = (dispatch) => ({
  push: (route) => dispatch(push(route)),
  updateUserList: (token) => {
    new User().setToken(token).getAllUsers().then(data => {
      dispatch({
        type: UsersTypes.USERS_UPDATE_LIST,
        payload: data
      })
    })
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(UsersPage);
