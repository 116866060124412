import TowTruckType from '../types/towtruck';

const towTruckReducer = (state = {list: []}, action) => {
  switch(action.type){
    case TowTruckType.TOWTRUCK_UPDATE_LIST:
      return {
        ...state,
        list: action.payload
      };
    case TowTruckType.TOWTRUCK_ADD_TO_LIST:
      let newList = state.list;
      newList.push(action.payload);
      return {
        ...state,
        list: newList
      };
    case TowTruckType.TOWTRUCK_REMOVE_ONE:
      let listTab = [];
      state.list.forEach((truck, i) => { if (i !== action.payload) listTab.push(truck) });
      return {
        ...state,
        list: listTab
      }
    case TowTruckType.TOWTRUCK_UPDATE_A_TOWTRUCK:
      let list = state.list;
      let index = list.findIndex(towtruck => towtruck.id === action.payload.id);
      if (index === -1) {
        list.push(action.payload)
      } else {
        list[index] = action.payload;
      }
      return {
        ...state,
        list: list
      };
    default:
      return state;
  }
}

export default towTruckReducer;
