const OrderType = {
  ORDER_SELECT_PROBLEM: "ORDER_SELECT_PROBLEM",
  ORDER_SELECT_REASON: "ORDER_SELECT_REASON",
  ORDER_SELECT_VEHICLE_TYPE: "ORDER_SELECT_VEHICLE_TYPE",
  ORDER_SELECT_LIMITED_HEIGHT: "ORDER_SELECT_LIMITED_HEIGHT",
  ORDER_SELECT_VEHICLE_BRAND: "ORDER_SELECT_VEHICLE_BRAND",
  ORDER_SELECT_VEHICLE_MODEL: "ORDER_SELECT_VEHICLE_MODEL",
  ORDER_SELECT_VEHICLE_REGISTRATION: "ORDER_SELECT_VEHICLE_REGISTRATION",
  ORDER_SELECT_DEPARTURE: "ORDER_SELECT_DEPARTURE",
  ORDER_SELECT_ARRIVAL: "ORDER_SELECT_ARRIVAL",
  ORDER_SELECT_B2B_COMPANY_NAME: 'ORDER_SELECT_B2B_COMPANY_NAME',
  ORDER_SELECT_CUSTOMER_NAME: "ORDER_SELECT_CUSTOMER_NAME",
  ORDER_SELECT_CUSTOMER_FIRSTNAME: "ORDER_SELECT_CUSTOMER_FIRSTNAME",
  ORDER_SELECT_CUSTOMER_EMAIL: "ORDER_SELECT_CUSTOMER_EMAIL",
  ORDER_SELECT_CUSTOMER_PHONENUMBER: "ORDER_SELECT_CUSTOMER_PHONENUMBER",
  ORDER_SELECT_CUSTOMER_BILL_ADDRESS: "ORDER_SELECT_CUSTOMER_BILL_ADDRESS",
  ORDER_SELECT_POLICE_STATION: "ORDER_SELECT_POLICE_STATION",
  ORDER_CANCEL_CREATE_ORDER: "ORDER_CANCEL_CREATE_ORDER",
  ORDER_SELECT_FINAL_PRICE: "ORDER_SELECT_FINAL_PRICE",
  ORDER_SELECT_DISCOUNT: "ORDER_SELECT_DISCOUNT",
  COMPANY_CREATE_SET_COMPANY: "COMPANY_CREATE_SET_COMPANY",
  COMPANY_CREATE_SET_DRIVER: "COMPANY_CREATE_SET_DRIVER",
  ORDER_FILL_ORDER_MODAL: "ORDER_FILL_ORDER_MODAL",
  ORDER_SELECT_PAYMENT_METHOD: "ORDER_SELECT_PAYMENT_METHOD",
  ORDER_SELECT_IF_ITS_PAID: "ORDER_SELECT_IF_ITS_PAID",
  ORDER_ADD_COMMENT: "ORDER_ADD_COMMENT",
  ORDER_SELECT_DATE: "ORDER_SELECT_DATE",
  ORDER_TOOGLE_B2B: 'ORDER_TOOGLE_B2B',
  ORDER_SELECT_B2B_COMPANY_INFOS: "ORDER_SELECT_B2B_COMPANY_INFOS",
  ORDER_SELECT_ROLLING: "ORDER_SELECT_ROLLING",
  ORDER_UNSET_POLICE_STATION: "ORDER_UNSET_POLICE_STATION",
  ORDER_SET_ADVANCE_OF_COST: "ORDER_SET_ADVANCE_OF_COST",
  ORDER_SET_ADVANCE_OF_COST_TVA: "ORDER_SET_ADVANCE_OF_COST_TVA",
  ORDER_SET_REF_NUMBER: "ORDER_SET_REF_NUMBER"
}

export default OrderType;
