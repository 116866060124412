import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

import Label from '../../components/Label';
import FormButton from '../../components/Buttons/FormButton';
import SubmitButton from '../../components/Buttons/SubmitButton';
import Input from '../../components/Input';
import Conversation from '../../core/Conversation';
import ConversationsType from '../../redux/types/conversations';
import moment from 'moment';

class RightColumn extends Component {
    constructor(props){
        super(props);
        this.state = {
            employesSelected: new Array(),
            message: ""
        }
    }

    _handleCreate = () => {
        new Conversation().setToken(this.props.token)
            .create(this.state.title, this.state.employesSelected)
            .then(c => this.setState({ employesSelected: new Array() }, () => {
                this.props.onChangeActive(c.id)
            }));
    }

    _handleSendMessage = () => {
        if (this.state.message !== "") {
            const message = this.state.message;
            this.setState({ message: "" }, () => {
                new Conversation().setToken(this.props.token).sendMessage(this.props.conversation.id, message);
            })
        }
    }

    componentDidMount(){
        let messageHTMLElement = document.querySelector(".messages-section");
        if (messageHTMLElement) {
            this.setState({ width: messageHTMLElement })
        }
    }

    render(){
        return (
            <div className="right-column">
                {
                    this.props.creation ? (
                        <div className="creation">
                            <h2>Créer une discution</h2>
                            <div>
                                <Label value="Choisissez un titre ?" />
                                <Input placeholder="Choisissez un titre" getValue={ title => this.setState({ title }) } />
                                <Label value="Qui ajouter dans cette discution ?" />
                                {
                                    this.props.employes.map((employe) => (
                                        <FormButton key={employe.id} label={employe.fullname} selected={this.state.employesSelected.includes(employe.id)} onClick={() => {
                                            if (this.state.employesSelected.includes(employe.id)) {
                                                this.setState({ employesSelected: this.state.employesSelected.filter(e => e !== employe.id) })
                                            } else {
                                                this.setState({ employesSelected: [...this.state.employesSelected, employe.id] })
                                            }
                                        }} />
                                    ))
                                }
                            </div>
                            <SubmitButton label="Créer" onClick={this._handleCreate}/> 
                        </div>
                    ) : (
                        <div className="messages-section">
                            <div className="participants">
                                {
                                    this.props.conversation && this.props.conversation.participants.map((p, i) => (
                                        <p key={p.id}>{p.fullname}</p>
                                    ))
                                }
                                <p onClick={() => new Conversation().setToken(this.props.token).leave(this.props.conversation.id).then(c => this.props.onChangeActive(c.id))}>Quitter</p>
                            </div>
                            <div className="content">
                                <div>
                                    { this.props.conversation && this.props.conversation.messages.map((message) => (
                                        <div className={this.props.userId === message.author.id ? "author" : ""} key={message.id}>
                                            { message.message }
                                            <span>{message.author.firstname} {message.author.name.charAt(0)}. le { moment(message.created_at).format('DD/MM/YY à HH:mm')}</span>
                                        </div>
                                    )) }
                                </div>
                                <form onSubmit={(e) => { e.preventDefault(); this._handleSendMessage() }}>
                                    <Input placeholder="Votre message..." priorityValue={this.state.message} getValue={message => this.setState({ message })}/>
                                    <FormButton onClick={this._handleSendMessage} label="Envoyer" selected={this.state.message !== ""} />
                                </form>
                            </div>
                        </div>
                    )
                }
            </div>
        )
    }
} 

// RightColumn.contextType = {
//     t: PropTypes.func.isRequired
// }

const mapStateToProps = (state) => ({
    employes: _.get(state, 'currentUser.user.company.employes', []).filter(employe => employe.id !== state.currentUser.user.id),
    userId: _.get(state, 'currentUser.user.id', -1),
    token: state.currentUser.token
});

const mapDispatchToProps = (dispatch) => ({
    addConversationMessage: (message) => dispatch({
        type: ConversationsType.CONVERSATIONS_ADD_MESSAGE,
        payload: message
    })
});

export default connect(mapStateToProps, mapDispatchToProps)(RightColumn);