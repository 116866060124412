import $ from 'jquery';

import env from '../../env';

class Vehicle{

  setToken(token){
    this.token = token ? token : "";
    return this;
  }

  searchBrands(brand){
    return new Promise((resolve, reject) => {
      $.ajax( env.backendUrl + "api/v1/vehicles/brands", {
        method: 'GET',
        crossDomain: true,
        headers: {
          "Authorization": "Bearer " + this.token
        },
        data: {
          brand: brand
        }
      }).done(data => resolve(data))
    }).catch(err => console.error("Une erreur est survenue : ", err));
  }

  searchModels(model, brand = undefined){
    return new Promise((resolve, reject) => {
      $.ajax( env.backendUrl + "api/v1/vehicles/models", {
        method: 'GET',
        crossDomain: true,
        headers: {
          "Authorization": "Bearer " + this.token
        },
        data: {
          model: model,
          brand: brand
        }
      }).done(data => resolve(data))
    }).catch(err => console.error("Une erreur est survenue : ", err));
  }

  findOneByBrandAndModel(brand, model){
    return new Promise((resolve, reject) => {
      $.ajax( env.backendUrl + "api/v1/vehicles/search", {
        method: 'GET',
        crossDomain: true,
        headers: {
          "Authorization": "Bearer " + this.token
        },
        data: {
          model: model,
          brand: brand
        }
      }).done(data => resolve(data))
    }).catch(err => console.error("Une erreur est survenue : ", err));
  }

  findVehiclesToBeUpdated(){
    return new Promise((resolve, reject) => {
      $.ajax( env.backendUrl + "api/v1/vehicles/update", {
        method: 'GET',
        crossDomain: true,
        headers: {
          "Authorization": "Bearer " + this.token
        },
        data: {}
      }).done(data => resolve(data))
    }).catch(err => console.error("Une erreur est survenue : ", err));
  }

  updateAVehicle(id, confirm = false){
    return new Promise((resolve, reject) => {
      $.ajax( env.backendUrl + "api/v1/vehicles/update", {
        method: 'POST',
        crossDomain: true,
        headers: {
          "Authorization": "Bearer " + this.token
        },
        data: {
          id: id,
          confirm: confirm ? true : undefined
        }
      }).done(data => resolve(data))
    }).catch(err => console.error("Une erreur est survenue : ", err));
  }
}

export default Vehicle;
