import React from 'react';
import mapboxgl from 'mapbox-gl';
import './styles.scss';

mapboxgl.accessToken = 'pk.eyJ1IjoicG94aWxpdW0iLCJhIjoiY2pxZXdsNWs0NGpqYTQzcHA4OXlxMWE4cyJ9.nra8KC_MUGMPuGMUKUQCRw';

class Map extends React.Component{

  componentDidMount(){
    // var zoomMap = window.innerHeight > window.innerWidth && window.innerWidth < 500 ? 4 : 5.5;
    var zoomMap = (window.innerWidth < 350 ? 3.5 : (window.innerWidth < 500 ? 4 : (window.innerWidth < 750 ? 4.5 : (window.innerWidth < 900 ? 5 : 5.5))))
    var mapCenter = [1.7191036, 46.71109];
    this.map = new mapboxgl.Map({
      container: document.getElementById("map"), // container id
      style: 'mapbox://styles/mapbox/streets-v11', // stylesheet location
      center: mapCenter, // starting position [lng, lat]
      zoom: zoomMap // starting zoom
    }).on("styleload", () => {
      this.map.resize();
    }).on('load', () => {
      this.props.orderList.forEach(elem => {
        let marker = document.createElement('div');
        marker.className = "marker-order";
        marker.addEventListener('click', () => this.props.onClickOrderMarker(elem.id))
        new mapboxgl.Marker(marker).setLngLat([elem.departure_lng, elem.departure_lat]).addTo(this.map);
      })
    });
  }

  componentDidUpdate(prevProps){
    if(prevProps.orderList !== this.props.orderList){
      this.setState({
        orderList: this.props.orderList
      }, () => {
        this.props.orderList.forEach(elem => {
          let marker = document.createElement('div');
          marker.className = "marker-order";
          new mapboxgl.Marker(marker).setLngLat([elem.departure_lng, elem.departure_lat]).addTo(this.map);
        })
      });
    }
  }

  render(){
    return(
      <div id="map" className="map"></div>
    )
  }
}

export default Map;
