import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';

import B2BCreateType from '../../redux/types/b2b_create_type';

import Input from '../../components/Input';

import './styles.scss';
import Autocomplete from '../../components/Autocomplete';

class B2BInfos extends React.Component{

  constructor(props){
      super(props);
      this.state = {};
  }

  _emailOnBlur = (e) => {
      if (this.state.error_email && /\S+@\S+\.\S{1,4}/.test(this.props.b2b.email)) {
          this.setState({ error_email: false })
      } else {
          if (!/\S+@\S+\.\S{1,4}/.test(this.props.b2b.email)) {
              this.setState({ error_email: true })
          } else {
              this.setState({ error_email: false })
          }
      }
  }

  _companyNameOnBlur = (e) => {
      if (this.state.error_company_name && /[a-zA-Z ]{2,}/.test(this.props.b2b.name)) {
          this.setState({ error_company_name: false })
      } else {
          if (!/[a-zA-Z ]{2,}/.test(this.props.b2b.name)) {
              this.setState({ error_company_name: true })
          } else {
              this.setState({ error_company_name: false })
          }
      }
  }

  _phonenumberOnBlur = (e) => {
      if (this.state.error_phonenumber && /[+0-9]{10,12}/.test(this.props.b2b.phonenumber)) {
          this.setState({ error_phonenumber: false })
      } else {
          if (!/[+0-9]{10,12}/.test(this.props.b2b.phonenumber)) {
              this.setState({ error_phonenumber: true })
          } else {
              this.setState({ error_phonenumber: false })
          }
      }
  }

  setLocation = (location) => {
    if (location) {
        this.setState({
            location: location.address,
        }, () => {
          this.props.setLocation({
            address: location.address,
            coords: {
              lng: location.coords[0],
              lat: location.coords[1]
            }
          })
        })
    }
  }

  render(){
      return(
        <div>
          <h2>{this.context.t('question_company_infos')}</h2>
          {
            this.state.error_company_name && <span className="error-message">{this.context.t('error_company_name')}</span>
          }
          <Input
            getValue={value => this.props.updateB2B('companyName', value)}
            placeholder={this.context.t('company_name')}
            priorityValue={this.props.b2b.companyName}
            autoComplete={false}
            onBlur={this._companyNameOnBlur}
            error={this.state.error_company_name}
          />
          {
            this.state.error_phonenumber && <span className="error-message">{this.context.t('error_phonenumber')}</span>
          }
          <Input
            getValue={value => this.props.updateB2B("phonenumber", value)}
            placeholder={this.context.t('phonenumber')}
            priorityValue={this.props.b2b.phonenumber}
            autoComplete={false}
            onBlur={this._phonenumberOnBlur}
            error={this.state.error_phonenumber}
          />
          {
            this.state.error_email && <span className="error-message">{this.context.t('error_email')}</span>
          }
          <Input
            getValue={value => this.props.updateB2B("email", value)}
            placeholder={this.context.t('company_email')}
            priorityValue={this.props.b2b.email}
            autoComplete={false}
            onBlur={this._emailOnBlur}
            error={this.state.error_email}
          />
          <Input
            getValue={value => this.props.updateB2B("tvaNumber", value)}
            placeholder={this.context.t('tva_number')}
            priorityValue={this.props.b2b.tvaNumber}
            autoComplete={false}
          />
          <Autocomplete 
            placeholder={this.context.t("company_address")}
            getValue={(value) => this.props.updateB2B("address", value)}
            defaultValue={this.props.b2b.address}
          />
        </div>
      );
    }
}

B2BInfos.contextTypes = {
  t: PropTypes.func.isRequired
}

export default B2BInfos;
