import $ from 'jquery';

import env from '../../env';

import Company from '../Company';

class Auth {

  constructor(login = null, password = null){
    this.login = login;
    this.password = password;
  }

  setToken(token){
    this.token = token;
    return this;
  }

  connexion(){
    return new Promise((resolve, reject) => {
      $.ajax({
          type: "POST",
          url: env.backendUrl + "api/v1/auth/login",
          contentType: "application/json; charset=utf-8",
          crossDomain: true,
          success: (data, textStatus, jqXHR) => {
            const { token } = data;
            if(token)
              this.setToken(token).getUser().then(async data => {
                  if(data.user.roles.includes('ROLE_ADMIN')) {
                    resolve(data)
                  } else
                    reject(401);
              });
          },
          error: (xhr, xhrOptions, throwError) => {
            if(env.debug) {
              console.error("xhr status : ", xhr.status);
              console.error("xhrOptions : ", xhrOptions);
              console.error("throwError : ", throwError);
            }
            reject(xhr.status);
          },
          data: JSON.stringify({username: this.login, password: this.password})
      });
    });
  }

  getUser(){
    return new Promise((resolve, reject) => {
      $.ajax({
        url: env.backendUrl + "api/v1/users/me",
        headers: {
          "Authorization": "Bearer " + this.token
        },
        contentType: "application/json; charset=utf-8",
        success: (async data => {
          data.company.employes = await new Company().setToken(this.token).getEmployes(data.company.id);
          resolve({
            token: this.token,
            user: data
          })
        }),
        error: (xhr, xhrOptions, throwError) => {
          if(env.debug) {
            console.error("xhr status : ", xhr.status);
            console.error("xhrOptions : ", xhrOptions);
            console.error("throwError : ", throwError);
          }
          reject(xhr.status);
        },
      })
    })
  }

}

export default Auth;
