import React from 'react';

import './styles.scss';

class AddButton extends React.Component{
  render(){
    return(
      <div className="common-addbutton" onClick={() => this.props.onClickAdd()}>
        +
      </div>
    )
  }
}

export default AddButton;
