import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import _ from 'lodash';
import './styles.scss';

import AddButton from '../Buttons/AddButton/index.js';

class StatusBar extends React.Component{

  constructor(props){
    super(props);
    this.state = {
      visible: window.innerWidth > 500 ? 1 : 0,
      size: window.innerWidth
    }
  }

  componentDidMount(){
    window.addEventListener("resize", () => this.setState({visible: window.innerWidth > 500 ? 1 : 0, size: window.innerWidth}))
  }
  componentWillUnmount(){
    window.removeEventListener("resize", () => this.setState({visible: window.innerWidth > 500 ? 1 : 0, size: window.innerWidth}))
  }

  showMenu = () => {
    // if(this.state.visible){
      return (
        <div style={{opacity: this.state.visible ? 1 : 0, right: this.state.visible ? 0 : "-200px"}}>
          <span
            className={this.props.route.split("/")[1] === "company" ? "active" : ""}
            onClick={() => {
              this.props.push('/company');
              if(this.state.size < 500)
                this.setState({visible: false});
            }}
          >
            {this.context.t("companies")}
          </span>
          <span
            className={this.props.route.split("/")[1] === "vehicle" ? "active" : ""}
            onClick={() => {
              this.props.push('/vehicle');
              if(this.state.size < 500)
                this.setState({visible: false});
            }}
          >
            {this.context.t("vehicles")}
          </span>
          <span
            className={this.props.route.split("/")[1] === "user" ? "active" : ""}
            onClick={() => {
              this.props.push('/user');
              if(this.state.size < 500)
                this.setState({visible: false});
            }}
          >
            {this.context.t("users")}
          </span>
        </div>
      );
    // }
    // return ( <div></div> );
  }

  render(){
    return(
      <div className="statusbar">
        <AddButton onClickAdd={() => this.props.push('/order/create')}/>
        <div>{this.context.t('hello') + ", " + _.get(this.props, 'user.firstname', "") + "."}</div>
        <div onClick={() => this.setState({visible: !this.state.visible})}></div>
        { this.showMenu() }
      </div>
    );
  }
}

StatusBar.contextTypes = {
  t: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
  route: state.routing.locationBeforeTransitions.pathname,
  user: state.currentUser.user
})

const mapDispatchToProps = (dispatch) => ({
  push: (route) => dispatch(push(route))
})

export default connect(mapStateToProps, mapDispatchToProps)(StatusBar);
