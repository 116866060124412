import React from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import PropTypes from 'prop-types';
import MaterialIcon from 'material-icons-react';
import _ from 'lodash';
import './styles.scss';

import Company from '../../core/Company';
import CompanyType from '../../redux/types/company';
import OrderCore from '../../core/Order';

import Template from '../../templates/Backoffice';
import HeaderButtons from '../../components/HeaderButtons';
import Label from '../../components/Label';
import SearchBar from '../../components/SearchBar';
import B2BCompany from '../../core/B2BCompany';
import Bill from '../../core/Bill';
import moment from 'moment';
import DetailsView from '../../templates/Details';
import { paymentMethod, vehicleType } from '../../constants';
import FormButton from '../../components/Buttons/FormButton';
import Input from '../../components/Input';
import SubmitButton from '../../components/Buttons/SubmitButton';

class BillDetails extends React.Component{

  constructor(props){
      super(props);
      this.state = {
        createCreditAmount: 0
      }
  }

  componentDidMount(){
    new Bill().setToken(this.props.user.token).findOneByBillNumber(this.props.params.billNumber).then(bill => this.setState(bill))
  }

  onClickDownloadCredit = (credit) => {
    if (credit) {
      new Bill({}).setToken(this.props.user.token).downloadCredit(this.props.params.billNumber, credit.credit_number).then(url => {
        let date = new Date(this.state.order_history ? this.state.order_history.wished_intervention_date : credit.created_at);
        let date_string = date.getUTCFullYear() + "" + (date.getMonth() < 10 ? "0"+(date.getMonth()+1):date.getMonth()+1) + "" + (date.getDate() < 10 ? "0"+date.getDate(): date.getDate())
        let file = document.createElement("a");
        file.setAttribute("href", url);
        let fileName = date_string + " . AVOIR " + credit.credit_number + " ";
        if (this.state.order_history && this.state.order_history.b2_bcompany) {
          fileName += this.state.order_history.b2_bcompany.company_name.toUpperCase() + " . ";
        } else if (this.state.order_history) {
          fileName += this.state.order_history.name.toUpperCase() + " " + this.state.order_history.firstname.toUpperCase() + " . ";
        } else {
          fileName += this.state.name.toUpperCase() + " . "
        }
        if (this.state.order_history) {
          fileName += "REMORQUAGE " + this.state.order_history.vehicle_registration;
        } else {
          fileName += "AUTRE"
        }
        file.setAttribute("download", fileName+".pdf");
        file.click();
      });
    }
  }

  onClickDownloadBill = (bill_number = this.props.params.billNumber) => {
    new Bill().setToken(this.props.user.token).downloadBill(bill_number).then(url => {
      let date = new Date(this.state.order_history ? this.state.order_history.wished_intervention_date : this.state.created_at);
      let date_string = date.getUTCFullYear() + "" + (date.getMonth() < 10 ? "0"+(date.getMonth()+1):date.getMonth()+1) + "" + (date.getDate() < 10 ? "0"+date.getDate(): date.getDate())
      let file = document.createElement("a");
      file.setAttribute("href", url);
      let fileName = date_string + " . FACTURE " + bill_number + " ";
      if (this.state.order_history && this.state.order_history.b2_bcompany) {
        fileName += this.state.order_history.b2_bcompany.company_name.toUpperCase() + " . ";
      } else if (this.state.order_history) {
        fileName += this.state.order_history.name.toUpperCase() + " " + this.state.order_history.firstname.toUpperCase() + " . ";
      } else {
        fileName += this.state.name.toUpperCase() + " . "
      }
      if (this.state.order_history) {
        fileName += "REMORQUAGE " + this.state.order_history.vehicle_registration;
      } else {
        fileName += "AUTRE"
      }
      
      file.setAttribute("download", fileName+".pdf");
      file.click();
    });
  }

  renderLine = (ref, label, quantity, pu, tva, amount) => (
    <tr>
      <td valign="top">{!!ref ? ref : ""}</td>
      <td valign="top">{!!label ? label : ""}</td>
      <td valign="top">{!!quantity ? quantity : ""}</td>
      {
        typeof pu !== typeof 1 ?
        <td valign="top">{pu}</td> : <td valign="top">{!!pu ? Number(pu).toFixed(2) + " €" : ""}</td>
      }
      
      <td valign="top">{!!tva || tva === 0 || tva === "0" ? tva + " %" : ""}</td>
      {
        typeof amount !== typeof 1 ? (
          <td valign="top">{ amount }</td>
        ) : (
          <td valign="top">{!!amount ? Number(amount).toFixed(2) + " €" : ""}</td>
        )
      }
    </tr>
  )

  renderOrderBill = (order) => {
    let final_price = order.final_price
    final_price -= Math.round(order.tolls/1.2*100)/100;
    if (!!order.address_police_station && order.waiting_time_police > 0) {
      final_price -= order.b2_bcompany && order.b2_bcompany.hour_police_station ? order.waiting_time_police * order.b2_bcompany.hour_police_station : order.waiting_time_police * 66;
    }
    if (order.limited_height) {
      final_price -= order.b2_bcompany && order.b2_bcompany.hour_basement ? order.b2_bcompany.hour_basement : 37.50;
    }
    let redeliveryPrice = 0;
    if (order.vehicle_type === vehicleType.LIGHT_VEHICLE) {
      redeliveryPrice = (order.b2_bcompany && order.b2_bcompany.redelivery_light_vl ? order.b2_bcompany.redelivery_light_vl : 99.17)
    } else if (order.vehicle_type === vehicleType.COMMERCIAL) {
      redeliveryPrice = (order.b2_bcompany && order.b2_bcompany.redelivery_commercial ? order.b2_bcompany.redelivery_commercial : 132.50)
    } else if (order.vehicle_type === vehicleType.VAN_VEHICLE) {
      redeliveryPrice = (order.b2_bcompany && order.b2_bcompany.redelivery_van_vehicle ? order.b2_bcompany.redelivery_van_vehicle : 132.50)
    } else if (order.vehicle_type === vehicleType.LIGHT_PL) {
      redeliveryPrice = (order.b2_bcompany && order.b2_bcompany.rolling_light_pl ? order.b2_bcompany.rolling_light_pl : 250.00)
    } else if (order.vehicle_type === vehicleType.MEDIUM_PL) {
      redeliveryPrice = (order.b2_bcompany && order.b2_bcompany.rolling_medium_pl ? order.b2_bcompany.rolling_medium_pl : 350.00)
    } else {
      redeliveryPrice = (order.b2_bcompany && order.b2_bcompany.rolling_heavy_pl ? order.b2_bcompany.rolling_heavy_pl : 519.00)
    }
    if (order.additional_displacement) {
      final_price -= redeliveryPrice * order.additional_displacement;
    }
    let handlingPrice = order.b2_bcompany && order.b2_bcompany.hour_handling ? order.b2_bcompany.hour_handling : 50;
    handlingPrice += (order.vehicle_type > vehicleType.VAN_VEHICLE ? order.vehicle_type - 10 : order.vehicle_type);
    if (order.hour_handling) {
      final_price -= handlingPrice * order.hour_handling;
    }
    if (order.b2_bcompany) {
      final_price -= order.b2_bcompany.advance_of_cost_commission*order.advance_of_cost/100;
    }
    
    if (order.cleaning) {
      final_price -= order.b2_bcompany && order.b2_bcompany.cleaning ? order.b2_bcompany.cleaning : 50
    }
    final_price += order.discount;

    return (
      <>
        {
          this.renderLine("FDR", 
            <ul>
              <li>Forfait dépannage remorquage</li>
              <li><b>{ order.vehicle_brand } { order.vehicle_model } - {order.vehicle_registration}</b></li>
              <li><u>De :</u> {order.departure_address}</li>
              { order.distance > 0 && <li><u>Vers :</u> { order.arrival_address }</li> }
              { !!order.ref && <li>Référence : <span style={{ color: "#226DAF" }}><b>{order.ref}</b></span></li>}
            </ul>, 1, Math.round(final_price*100)/100, 20, Math.round(final_price*100)/100)
        }
        {
          order.tolls > 0 ? this.renderLine("PEA", "Frais annexes", 1, Math.round(order.tolls/1.2*100)/100, 20, Math.round(order.tolls/1.2*100)/100) : ""
        }
        {
          !!order.address_police_station && order.waiting_time_police > 0 ? this.renderLine("HCF",
            <ul>
              <li>Heure de commissariat / fourrière</li>
              <li><u>À :</u> {order.address_police_station}</li>
            </ul>,
            order.waiting_time_police,
            order.b2_bcompany && order.b2_bcompany.hour_police_station ? order.b2_bcompany.hour_police_station : 66,
            20,
            order.b2_bcompany && order.b2_bcompany.hour_police_station ? order.waiting_time_police * order.b2_bcompany.hour_police_station : order.waiting_time_police * 66
          ) : ""
        }
        {
          !!order.limited_height ? this.renderLine("FHL", "Hauteur limitée ou sous-sol", 1, 
            order.b2_bcompany && order.b2_bcompany.hour_basement ? order.b2_bcompany.hour_basement : 37.50, 20,
            order.b2_bcompany && order.b2_bcompany.hour_basement ? order.b2_bcompany.hour_basement : 37.50
          ) : ""
        }
        {
          order.additional_displacement > 0 ? this.renderLine(
            "FDS", "Forfait déplacement supplémentaire", order.additional_displacement,
            redeliveryPrice, 20, redeliveryPrice * order.additional_displacement
          ) : ""
        }
        {
          order.hour_handling > 0 ? this.renderLine(
            "HDM", "Heure de manutention", order.hour_handling,
            handlingPrice, 20, handlingPrice * order.hour_handling
          ) : ""
        }
        {
          order.advance_of_cost ? this.renderLine(
            "ADF", "Avance de frais", 1, order.advance_of_cost_tva ? Math.round(order.advance_of_cost/1.2*100)/100 : order.advance_of_cost,
            order.advance_of_cost_tva ? 20 : 0,
            order.advance_of_cost_tva ? Math.round(order.advance_of_cost/1.2*100)/100 : order.advance_of_cost
          ) : ""
        }
        {
          order.advance_of_cost && order.b2_bcompany && order.b2_bcompany.advance_of_cost_commission ? this.renderLine(
            "CAF", "Commission sur avance de frais", 1, order.b2_bcompany.advance_of_cost_commission*order.advance_of_cost/100,
            20, order.b2_bcompany.advance_of_cost_commission*order.advance_of_cost/100
          ) : ""
        }
        {
          order.cleaning ? this.renderLine(
            "FND", "Forfait nettoyage dépanneuse", 1, order.b2_bcompany && order.b2_bcompany.cleaning ? order.b2_bcompany.cleaning : 50,
            20, order.b2_bcompany && order.b2_bcompany.cleaning ? order.b2_bcompany.cleaning : 50
          ) : ""
        }
        {
          order.discount ? this.renderLine(
            "REM", "Remise commerciale", 1, -1*order.discount, 20, -1*order.discount
          ) : ""
        }
      </>
    );
  }

  renderCSV = () => {
    return this.state.csv_data.split("\n").map((row) => {
      let data = row.split(";");
      return this.renderLine(data[0], data[1], parseInt(data[2]), parseInt(data[3]), parseInt(data[4]), parseInt(data[5]));
    })
  }

  addCredit = (amount) => {
    new Bill().setToken(this.props.user.token).addCredit(this.state.bill_number, Number(amount*1.2).toFixed(2)).then(bill => this.props.push("/bills"))
  }

  render(){
    console.log("Bill : ", this.state);
    return(
      <Template>
          {
            !this.state.bill_number ? (
              <Label value={this.context.t('loading')} />
            ) : (
              <DetailsView className="bills-detail-page">
                <div className="bill">
                  <img alt="logo" src="/logo.svg" />
                  <div className="buttons">
                    {
                      this.state.related_order ? (
                        <SubmitButton isCancel label="Annuler" onClick={() => new OrderCore().setToken(this.props.user.token).cancelOrder(this.state.related_order.id).then(() => this.props.push("/bills"))} />
                      ) : (
                        <SubmitButton isCancel label="Annuler" onClick={() => new Bill().setToken(this.props.user.token).cancel(this.state.bill_number).then(() => this.props.push("/bills"))} />
                      )
                    }
                    <SubmitButton label="Télécharger" onClick={this.onClickDownloadBill}/>
                  </div>
                  <header>
                    <div>
                      <p><b>DÉPANN'MOI</b></p>
                      <p>14, Avenue de l'Opéra</p>
                      <p>75001 PARIS 1</p>
                      <p>Tel : +33 805 382 262</p>
                      <p>alexandre@depannmoi.com</p>
                      <p>www.depannmoi.com</p>
                      <p>N° TVA Intracommunautaire : FR51822624615</p>
                      <p>N° SIRET : 82262461500016</p>
                      <p>RCS : BOBIGNY</p>
                      <p>Capital : 5 000 €</p>
                    </div>
                    {
                      this.state.order_history ? (
                        <div>
                          {
                            this.state.order_history.b2_bcompany ? (
                              <p><b>{this.state.order_history.b2_bcompany.company_name}</b></p>
                            ) : (
                              <p><b>{this.state.order_history.firstname} {this.state.order_history.name}</b></p>
                            )
                          }
                          {
                            this.state.order_history.bill_address && (
                              <p>{this.state.order_history.bill_address}</p>
                            )
                          }
                          <p>Tel : {this.state.order_history.phonenumber}</p>
                          <p>{this.state.order_history.email}</p>
                          {
                            this.state.order_history.b2_bcompany && this.state.order_history.b2_bcompany.tva_number && (
                              <p>N° TVA : {this.state.order_history.b2_bcompany.tva_number}</p>
                            )
                          }
                        </div>
                      ) : (
                        <div>
                          <p><b>{this.state.name}</b></p>
                          <p>{this.state.address}</p>
                          <p>Tel : {this.state.phonenumber}</p>
                          <p>{this.state.email}</p>
                          { this.state.tva_number && <p>N° TVA : {this.state.tva_number}</p>}
                        </div>
                      )
                    }
                  </header>
                  <h2>FACTURE N° {this.state.bill_number}</h2>
                  <b>Le { moment(this.state.created_at).format('DD/MM/y') }</b>
                  <table className="details">
                    <tr>
                      <th>Référence</th><th>Désignation</th><th>Quantité</th><th>PU Vente</th><th>TVA</th><th>Montant HT</th>
                    </tr>
                    {
                      !!this.state.order_history ? this.renderOrderBill(this.state.order_history) : this.renderCSV()
                    }
                  </table>
                  <footer>
                    {
                      !!this.state.order_history ? (
                        <>
                          <div>
                            { this.state.order_history && this.state.order_history.last_amount_paid === this.state.order_history.final_price + (Math.round(this.state.order_history.advance_of_cost/1.2*100)/100) ? (
                              <>
                              <p>Condition de paiement : { Math.round(this.state.order_history.last_amount_paid*1.2*100)/100 } € payé.</p>
                              <h1>PAYÉ</h1>
                              </>
                            ) : "  "}
                            { this.state.order_history && this.state.order_history.last_amount_paid < this.state.order_history.final_price + (Math.round(this.state.order_history.advance_of_cost/1.2*100)/100) && (
                              <p>Condition de paiement : { Math.round(this.state.order_history.last_amount_paid*1.2*100)/100 } € payé, {this.state.order_history.final_price - Math.round(this.state.order_history.last_amount_paid*1.2*100)/100 } € à payer.</p>
                            ) }
                            { this.state.order_history && this.state.order_history.last_amount_paid > this.state.order_history.final_price + (Math.round(this.state.order_history.advance_of_cost/1.2*100)/100) && (
                              <p>Condition de paiement : { Math.round(this.state.order_history.last_amount_paid*1.2*100)/100 } € payé, {(Math.round(this.state.order_history.advance_of_cost/1.2*100)/100) - this.state.order_history.final_price} € à rembourser.</p>
                            ) }
                            {
                              this.state.order_history && !this.state.order_history.last_amount_paid && (
                                <p>Condition de paiement : { Math.round(this.state.order_history.final_price*1.2*100)/100 + this.state.order_history.advance_of_cost } € à payer.</p>
                              )
                            }
                            <p>Le { moment(this.state.created_at).format('DD/MM/y') } (à réception).</p>
                          </div>
                          <div>
                            <table>
                              <tr><td><b>Total HT</b></td><td><b>{ Math.round((this.state.order_history.final_price + (this.state.order_history.advance_of_cost_tva ? Math.round(this.state.order_history.advance_of_cost/1.2*100)/100 : this.state.order_history.advance_of_cost))*100)/100 } €</b></td></tr>
                              <tr><td><b>TVA (20 %)</b></td><td><b>{ Math.round( ( (Math.round(this.state.order_history.final_price*1.2*100)/100 + this.state.order_history.advance_of_cost) - (this.state.order_history.final_price + (this.state.order_history.advance_of_cost_tva ? Math.round(this.state.order_history.advance_of_cost/1.2*100)/100 : this.state.order_history.advance_of_cost))) *100 )/100 } €</b></td></tr>
                              <tr><td><b>Total TTC</b></td><td><b>{ Math.round(this.state.order_history.final_price*1.2*100)/100 + this.state.order_history.advance_of_cost } €</b></td></tr>
                            </table>
                          </div>
                        </>
                      ) : (
                        <>
                          <div>
                            { 
                              this.state.paid ? (
                                <>
                                  Condition de paiement : { Number(this.state.amount_ttc).toFixed(2) } € payé.
                                  <h1>PAYÉ</h1>
                                </>
                              ) : (
                                <p>Condition de paiement : { Number(this.state.amount_ttc).toFixed(2) } € à payer.</p>
                              )
                            }
                            <p>Le { moment(this.state.created_at).format('DD/MM/y') } (à réception).</p>
                          </div>
                          <div>
                            <table>
                              <tr><td><b>Total HT</b></td><td><b>{ Number(this.state.amount_ht).toFixed(2) } €</b></td></tr>
                              <tr><td><b>TVA (20 %)</b></td><td><b>{ Number(this.state.amount_tva).toFixed(2) } €</b></td></tr>
                              <tr><td><b>Total TTC</b></td><td><b>{ Number(this.state.amount_ttc).toFixed(2) } €</b></td></tr>
                            </table>
                          </div>
                        </>
                      )
                    }
                  </footer>
                </div>
              </DetailsView>
            )
          }
          {
            this.state.credits && this.state.credits.map((credit, i) => (
              <DetailsView className="credits-detail-page" key={i}>
                <div className="bill">
                  <div className="buttons">
                    <SubmitButton label="Télécharger" onClick={() => this.onClickDownloadCredit(credit)} />
                  </div>
                  <h2>AVOIR N° {credit.credit_number}</h2>
                  <b>Le { moment(credit.created_at).format('DD/MM/y') }</b>
                  <table className="details">
                    <tr>
                      <th>Référence</th><th>Désignation</th><th>Quantité</th><th>PU Vente</th><th>TVA</th><th>Montant HT</th>
                    </tr>
                    {
                      this.renderLine("", <ul>
                        <li><b>Avoir sur facture N° {this.state.bill_number}</b></li>
                        { this.state.order_history ? (
                            <li>{ Math.round( (credit.amount / (Math.round((this.state.order_history.advance_of_cost + this.state.order_history.final_price*1.2)*100)/100)*100) *100)/100 } % du montant total TTC</li>
                          ) : (
                            <li>{ Math.round( (credit.amount / this.state.amount_ttc)*100 ) } % du montant total TTC</li>
                          )}
                      </ul>, 1, credit.amount_ht, credit.tva_percentage, credit.amount_ht)
                    }
                  </table>
                  <footer>
                    <div></div>
                    <div>
                      {
                        this.state.order_history ? (
                          <table>
                            <tr><td><b>Total HT</b></td><td><b>{ this.state.order_history.advance_of_cost_tva ? Math.round(credit.amount/1.2*100)/100 : Math.round( (((credit.amount-this.state.order_history.advance_of_cost)/1.2)+this.state.order_history.advance_of_cost) *100)/100 } €</b></td></tr>
                            <tr><td><b>TVA (20 %)</b></td><td><b>{ Math.round( (credit.amount - (this.state.order_history.advance_of_cost_tva ? Math.round(credit.amount/1.2*100)/100 : Math.round( (((credit.amount-this.state.order_history.advance_of_cost)/1.2)+this.state.order_history.advance_of_cost) *100)/100)  ) *100 ) /100 } €</b></td></tr>
                            <tr><td><b>Total TTC</b></td><td><b>{ credit.amount } €</b></td></tr>
                          </table>
                        ) : (
                          <table>
                            <tr><td><b>Total HT</b></td><td><b>{ Number(credit.amount_ht).toFixed(2) } €</b></td></tr>
                            <tr><td><b>TVA (20 %)</b></td><td><b>{ Number(credit.amount - credit.amount_ht).toFixed(2) } €</b></td></tr>
                            <tr><td><b>Total TTC</b></td><td><b>{ Number(credit.amount).toFixed(2) } €</b></td></tr>
                          </table>
                        )
                      }
                    </div>
                    { credit.cancelled ? <h1>Annulé</h1> : ""}
                  </footer>
                </div>
              </DetailsView>
            ))
          }
          {
            this.state.bill_number && (
              <DetailsView className="credits-detail-page">
                <div className="bill">
                  <div className="buttons">
                    <SubmitButton isCancel style={{ padding: "0 20px", width: "auto" }} label="Annuler" onClick={() => this.setState({ createCreditAmount: 0 })} />
                    <FormButton style={{ padding: "0 20px" }} selected label="Créer l'avoir" onClick={() => this.addCredit(this.state.createCreditAmount)} />
                  </div>
                  <h2>CRÉATION D'UN AVOIR</h2>
                  <b>Le { moment(new Date()).format('DD/MM/y') }</b>
                  <table className="details">
                    <tr>
                      <th>Référence</th><th>Désignation</th><th>Quantité</th><th>PU Vente</th><th>TVA</th><th>Montant HT</th>
                    </tr>
                    {
                      this.renderLine("", <ul>
                        <li><b>Avoir sur facture N° {this.state.bill_number}</b></li>
                        { this.state.order_history && !!this.state.createCreditAmount ? (
                            <li>{ Math.round( (this.state.createCreditAmount / (Math.round((this.state.order_history.advance_of_cost + this.state.order_history.final_price*1.2)*100)/100)*100) *100)/100 } % du montant total TTC</li>
                          ) : (
                            <li>{ Math.round( (this.state.createCreditAmount / this.state.amount_ht)*10000 ) /100 } % du montant total TTC</li>
                          )}
                        </ul>, 1, <Input style={{ marginTop: 0 }} type="number" priorityValue={this.state.createCreditAmount} getValue={createCreditAmount => this.setState({ createCreditAmount })}/>, 20, <Input type="number" style={{ marginTop: 0 }} priorityValue={this.state.createCreditAmount} getValue={createCreditAmount => this.setState({ createCreditAmount })}/>
                      )
                    }
                  </table>
                  <footer>
                    <div></div>
                    <div>
                      <table>
                        <tr><td><b>Total HT</b></td><td><b>{ this.state.createCreditAmount } €</b></td></tr>
                        <tr><td><b>TVA (20 %)</b></td><td><b>{ Math.round( (Math.round(this.state.createCreditAmount*1.2*100)/100 - this.state.createCreditAmount) *100)/100 } €</b></td></tr>
                        <tr><td><b>Total TTC</b></td><td><b>{ Math.round(this.state.createCreditAmount*1.2*100)/100 } €</b></td></tr>
                      </table>
                    </div>
                  </footer>
                </div>
              </DetailsView>
            )
          }
      </Template>
    );
  }
}

BillDetails.contextTypes = {
  t: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
  user: state.currentUser
})
const mapDispatchToProps = (dispatch) => ({
  push: (route) => dispatch(push(route))
})

export default connect(mapStateToProps, mapDispatchToProps)(BillDetails);
