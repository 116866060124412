import $ from 'jquery';

import env from '../../env';

class Company {
  constructor(company = {}){
    this.name = company.name;
    this.siret = company.siret;
    this.phonenumber = company.phonenumber;
    this.address = company.address;
    this.latitude = company.latitude;
    this.longitude = company.longitude;
    this.email = company.email;
    this.garage = company.garage === true || company.garage === 1 ? 1 : 0;
    this.bodyWork = company.bodyWork === true || company.bodyWork === 1 ? 1 : 0;
    this.mechanics = company.mechanics === true || company.mechanics === 1 ? 1 : 0;
    this.paint = company.paint === true || company.paint === 1 ? 1 : 0;
    this.rentalCar = company.rentalCar === true || company.rentalCar === 1 ? 1 : 0;
    this.iban = company.iban ? company.iban : undefined;
    this.defaultCommission = company.defaultCommission ? company.defaultCommission : 15;
    this.logo = company.logo ? company.logo : undefined
  }

  setToken(token){
    this.token = token;
    return this;
  }

  findAll(){
    return new Promise((resolve, reject) => {
      $.ajax( env.backendUrl + "api/v1/companies", {
        method: 'GET',
        crossDomain: true,
        headers: {
          "Authorization": "Bearer " + this.token
        },
        data: {}
      }).done(data => resolve(data)).catch(err => console.error("Une erreur est survenue : ", err));
    })
  }

  payment(id, comment = undefined){
    return new Promise((resolve, reject) => {
      $.ajax( env.backendUrl + "api/v1/companies/" + id + "/payment", {
        method: 'POST',
        crossDomain: true,
        headers: {
          "Authorization": "Bearer " + this.token
        },
        data: {
            comment: comment
        }
      }).done(data => resolve(data)).catch(err => console.error("Une erreur est survenue : ", err));
    })
  }

  getPayments(id){
      // console.log("Company getPayments : ", id)
      return new Promise((resolve, reject) => {
        $.ajax( env.backendUrl + "api/v1/companies/" + id + "/payment", {
          method: 'GET',
          crossDomain: true,
          headers: {
            "Authorization": "Bearer " + this.token
          },
        }).done(data => resolve(data)).catch(err => console.error("Une erreur est survenue : ", err));
      })
  }

  findOne(id){
    return new Promise((resolve, reject) => {
      $.ajax( env.backendUrl + "api/v1/companies/" + id, {
        method: 'GET',
        crossDomain: true,
        headers: {
          "Authorization": "Bearer " + this.token
        }
      }).done(async data => {
        let company = data;
        company.employes = await this.getEmployes(id);
        $.ajax( env.backendUrl + "api/v1/companies/" + id + "/orders", {
          method: 'GET',
          crossDomain: true,
          headers: {
            "Authorization": "Bearer " + this.token
          }
        }).done(async (orders) => {
          let companyWithLogo = {...company, orders };
          this.getLogo(id)
            .then(logo => resolve({ ...companyWithLogo, logo }))
            .catch(() => resolve(companyWithLogo));
        });
      }).catch(err => console.error("Une erreur est survenue : ", err));
    })
  }

  findByName(name){
    return new Promise((resolve, reject) => {
      $.ajax( env.backendUrl + "api/v1/companies?companyName="+name, {
        method: "GET",
        crossDomain: true,
        headers: {
          "Authorization": "Bearer " + this.token
        }
      }).done(data => resolve(data)).catch(err => console.error("Une erreur est survenue : ", err));
    })
  }

  getTowTrucks(id){
    return new Promise((resolve, reject) => {
      $.ajax( env.backendUrl + "api/v1/companies/" + id + "/towtrucks", {
        method: 'GET',
        crossDomain: true,
        headers: {
          "Authorization": "Bearer " + this.token
        }
      }).done(tow_trucks => resolve(tow_trucks));
    });
  }

  getEmployes(id){
    return new Promise((resolve, reject) => {
      $.ajax( env.backendUrl + "api/v1/companies/" + id + "/employes", {
        method: 'GET',
        crossDomain: true,
        headers: {
          "Authorization": "Bearer " + this.token
        }
      }).done(employes => resolve(employes));
    });
  }

  setIban(id, iban){
    return new Promise((resolve, reject) => {
      $.ajax( env.backendUrl + "api/v1/companies/" + id, {
        method: "POST",
        crossDomain: true,
        headers: {
          "Authorization": "Bearer " + this.token
        },
        data: {
          iban: iban
        }
      }).done(data => resolve(data)).catch(err => reject(err));
    })
  }

  setDefaultCommission(id, default_commission){
    return new Promise((resolve, reject) => {
      $.ajax( env.backendUrl + "api/v1/companies/" + id, {
        method: "POST",
        crossDomain: true,
        headers: {
          "Authorization": "Bearer " + this.token
        },
        data: {
          defaultCommission: default_commission
        }
      }).done(data => resolve(data)).catch(err => reject(err));
    })
  }

  getLogo(id){
    return new Promise((resolve, reject) => {
      fetch(env.backendUrl + "api/v1/companies/"+id+"/logo", {
        crossDomain: true,
        headers: {
          "Authorization": "Bearer " + this.token
        }
      }).then(data => {
        if (data.status !== 200) {
          reject()
        } else {
          return data.blob()
        }
      })
        .then((blob) => {
          if (blob.size === 0) reject()
          try {
            let img = new Image();
            img.src = URL.createObjectURL(blob);
            img.onload = (e) => {
              let canvas = document.createElement("canvas");
              canvas.setAttribute("width", img.width);
              canvas.setAttribute("height", img.height);
              console.log(canvas)
              let context = canvas.getContext("2d");
              context.drawImage(img, 0, 0);
              resolve(canvas.toDataURL());
            }
          } catch (e) {
            reject();
          }
        })
        .catch(err => console.log("Err : ", err))
    })
  }

  setLogo(id){
    return new Promise((resolve, reject) => {
      let formData = new FormData();
      formData.append("logo", this.logo);
      fetch( env.backendUrl + "api/v1/companies/"+id, {
        method: "POST",
        crossDomain: true,
        headers: {
          "Authorization": "Bearer " + this.token
        },
        body: formData
      })
        .then(data => data.json())
        .then(data => resolve(data))
        .catch(err => reject(err));
    })
  }

  create(){
    return new Promise((resolve, reject) => {
      let formData = new FormData();
      formData.append("name", this.name);
      formData.append("siret", this.siret);
      formData.append("phonenumber", this.phonenumber);
      formData.append("address", this.address);
      formData.append("latitude", this.latitude);
      formData.append("longitude", this.longitude);
      formData.append("email", this.email);
      formData.append("garage", this.garage ? 1 : 0);
      formData.append("bodyWork", this.bodyWork ? 1 : 0);
      formData.append("mechanics", this.mechanics ? 1 : 0);
      formData.append("paint", this.paint ? 1 : 0);
      formData.append("rentalCar", this.rentalCar ? 1 : 0);
      formData.append("iban", this.iban ? this.iban : undefined);
      formData.append("defaultCommission", this.defaultCommission);
      formData.append("logo", this.logo);
      fetch( env.backendUrl + "api/v1/companies", {
        method: "POST",
        crossDomain: true,
        headers: {
          "Authorization": "Bearer " + this.token
        },
        body: formData
        }).then(data => data.json()).then(data => resolve(data)).catch(err => reject(err));
      })
  }
}

export default Company;
