import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';

import Button from '../../components/Buttons/FormButton';

import './styles.scss';
import Label from '../../components/Label';
import Input from '../../components/Input';
import B2BCreateType from '../../redux/types/b2b_create_type';

import { distanceCalculation } from '../../constants';

class B2BDistance extends React.Component{

  constructor(props){
      super(props);
      this.state = {};
  }

  render(){
      return(
        <div>
          <h2>{this.context.t('question_b2b_company_distance_calculation')}</h2>
          <div>
            <Button
              onClick={() => this.props.updateB2B("fullDistanceCalculation", distanceCalculation.REDUCED_CALCULATION)}
              label={this.context.t('half_calculation')}
              selected={this.props.b2b.fullDistanceCalculation === distanceCalculation.REDUCED_CALCULATION}
            />
            <Button
              onClick={() => this.props.updateB2B("fullDistanceCalculation", distanceCalculation.FULL_CALCULATION)}
              label={this.context.t('full_calculation')}
              selected={this.props.b2b.fullDistanceCalculation === distanceCalculation.FULL_CALCULATION}
            />
          </div>
          <div className="one">
            <Button
              onClick={() => this.props.updateB2B("fullDistanceCalculation", distanceCalculation.DEPOSIT_CALCULATION)}
              label={this.context.t('deposit_calculation')}
              selected={this.props.b2b.fullDistanceCalculation === distanceCalculation.DEPOSIT_CALCULATION}
            />
          </div>
          { this.props.b2b.fullDistanceCalculation === distanceCalculation.REDUCED_CALCULATION && (
              <Label value={this.context.t("half_calculation_explanation")} />
          )}
          { this.props.b2b.fullDistanceCalculation === distanceCalculation.FULL_CALCULATION && (
              <Label value={this.context.t("full_calculation_explanation")} />
          )}
          { this.props.b2b.fullDistanceCalculation === distanceCalculation.DEPOSIT_CALCULATION && (
              <Label value={this.context.t("deposit_calculation_explanation")} />
          )}
          <Input 
            placeholder={this.context.t('kilometers_included')}
            getValue={(value) => this.props.updateB2B("kmIncluded", value)}
            priorityValue={this.props.b2b.kmIncluded}
            type="number"
          />
          <Label value={this.context.t("kilometers_included_explanation")} />
        </div>
      );
    }
}

B2BDistance.contextTypes = {
  t: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
    full_distance: state.b2bCreate.full_distance,
    km_included: _.get(state.b2bCreate, 'km_included', "")
})

const mapDispatchToProps = (dispatch) => ({
  toogleDistanceMode: (mode) => dispatch({ type: B2BCreateType.B2B_CREATE_SET_DISTANCE_MODE_FULL, payload: mode }),
  setKMIncluded: (km) => dispatch({ type: B2BCreateType.B2B_CREATE_SET_KM_INCLUDED, payload: km })
})

export default connect(mapStateToProps, mapDispatchToProps)(B2BDistance);
