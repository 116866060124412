import React from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import PropTypes from 'prop-types';
import MaterialIcon from 'material-icons-react';
import _ from 'lodash';
import './styles.scss';

import Company from '../../core/Company';
import CompanyType from '../../redux/types/company';
import OrderCore from '../../core/Order';

import Template from '../../templates/Backoffice';
import HeaderButtons from '../../components/HeaderButtons';
import Label from '../../components/Label';
import SearchBar from '../../components/SearchBar';
import B2BCompany from '../../core/B2BCompany';
import Bill from '../../core/Bill';
import moment from 'moment';
import DetailsView from '../../templates/Details';
import { paymentMethod, vehicleType } from '../../constants';
import FormButton from '../../components/Buttons/FormButton';
import SubmitButton from '../../components/Buttons/SubmitButton';
import Input from '../../components/Input';

class BillCreate extends React.Component{

  constructor(props){
      super(props);
      this.state = {
          lines: [";;;;;", ";;;;;", ";;;;;", ";;;;;", ";;;;;", ";;;;;", ";;;;;", ";;;;;", ";;;;;", ";;;;;", ";;;;;", ";;;;;", ";;;;;", ";;;;;", ";;;;;", ";;;;;", ";;;;;", ";;;;;", ";;;;;" ]
      }
  }

  getTotalHt = () => {
    let total = 0;
    for (let line of this.state.lines) {
      let value = line.split(';')[5]
      if (Number(value) > 0) {
        total += Number(value);
      } 
    }
    return Number(total).toFixed(2);
  }

  getTotalTTC = () => {
    let total = 0;
    for (let line of this.state.lines) {
      let value = Number(line.split(';')[5])
      let tva = 1 + (Number(line.split(";")[4])/100);

      if (Number(value) > 0) {
        total += (value*tva);
      } 
    }
    return Number(total).toFixed(2);
  }

  getCSV = () => {
    let csv = "";
    for (let line of this.state.lines) {
      line = line.split(";");
      if (line[1].length > 0 && line[2].length > 0 && line[3].length > 0 && line[4].length > 0 && line[5].length > 0) {
        csv += line.join(";");
        csv += "\n";
      }
    }
    return csv;
  }

  handleCreate = () => {
    new Bill().setToken(this.props.user.token).create({
      name: this.state.name,
      address: this.state.address,
      phonenumber: this.state.phonenumber,
      email : this.state.email,
      tvaNumber: this.state.tvaNumber,
      paid: this.state.paid ? 1 : 0,
      csvData: this.getCSV()
    }).then(bill => this.props.push("/bills"))
  }

  render(){
    return(
      <Template>
          <DetailsView className="bills-create-page">
                <div className="bill">
                  <img alt="logo" src="/logo.svg" />
                  <header>
                    <div>
                      <p><b>DÉPANN'MOI</b></p>
                      <p>14, Avenue de l'Opéra</p>
                      <p>75001 PARIS 1</p>
                      <p>Tel : +33 805 382 262</p>
                      <p>alexandre@depannmoi.com</p>
                      <p>www.depannmoi.com</p>
                      <p>N° TVA Intracommunautaire : FR51822624615</p>
                      <p>N° SIRET : 82262461500016</p>
                      <p>RCS : BOBIGNY</p>
                      <p>Capital : 5 000 €</p>
                    </div>
                    <div>
                      <Input placeholder="Nom sur la facture" getValue={name => this.setState({ name })}/>
                      <Input placeholder="Adresse de facturation" getValue={address => this.setState({ address })} />
                      <Input placeholder="Téléphone" getValue={phonenumber => this.setState({ phonenumber })} />
                      <Input placeholder="Email" getValue={email => this.setState({ email })} />
                      <Input placeholder="Numéro de TVA" getValue={tvaNumber => this.setState({ tvaNumber })} />
                    </div>
                  </header>
                  <h2>FACTURE N° {moment(new Date()).format('YY-MM') + "-Dxxxx"}</h2>
                  <b>Le { moment(new Date()).format('DD/MM/y') }</b>
                  <table className="details">
                    <tr>
                      <th>Référence</th><th>Désignation</th><th>Quantité</th><th>PU Vente</th><th>TVA</th><th>Montant HT</th>
                    </tr>
                    {
                      this.state.lines.map((line, i) => (
                        <tr>
                          <td><Input getValue={(value => {
                              let tab = this.state.lines;
                              let tmp = line.split(";");
                              tmp[0] = value;
                              if (!tmp[4]) tmp[4] = 20;
                              tab[i] = tmp.join(";")
                              this.setState({ lines: tab })
                            })} priorityValue={line.split(";")[0]} /></td>
                          <td><Input getValue={(value => {
                              let tab = this.state.lines;
                              let tmp = line.split(";");
                              tmp[1] = value;
                              if (!tmp[4]) tmp[4] = 20;
                              tab[i] = tmp.join(";")
                              this.setState({ lines: tab })
                            })} priorityValue={line.split(";")[1]} /></td>
                          <td><Input type="number" getValue={(value => {
                              let tab = this.state.lines;
                              let tmp = line.split(";");
                              tmp[2] = value >= 0 ? Math.round(value) : 0;
                              if (!tmp[4]) tmp[4] = 20;

                              if (Number(tmp[3]) > 0 && value > 0) {
                                tmp[5] = Math.round(value*Number(tmp[3])*100)/100
                              } else if (Number(tmp[5]) > 0 && value > 0) {
                                tmp[3] = Math.round(Number(tmp[5])/value*100)/100
                              } else if (value === 0) {
                                tmp[3] = 0;
                                tmp[5] = 0;
                              }

                              tab[i] = tmp.join(";")
                              this.setState({ lines: tab })
                            })} priorityValue={line.split(";")[2]} /></td>
                          <td><Input type="number" getValue={(value => {
                              let tab = this.state.lines;
                              let tmp = line.split(";");
                              tmp[3] = value >= 0 ? Math.round(value*100)/100 : 0;
                              if (!tmp[4]) tmp[4] = 20;

                              if (Number(tmp[2]) > 0 && value > 0) {
                                tmp[5] = Math.round(value*Number(tmp[2])*100)/100
                              }

                              tab[i] = tmp.join(";")
                              this.setState({ lines: tab })
                            })} priorityValue={line.split(";")[3]} /></td>
                          <td><Input type="number" getValue={(value => {
                              let tab = this.state.lines;
                              let tmp = line.split(";");
                              tmp[4] = value >= 0 ? Math.round(value*100)/100 : 0;
                              tab[i] = tmp.join(";")
                              this.setState({ lines: tab })
                            })} priorityValue={line.split(";")[4]} /></td>
                          <td><Input type="number" getValue={(value => {
                              let tab = this.state.lines;
                              let tmp = line.split(";");
                              tmp[5] = value >= 0 ? Math.round(value*100)/100 : 0;
                              if (!tmp[4]) tmp[4] = 20;

                              if (Number(tmp[2]) > 0 && value > 0) {
                                tmp[3] = Math.round(value/Number(tmp[2])*100)/100
                              }

                              tab[i] = tmp.join(";")
                              this.setState({ lines: tab })
                            })} priorityValue={line.split(";")[5]} /></td>
                        </tr>
                      ))
                    }
                  </table>
                  <footer>
                    <div>
                      <p>Condition de paiement : { this.getTotalTTC() } € {!this.state.paid ? "à payer." : "payé."} </p>
                      {/* <p>Le { moment(new Date()).format('DD/MM/y') } (à réception).</p> */}
                      <div style={{ display: "flex", marginTop: 10 }}>
                        <FormButton style={{ marginRight: 5 }} label="À payer" onClick={() => this.setState({ paid: false })} selected={!this.state.paid} />
                        <FormButton style={{ marginLeft: 5 }} label="Payé" onClick={() => this.setState({ paid: true })} selected={this.state.paid} />
                      </div>
                    </div>
                    <div>
                      <table>
                        <tr><td><b>Total HT</b></td><td><b>{ this.getTotalHt() } €</b></td></tr>
                        <tr><td><b>TVA (20 %)</b></td><td><b>{ Number(this.getTotalTTC() - this.getTotalHt()).toFixed(2) } €</b></td></tr>
                        <tr><td><b>Total TTC</b></td><td><b>{ this.getTotalTTC() } €</b></td></tr>
                      </table>
                    </div>
                  </footer>
                  <div style={{ padding: "10px 0", marginTop: 10 , borderTop: "1px solid grey" }}>
                    <SubmitButton label="Créer la facture" onClick={this.handleCreate} />
                  </div>
                </div>
          </DetailsView>
      </Template>
    );
  }
}

BillCreate.contextTypes = {
  t: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
  user: state.currentUser
})
const mapDispatchToProps = (dispatch) => ({
  push: (route) => dispatch(push(route))
})

export default connect(mapStateToProps, mapDispatchToProps)(BillCreate);
