import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import MaterialIcon from 'material-icons-react';
import moment from 'moment'

import './styles.scss';
import Company from '../../core/Company';

import Label from '../../components/Label';
import Input from '../../components/Input';
import Accordion from '../../components/Accordion';

import Template from '../../templates/Backoffice';
import DetailsView from '../../templates/Details';

class CompanyDetails extends React.Component{

  constructor(props){
    super(props);
    this.state = {
      id: undefined
    }
  }

  componentDidMount(){
    if(_.get(this.props, 'user.token', undefined) !== undefined){
      this.loadCompanyData();
    }
  }

  loadCompanyData = () => {
    new Company({}).setToken(this.props.user.token).findOne(this.props.params.id).then(company => {
      this.setState({
        ...company,
        tmp_default_commission: company.default_commission.toString(10),
        tmp_default_iban: company.iban
      });
    }).catch(err => console.log(err));
  }

  onClickPayment = () => {
    if(this.state.left_to_pay !== 0)
      new Company({}).setToken(this.props.user.token).payment(this.props.params.id, this.state.comment).then(company => {
        this.setState(company);
      });
  }

  onClickDefaultCommission = () => {
    new Company({}).setToken(this.props.user.token).setDefaultCommission(this.state.id, this.state.tmp_default_commission).then(company => {
      this.setState({
        ...company,
        tmp_default_commission: company.default_commission,
        edit_default_commission: false
      });
    })
  }
  onClickSetIban = () => {
    if (this.state.tmp_default_iban) {
      new Company().setToken(this.props.user.token).setIban(this.state.id, this.state.tmp_default_iban).then(company => {
        this.setState({
          ...company,
          tmp_default_iban: company.iban,
          edit_iban: false
        });
      });
    }
  }

  _handleChangeLogo = (logo) => {
    if (logo) {
      this.setState({ id: undefined }, () => {
        new Company({ logo })
        .setToken(this.props.user.token)
        .setLogo(this.props.params.id)
        .then(data => {
          this.loadCompanyData()
        })
      });
    }
  }

  render(){
    if(this.state.id === undefined || this.state.id === null || this.state.id === -1){
      return(
        <Template>
          <DetailsView isLoading />
        </Template>
      );
    }

    let globalCA = 0; let globalCount = 0;
    let annualCA = 0; let annualCount = 0;
    let monthlyCA = 0; let monthlyCount = 0;

    let activity = "";
    activity += this.state.body_work ? this.context.t('company_bodyWork') + " - " : "";
    activity += this.state.mechanics ? this.context.t('company_mechanics') + " - " : "";
    activity += this.state.paint ? this.context.t('company_paint') + " - " : "";
    activity += this.state.rental_car ? this.context.t('company_rentalCar') : "";

    let orders = this.state.orders ? this.state.orders : []
    orders.forEach(elem => {
      if (!elem.cancelled) {
        globalCA += Math.round(elem.final_price*1.2*100)/100;
        globalCount++;
        if(new Date(elem.wished_intervention_date).getMonth() === new Date().getMonth()){
          monthlyCA += Math.round(elem.final_price*1.2*100)/100;
          monthlyCount++;
        }
        if(new Date(elem.wished_intervention_date).getYear() === new Date().getYear()){
          annualCA += Math.round(elem.final_price*1.2*100)/100;
          annualCount++;
        }
      }
    })
    console.log("Company", this.state);
    return(
        <Template>
          <DetailsView className="company-details">

            { this.state.logo && <div className="logo" style={{ backgroundImage: "url("+this.state.logo+")"}}></div> }
            { !this.state.logo && <h2>{this.state.name}</h2> }

            <Label value={this.context.t('company_basic_infos')} />
            <ul>
              <li>
                <span><MaterialIcon icon="room" size={"tiny"} color="#1c6bb1" /></span>{this.state.address}
              </li>
              <li>
                <span><MaterialIcon icon="email" size={"tiny"} color="#1c6bb1" /></span>{this.state.email}
              </li>
              <li>
                <span><MaterialIcon icon="call" size={"tiny"} color="#1c6bb1" /></span>{this.state.phonenumber}
              </li>
              <li>
                <span><MaterialIcon icon="book" size={"tiny"} color="#1c6bb1" /></span>{this.state.siret}
              </li>
              <li>
                <span><MaterialIcon icon="camera_alt" size={"tiny"} color="#1c6bb1" /></span>
                Logo : <Input type="file" placeholder="logo" getValue={this._handleChangeLogo} />
              </li>
            </ul>
            <Label value={this.context.t('company_business_infos')} />
            <ul>
              <li>
                <span><MaterialIcon icon="account_balance" size={"tiny"} color="#1c6bb1" /></span>
                {
                  (this.state.edit_iban || !this.state.iban) && (
                    <div><Input placeholder={this.context.t('iban')} getValue={(value) => this.setState({tmp_default_iban: value})}/><span onClick={() => {
                      if (this.state.tmp_default_iban) this.onClickSetIban();
                    }}><MaterialIcon icon="check" size={"tiny"} color="#1c6bb1" /></span></div>
                  )}

                {!this.state.edit_iban && this.state.iban ? <div>{this.state.iban}<span onClick={() => this.setState({edit_iban: true})}><MaterialIcon icon="create" size={"tiny"} color="#1c6bb1" /></span></div> : ""}
              </li>
              <li>
                <span><MaterialIcon icon="share" size={"tiny"} color="#1c6bb1" /></span>
                {!this.state.edit_default_commission ? <div>{this.state.default_commission + "% " + this.context.t('of_commission')}<span onClick={() => this.setState({edit_default_commission: true})}><MaterialIcon icon="create" size={"tiny"} color="#1c6bb1" /></span></div> : "" }
                {this.state.edit_default_commission ? <div><Input placeholder={this.context.t('default_commission')} getValue={(value) => this.setState({tmp_default_commission: value})} defaultValue={this.state.tmp_default_commission}/><span onClick={() => this.onClickDefaultCommission()}><MaterialIcon icon="check" size={"tiny"} color="#1c6bb1" /></span></div> : ""}
              </li>
              <li>
                <span><MaterialIcon icon="show_chart" size={"tiny"} color="#1c6bb1" /></span>
                <ul>
                  <li><span>{this.context.t('global_CA') + " " + this.state.turnover.global + " € TTC"}</span><span>{" - " + globalCount + " " + this.context.t('intervention' + (globalCount > 1 ? "s" : ""))}</span></li>
                  <li><span>{this.context.t('annual_CA') + " " + this.state.turnover.annual + " € TTC"}</span><span>{" - " + annualCount + " " + this.context.t('intervention' + (annualCount > 1 ? "s" : ""))}</span></li>
                  <li><span>{this.context.t('monthly_CA') + " " + this.state.turnover.monthly + " € TTC"}</span><span>{" - " + monthlyCount + " " + this.context.t('intervention' + (monthlyCount > 1 ? "s" : ""))}</span></li>
                </ul>
              </li>
              <li>
                <span><MaterialIcon icon="euro_symbol" size={"tiny"} color="#1c6bb1" /></span>
                <div>
                  {
                    this.state.left_to_pay > 0 && Math.round(this.state.left_to_pay * 100) / 100 + "€ " + this.context.t('to_pay_the_company')
                  }
                  {
                    this.state.left_to_pay < 0 && this.context.t("company_needs_to_pay") + " " + Math.round(-1 * this.state.left_to_pay * 100) / 100 + "€"
                  }
                  {
                    this.state.left_to_pay === 0 && this.context.t('nothing_to_pay_the_company')
                  }
                  {
                    this.state.left_to_pay !== 0 && <span onClick={() => this.onClickPayment()}><MaterialIcon icon="done" size={"tiny"} color="#1c6bb1" /></span>
                  }
                </div>
              </li>
              {
                this.state.left_to_pay !== 0 && <li><Input placeholder="Commentaire" getValue={(value) => this.setState({ comment: value })} /></li>
              }
              <li className="color-grey">
                {this.context.t('left_to_pay_explaination')}
              </li>
              <Accordion title={this.context.t('payments_history')} onOpen={() => {
                  new Company().setToken(this.props.user.token).getPayments(this.props.params.id).then((payments) => {
                      this.setState({payments})
                  })
              }}>
                {
                    this.state.payments && this.state.payments.length > 0 && this.state.payments.map((elem) => {
                    return (
                      <div className="row" key={elem.id}>
                        <div>
                          <b>{elem.amount} €</b><i>{" " + (elem.comment ? elem.comment : this.context.t("no_comment"))}</i>
                        </div>
                        <div>
                            <span>{moment(elem.created_at).format('DD/MM/yyyy')}</span>
                        </div>
                      </div>
                    )
                  })
                }
                {
                    !this.state.payments && (
                        <div className="row">{this.context.t('loading')}</div>
                    )
                }
                {
                    this.state.payments && this.state.payments.length <= 0 && (
                        <div className="row">{this.context.t('payment_history_empty')}</div>
                    )
                }
              </Accordion>
            </ul>
            <Label value={this.context.t('company_activity_infos')} />
            <ul>
              <li>
                <span><MaterialIcon icon="build" size={"tiny"} color="#1c6bb1" /></span>{this.context.t(this.state.garage ? "company_is_garage" : "company_is_not_garage")}
              </li>
              {
                activity !== "" && activity !== null && activity !== undefined ? <li><span><MaterialIcon icon="shopping_cart" size={"tiny"} color="#1c6bb1" /></span>{activity}</li> : ""
              }
            </ul>
          </DetailsView>
        </Template>
    );
  }
}

CompanyDetails.contextTypes = {
  t: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
  user: state.currentUser
})
const mapDispatchToProps = (dispatch) => ({
  push: (route) => dispatch(push(route))
})

export default connect(mapStateToProps, mapDispatchToProps)(CompanyDetails);
