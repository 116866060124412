import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Button from '../../components/Buttons/FormButton';

import './styles.scss';
import OrderType from '../../redux/types/order';
import Input from '../../components/Input';

class IsB2B extends React.Component{
  render(){
      return(
        <div>
          <h2>{this.context.t('question_about_order')}</h2>
          <Button
            onClick={() => this.props.toogleB2B() }
            label={this.props.is_b2b ? this.context.t('customer_b2b') : this.context.t('customer_not_b2b')}
            selected={this.props.is_b2b}
          />
          { this.props.is_b2b && (
            <Input
              placeholder={this.context.t('order_ref')}
              getValue={this.props.setRef}
            />
          )}
        </div>
      );
    }
}

IsB2B.contextTypes = {
  t: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
  is_b2b: state.order.is_b2b
})

const mapDispatchToProps = (dispatch) => ({
  toogleB2B: () => dispatch({
      type: OrderType.ORDER_TOOGLE_B2B
  }),
  setRef: (ref) => dispatch({
    type: OrderType.ORDER_SET_REF_NUMBER, payload: ref
  })
})

export default connect(mapStateToProps, mapDispatchToProps)(IsB2B);
