import React from 'react';

import './styles.scss';

class Input extends React.Component{

  constructor(props){
    super(props);
    this.state = {
      value: this.props.defaultValue ? this.props.defaultValue : ""
    }
  }

  componentDidMount(){
    this.props.getValue(this.state.value);
  }

  componentDidUpdate(prevProps) {
    if(this.props.priorityValue !== prevProps.priorityValue){
      this.setState({value: this.props.priorityValue});
    }
  }

  handleOnChange = (value) => {
    this.setState({
      value: value
    }, () => this.props.getValue(value))
  }

  render(){
    let value = "";

    if(this.state.priorityValue !== undefined && this.state.priorityValue !== null && this.state.priorityValue !== ""){
      value = this.state.priorityValue;
    }
    else{
      value = this.props.defaultValue && this.state.value === ""  ? this.props.defaultValue : this.state.value
    }

    return(
      <div className={this.props.error ? "basic-textarea error" : "basic-textarea"}>
        <textarea
          type={this.props.type === "password" ? "password" : "text"}
          name={this.props.name}
          value={value}
          placeholder={this.props.placeholder}
          onChange={(event) => this.handleOnChange(event.target.value)}
          onBlur={() => this.props.onBlur ? this.props.onBlur() : {}}
          onFocus={() => this.props.onFocus ? this.props.onFocus() : {}}
        />
      </div>
    );
  }
}

export default Input;
