import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import MaterialIcon from 'material-icons-react';

import Vehicle from '../../core/Vehicle';
import { vehicleType } from '../../constants';

import VehicleToUpdateType from '../../redux/types/vehicles_to_update';

import './styles.scss';
import Accordion from '../../components/Accordion';
import Label from '../../components/Label';
import Template from '../../templates/Backoffice';

class SettingsPage extends React.Component{

  constructor(props){
    super(props);
    this.state = {
      vehicles: []
    }
  }

  _updateVehicleToUpdateList = (vehicles => {
    this.props.updateVehicleToUpdateList(vehicles);
  });

  _handleConfirm = (id) => {
    new Vehicle().setToken(this.props.user.token).updateAVehicle(id, true).then(vehicles => this._updateVehicleToUpdateList(vehicles));
  }

  _handleReject = (id) => {
    new Vehicle().setToken(this.props.user.token).updateAVehicle(id, false).then(vehicles => this._updateVehicleToUpdateList(vehicles));
  }

  render(){
    return(
      <Template>
        <div className="settings-page">
          <Label value={this.context.t("vehicle_update_description")} />
          <Accordion title={this.context.t("vehicle_update_title")} badgeNumber={this.props.vehicles.length}>
            {
              this.props.vehicles.length > 0 ? this.props.vehicles.map(elem => {
                let vehicleTypeTrans = (elem.vehicle_type === vehicleType.LIGHT_VEHICLE ? "light_vehicle" : "");
                vehicleTypeTrans = (elem.vehicle_type === vehicleType.VAN_VEHICLE ? "van_vehicle" : vehicleTypeTrans);
                vehicleTypeTrans = (elem.vehicle_type === vehicleType.COMMERCIAL ? "commercial_vehicle" : vehicleTypeTrans);
                vehicleTypeTrans = (elem.vehicle_type === vehicleType.LIGHT_PL ? "light_pl" : vehicleTypeTrans);
                vehicleTypeTrans = (elem.vehicle_type === vehicleType.MEDIUM_PL ? "medium_pl" : vehicleTypeTrans);
                vehicleTypeTrans = (elem.vehicle_type === vehicleType.HEAVY_PL ? "heavy_pl" : vehicleTypeTrans);
                if (elem.vehicle_type === undefined) {
                  return (
                    <div className="row" key={elem.id}>
                      <div>
                        {"Ajouter " + elem.brand + " " + elem.model + " ?"}
                      </div>
                      <div>
                        <span onClick={() => this._handleReject(elem.id)}><MaterialIcon icon="clear" size={"tiny"} color="rgb(192, 57, 43)" /></span>
                        <span onClick={() => this._handleConfirm(elem.id)}><MaterialIcon icon="check" size={"tiny"} color="rgb(39, 174, 96)" /></span>
                      </div>
                    </div>
                  );
                } else {
                  return (
                    <div className="row" key={elem.id}>
                      <div>
                        {"Le " + elem.brand + " " + elem.model + " est un "}<strong>{this.context.t(vehicleTypeTrans)}</strong>{" ?"}
                      </div>
                      <div>
                        <span onClick={() => this._handleReject(elem.id)}><MaterialIcon icon="clear" size={"tiny"} color="rgb(192, 57, 43)" /></span>
                        <span onClick={() => this._handleConfirm(elem.id)}><MaterialIcon icon="check" size={"tiny"} color="rgb(39, 174, 96)" /></span>
                      </div>
                    </div>
                  );
                }
              }) : <div className="row">{this.context.t('no_vehicle_to_update')}</div>
            }
          </Accordion>
        </div>
      </Template>
    );
  }
}

SettingsPage.contextTypes = {
  t: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
  user: state.currentUser,
  vehicles: state.vehicleToUpdate
});
const mapDispatchToProps = (dispatch) => ({
  push: (route) => dispatch(push(route)),
  updateVehicleToUpdateList: (vehicles) => dispatch({
    type: VehicleToUpdateType.SET_VEHICLES_TO_UPDATE_LIST,
    payload: vehicles
  })
})

export default connect(mapStateToProps, mapDispatchToProps)(SettingsPage);
