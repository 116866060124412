import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

import Button from '../../components/Buttons/FormButton';

import './styles.scss';
import OrderType from '../../redux/types/order';

class Rolling extends React.Component{
  render(){
      return(
        <div>
          <h2>{this.context.t('question_rolling')}</h2>
          <div>
            <Button
              onClick={() => this.props.updateOrder(true)}
              label={this.context.t('yes')}
              selected={_.get(this.props, 'order.rolling') === true ? true : false}
            />
            <Button
              onClick={() => this.props.updateOrder(false)}
              label={this.context.t('no')}
              selected={_.get(this.props, 'order.rolling') === false ? true : false}
            />
          </div>
        </div>
      );
    }
}

Rolling.contextTypes = {
  t: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
  order: state.order,
  user: state.currentUser
})

const mapDispatchToProps = (dispatch) => ({
  updateOrder: (choice) => dispatch({
    type: OrderType.ORDER_SELECT_ROLLING,
    payload: {rolling: choice}
  }),
  push: (route) => dispatch(push(route))
})

export default connect(mapStateToProps, mapDispatchToProps)(Rolling);
