import React from 'react';

import './styles.scss';

class LoginButton extends React.Component{

  handleOnClickSubmit = (event) => {
    event.preventDefault();
    this.props.onClickSubmit();
  }

  render(){
    return(
      <div className="common-loginbutton" onClick={(event) => this.handleOnClickSubmit(event)}>
        <button>{this.props.label}</button>
      </div>
    )
  }
}

export default LoginButton;
