import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';

import Input from '../../components/Input';

import Vehicle from '../../core/Vehicle';

import VehicleBrandAutocomplete from '../../components/Autocomplete/vehiclebrand';
import VehicleModelAutocomplete from '../../components/Autocomplete/vehiclemodels';

import './styles.scss';
import OrderType from '../../redux/types/order';

class VehicleInfos extends React.Component{

  constructor(props){
    super(props);
    this.state = {};
  }

  _handleOnChange = () => {
    if (this.state.brand && this.props.model) {
      new Vehicle().setToken(this.props.token).findOneByBrandAndModel(this.state.brand, this.state.model).then(data => {
        if (data.vehicle_type !== undefined){
          this.props.setVehicleType(data.vehicle_type);
        } else {
          this.props.setVehicleType(undefined);
        }
      })
    }
  }

  _registrationOnBlur = (e) => {
      if (this.state.error_registration && /[0-9A-Z-]{6,14}/.test(this.props.registration)) {
          this.setState({ error_registration: false })
      } else {
          if (!/[0-9A-Z-]{6,14}/.test(this.props.registration)) {
              this.setState({ error_registration: true })
          } else {
              this.setState({ error_registration: false })
          }
      }
  }

  _modelOnBlur = (e) => {
      if (this.state.error_model && /[0-9A-Z-]{2,}/.test(this.props.model)) {
          this.setState({ error_model: false })
      } else {
          if (!/[0-9A-Z-]{2,}/.test(this.props.model)) {
              this.setState({ error_model: true })
          } else {
              this.setState({ error_model: false })
          }
      }
  }

  _brandOnBlur = (e) => {
      if (this.state.error_brand && /[0-9A-Z-]{2,}/.test(this.props.brand)) {
          this.setState({ error_brand: false })
      } else {
          if (!/[0-9A-Z-]{2,}/.test(this.props.brand)) {
              this.setState({ error_brand: true })
          } else {
              this.setState({ error_brand: false })
          }
      }
  }

  render(){
      return(
        <div>
          <h2>{this.context.t('question_vehicle_info')}</h2>
          {
              this.state.error_brand && <span className="error-message">{this.context.t("error_brand")}</span>
          }
          <VehicleBrandAutocomplete
            token={this.props.token}
            getValue={value => this.setState({brand: value}, () => {this.props.setVehicleBrand(value); this._handleOnChange()})}
            priorityValue={this.props.brand}
            placeholder={this.context.t('vehicle_brand')}
            onBlur={this._brandOnBlur}
            error={this.state.error_brand}
          />
          {
              this.state.error_model && <span className="error-message">{this.context.t("error_model")}</span>
          }
          <VehicleModelAutocomplete
            token={this.props.token}
            getValue={value => this.setState({model: value}, () => {this.props.setVehicleModel(value); this._handleOnChange()})}
            priorityValue={this.props.model}
            placeholder={this.context.t('vehicle_model')}
            brand={this.props.brand === "" ? undefined : this.props.brand}
            onBlur={this._modelOnBlur}
            error={this.state.error_model}
          />
          {
              this.state.error_registration && <span className="error-message">{this.context.t("error_registration")}</span>
          }
          <Input
            getValue={value => this.setState({registration: value}, () => this.props.setVehicleRegistration(value.toUpperCase()))}
            placeholder={this.context.t('vehicle_registration')}
            priorityValue={this.props.registration}
            onBlur={this._registrationOnBlur}
            error={this.state.error_registration}
          />
        </div>
      );
    }
}

VehicleInfos.contextTypes = {
  t: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
  token: state.currentUser.token,
  brand: _.get(state, "order.vehicle_infos.brand", ""),
  model: _.get(state, "order.vehicle_infos.model", ""),
  registration: _.get(state, "order.vehicle_infos.registration", "")
})

const mapDispatchToProps = (dispatch) => ({
  setVehicleBrand: (brand) => dispatch({
    type: OrderType.ORDER_SELECT_VEHICLE_BRAND,
    payload: {
      brand: brand
    }
  }),
  setVehicleModel: (model) => dispatch({
    type: OrderType.ORDER_SELECT_VEHICLE_MODEL,
    payload: {
      model: model
    }
  }),
  setVehicleRegistration: (registration) => dispatch({
    type: OrderType.ORDER_SELECT_VEHICLE_REGISTRATION,
    payload: {
      registration: registration
    }
  }),
  setVehicleType: (type) => dispatch(
    {
      type: OrderType.ORDER_SELECT_VEHICLE_TYPE,
      payload: {vehicle_type: type}
    }
  )
})

export default connect(mapStateToProps, mapDispatchToProps)(VehicleInfos);
