import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import {translations} from "./translations"
import * as serviceWorker from './serviceWorker';
import { createStore, combineReducers, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import { Router, Route, browserHistory } from 'react-router';
import { syncHistoryWithStore, routerReducer, routerMiddleware } from 'react-router-redux';
import I18n from "redux-i18n";
import {i18nState} from "redux-i18n";

import navbarReducer from './redux/reducers/navbar-router';
import order from './redux/reducers/order';
import currentUser from './redux/reducers/currentuser';
import orderList from './redux/reducers/orderlist';
import company from './redux/reducers/company';
import users from './redux/reducers/users';
import towtruck from './redux/reducers/towtruck';
import vehicleToUpdate from './redux/reducers/vehicles_to_update';
import b2bCreateReducer from './redux/reducers/b2b-create';
import messages from './redux/reducers/conversations';

import Home from './pages/Home';
import Order from './pages/Order';
import OrderCreate from './pages/OrderCreate';
import OrderDetails from './pages/OrderDetails';
import Login from './pages/Login';
import Companies from './pages/Companies';
import CompaniesCreate from './pages/CompaniesCreate';
import CompanyDetails from './pages/CompanyDetails';
import Users from './pages/Users';
import UsersCreate from './pages/UsersCreate';
import UsersDetails from './pages/UsersDetails';
import UsersUpdate from './pages/UsersUpdate';
import Vehicle from './pages/Vehicle';
import VehicleCreate from './pages/VehicleCreate';
import VehicleDetails from './pages/VehicleDetails';
import SettingsPage from './pages/Settings';
import B2B from './pages/B2B';
import B2BCreate from './pages/B2BCreate';

import Mercure from './templates/Mercure';
import B2BCompanyDetails from './pages/B2BCompanyDetails';
import Bills from './pages/Bills';
import BillDetails from './pages/BillDetails';
import BillCreate from './pages/BillCreate';
import Conversations from './pages/Conversations';

const middleware = routerMiddleware(browserHistory)

const store = createStore(
  combineReducers({
    navbarReducer,
    currentUser,
    order,
    orderList,
    company,
    i18nState,
    users,
    towtruck,
    vehicleToUpdate,
    b2bCreate: b2bCreateReducer,
    messages,
    routing: routerReducer
  }),
  applyMiddleware(middleware)
);
const history = syncHistoryWithStore(browserHistory, store);

if(window.innerWidth > 500){
  window.addEventListener('load', function () {
    Notification.requestPermission(function (status) {
      // Cela permet d'utiliser Notification.permission avec Chrome/Safari
      if (Notification.permission !== status) {
        Notification.permission = status;
      }
    });
  });
}

ReactDOM.render(
  <Provider store={store}>
    <I18n translations={translations} initialLang="fr">
      <Mercure>
      <Router history={history}>
        <Route path="/login" component={Login} />
        <Route path="/order/create" component={OrderCreate} />
        <Route path="/order" component={Order} />
        <Route path="/company" component={Companies} />
        <Route path="/company/create" component={CompaniesCreate}/>
        <Route path="/user/create" component={UsersCreate} />
        <Route path="/user" component={Users} />
        <Route path="/user/:id" component={UsersDetails} />
        <Route path="/user/:id/edit" component={UsersUpdate} />
        <Route path="/company/:id" component={CompanyDetails} />
        <Route path="/order/:id" component={OrderDetails} />
        <Route path="/vehicle" component={Vehicle} />
        <Route path="/vehicle/create" component={VehicleCreate} />
        <Route path="/vehicle/:id" component={VehicleDetails} />
        <Route path="/settings" component={SettingsPage} />
        <Route path="/b2b/create" component={B2BCreate} />
        <Route path="/b2b/:id" component={B2BCompanyDetails} />
        <Route path="/b2b" component={B2B} />
        <Route path="/bills/create" component={BillCreate} />
        <Route path="/bills/:billNumber" component={BillDetails} />
        <Route path="/bills" component={Bills} />
        <Route path="/conversations" component={Conversations} />
        <Route path="/" component={Home} />
      </Router>
      </Mercure>
    </I18n>
  </Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
