import React from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import MaterialIcon from 'material-icons-react';
import _ from 'lodash';

import './styles.scss';

class NavBar extends React.Component{

  render(){
    return(
      <div className="navbar">
        <div className="logo">
          <img src="/logo.svg" alt="Logo" />
        </div>
        <div
          className={this.props.route === "" || this.props.route === "/" ? "navbar-item active" : "navbar-item"}
          onClick={() => this.props.push("/")}
        >
          <MaterialIcon icon="home" />
        </div>
        <div
          className={this.props.route === "order" || this.props.route === "/order" ? "navbar-item active" : "navbar-item"}
          onClick={() => this.props.push("/order")}
        >
          <MaterialIcon icon="local_shipping" />
        </div>
        
        <div
          className={this.props.route === "b2b" || this.props.route === "/b2b" ? "navbar-item active" : "navbar-item"}
          onClick={() => this.props.push("/b2b")}
        >
          <MaterialIcon icon="store" />
        </div>
        <div
          className={this.props.route === "bills" || this.props.route === "/bills" ? "navbar-item active" : "navbar-item"}
          onClick={() => this.props.push("/bills")}
        >
          <MaterialIcon icon="account_balance_wallet" />
        </div>
        {
          this.props.moreThanOneEmploye && (
            <div
              className={this.props.route === "conversations" || this.props.route === "/conversations" ? "navbar-item active" : "navbar-item"}
              onClick={() => this.props.push("/conversations")}
            >
              <MaterialIcon icon="message" />
            </div>
          )
        }
        <div
          className={this.props.route === "settings" || this.props.route === "/settings" ? "navbar-item active" : "navbar-item"}
          onClick={() => this.props.push("/settings")}
        >
          <MaterialIcon icon="settings" />
          <div className={"badge" + (this.props.settingsBadgeNumber <= 0 ? " none" : "")}>
            {this.props.settingsBadgeNumber <= 9 ? this.props.settingsBadgeNumber : "9+"}
          </div>
        </div>
        <div
            className="navbar-item"
            onClick={() => {
                let token = localStorage.getItem('depannmoi_user_token');
                if (token) {
                    localStorage.removeItem('depannmoi_user_token');
                }
                this.props.push('/login');
            }
        }>
            <MaterialIcon icon="power_settings_new" />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  buttons: state.navbarReducer.buttons,
  route: state.routing.locationBeforeTransitions.pathname.split("/")[1],
  moreThanOneEmploye: _.get(state, "currentUser.user.company.employes.length", 1) > 1
})

const mapDispatchToProps = (dispatch) => ({
  push: (path) => dispatch(push(path))
})

export default connect(mapStateToProps, mapDispatchToProps)(NavBar);
