import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { towTruckType } from '../../constants';

import './styles.scss';

import HeaderButtons from '../../components/HeaderButtons';
import Input from '../../components/Input';
import Button from '../../components/Buttons/FormButton';
import SubmitButton from '../../components/Buttons/SubmitButton';
import CompanyAutocomplete from '../../components/Autocomplete/companyname';
import BrandAutocomplete from '../../components/Autocomplete/vehiclebrand';
import ModelAutocomplete from '../../components/Autocomplete/vehiclemodels';

import Template from '../../templates/Backoffice';
import Form from '../../templates/Form';
import ReduxTruckType from '../../redux/types/towtruck';

import TowTruck from '../../core/TowTruck';

class VehicleCreatePage extends React.Component{

  constructor(props){
    super(props);
    this.state = {
      brand: undefined
    }
  }

  checkValues = () => {
    let errors = {};
    let noError = true;

    if(!this.state.model || this.state.model === ""){
      errors.model = this.context.t("is_required");
      noError = false;
    }
    if(!this.state.brand || this.state.brand === ""){
      errors.brand = this.context.t("is_required");
      noError = false;
    }
    if(!this.state.registration || this.state.registration === "" || this.state.registration.length < 6 || this.state.registration > 8){
      errors.registration = this.context.t("is_required");
      noError = false;
    }
    if(this.state.type < 0 || this.state.type === undefined){
      errors.type = this.context.t("is_required");
      noError = false;
    }
    if(!this.state.company || !this.state.company.id){
      errors.company = this.context.t("is_required");
      noError = false;
    }

    this.setState({errors: errors})
    return noError;
  }

  onClickConfirm = (e) => {
    this.checkValues();
    if(this.props.token && this.props.user.roles.includes("ROLE_ADMIN") && this.checkValues()){
      new TowTruck({
        companyIdentifier: this.state.company.id,
        type: this.state.type,
        registration: this.state.registration,
        brand: this.state.brand,
        model: this.state.model,
      }).setToken(this.props.token).create().then(data => {this.props.addTowTruck(data); this.props.push("/vehicle")});
    }
  }

  render(){
    return(
      <Template>
        <Form>
          <HeaderButtons
            tabList={[
              {
                label: this.context.t('list_of_vehicles'),
                route: 'vehicle',
                onClick: () => this.props.push("/vehicle")
              },
              {
                label: this.context.t('create_vehicle'),
                route: 'vehicle/create',
                onClick: () => this.props.push("/vehicle/create")
              }
            ]}
          />
          <h1>{this.context.t('create_vehicle')}</h1>
          <div className="section">
            <div>
              <h2>{this.context.t('basic_infos')}</h2>
              <BrandAutocomplete
                placeholder={this.context.t('vehicle_brand')}
                getValue={(value) => {
                  this.setState({
                    brand: value
                  }, () => { if(this.state.clicked) this.checkValues() });
                }}
                priorityValue={_.get(this.state, 'brand', undefined)}
                token={this.props.token}
                error={_.get(this.state, 'errors.brand', false) ? true : false}
              />
              <ModelAutocomplete
                placeholder={this.context.t('vehicle_model')}
                getValue={(value) => {
                  this.setState({
                    model: value
                  }, () => { if(this.state.clicked) this.checkValues() });
                }}
                priorityValue={_.get(this.state, 'model', undefined)}
                token={this.props.token}
                error={_.get(this.state, 'errors.model', false) ? true : false}
                brand={this.state.brand ? this.state.brand : undefined}
              />
              <Input
                getValue={value => this.setState({registration: value}, () => { if(this.state.clicked) this.checkValues() })}
                placeholder={this.context.t('registration')}
                priorityValue={_.get(this.state, 'registration', false) ? this.state.registration : ""}
                error={_.get(this.state, 'errors.registration', false) ? true : false}
                autoComplete={false}
              />
            </div>
            <div>
              <h2>{this.context.t('company')}</h2>
              <CompanyAutocomplete
                placeholder={this.context.t('company_name')}
                getValue={(value) => {
                  this.setState({company: {
                    id: value.id,
                    name: value.name
                  }}, () => { if(this.state.clicked) this.checkValues() })
                }}
                priorityValue={_.get(this.state, 'company.name', undefined)}
                token={this.props.token}
                error={_.get(this.state, 'errors.company', false) ? true : false}
              />
            </div>
            <div>
              <h2>{this.context.t('towtruck_type')}</h2>
              <div>
                <Button
                  onClick={() => this.setState({type: towTruckType.TYPE_QUATRE_X_QUATRE}, () => { if(this.state.clicked) this.checkValues() })}
                  label={this.context.t('type_4x4')}
                  selected={_.get(this.state, 'type') === towTruckType.TYPE_QUATRE_X_QUATRE ? true : false}
                  error={_.get(this.state, 'errors.type', false) ? true : false}
                />
                <Button
                  onClick={() => this.setState({type: towTruckType.TYPE_PLATEAU_VL}, () => { if(this.state.clicked) this.checkValues() })}
                  label={this.context.t('type_plateau_vl')}
                  selected={_.get(this.state, 'type') === towTruckType.TYPE_PLATEAU_VL ? true : false}
                  error={_.get(this.state, 'errors.type', false) ? true : false}
                />
              </div>
              <div>
                <Button
                  onClick={() => this.setState({type: towTruckType.TYPE_PLATEAU_PL}, () => { if(this.state.clicked) this.checkValues() })}
                  label={this.context.t('type_plateau_pl')}
                  selected={_.get(this.state, 'type') === towTruckType.TYPE_PLATEAU_PL ? true : false}
                  error={_.get(this.state, 'errors.type', false) ? true : false}
                />
                <Button
                  onClick={() => this.setState({type: towTruckType.TYPE_SPL}, () => { if(this.state.clicked) this.checkValues() })}
                  label={this.context.t('type_spl')}
                  selected={_.get(this.state, 'type') === towTruckType.TYPE_SPL ? true : false}
                  error={_.get(this.state, 'errors.type', false) ? true : false}
                />
              </div>
              <div>
                <Button
                  onClick={() => this.setState({type: towTruckType.TYPE_GRUE}, () => { if(this.state.clicked) this.checkValues() })}
                  label={this.context.t('type_grue')}
                  selected={_.get(this.state, 'type') === towTruckType.TYPE_GRUE ? true : false}
                  error={_.get(this.state, 'errors.type', false) ? true : false}
                />
              </div>
            </div>
            <div>
              <div>
                <SubmitButton
                  isCancel
                  label={this.context.t("cancel")}
                  onClick={() => this.setState({
                    name: "", firstname: "", phonenumber: "", email: "", role: "", company: {}, password1: "", password2: ""
                  })}
                />
                <SubmitButton
                  label={this.context.t("confirm")}
                  onClick={(e) => {this.onClickConfirm(e); this.setState({clicked: true})}}
                />
              </div>
            </div>
          </div>
        </Form>
      </Template>
    );
  }
}

VehicleCreatePage.contextTypes = {
  t: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
  token: state.currentUser.token,
  user: state.currentUser.user,
})
const mapDispatchToProps = (dispatch) => ({
  push: (route) => dispatch(push(route)),
  addTowTruck: (truck) => dispatch({
    type: ReduxTruckType.TOWTRUCK_ADD_TO_LIST,
    payload: truck
  })
})

export default connect(mapStateToProps, mapDispatchToProps)(VehicleCreatePage);
