import $ from 'jquery';

import env from '../../env';

class User {

  constructor(user = {}){
    this.name = user.name;
    this.firstname = user.firstname;
    this.email = user.email;
    this.phonenumber = user.phonenumber;
    this.plainRole = this.getAvailableRoles().includes(user.role) ? user.role : undefined;
    this.plainPassword = user.password;
    this.companyIdentifier = user.companyIdentifier;
    this.whiteCard = user.white_card ? 1 : 0;
    this.towTruckIdentifier = user.towTruckIdentifier;
    if(user.birth){
      user.birth = user.birth.split("/");
      this.birth = user.birth[2] + "-" + user.birth[1] + "-" + user.birth[0];
    }
  }

  setToken(token){
    this.token = token;
    return this;
  }

  getOrders(id){
    return new Promise((resolve, reject) => {
      $.ajax({
        url: env.backendUrl + "api/v1/users/" + id + "/orders",
        headers: {
          "Authorization": "Bearer " + this.token
        },
        dataType: "json",
        contentType: "application/json; charset=utf-8",
        success: data => resolve(data),
        error: (err) => reject(404)
      })
    })
  }

  getAvailableRoles(){
    return ["ROLE_USER", "ROLE_DRIVER", "ROLE_DISPATCH", "ROLE_ADMIN"];
  }

  findOne(id){
    return new Promise((resolve, reject) => {
      $.ajax({
        url: env.backendUrl + "api/v1/users/" + id,
        headers: {
          "Authorization": "Bearer " + this.token
        },
        dataType: "json",
        contentType: "application/json; charset=utf-8",
        success: data => resolve(data),
        error: (err) => reject(404)
      })
    })
  }

  findUsersByCompanyId(id){
    return new Promise((resolve, reject) => {
      $.ajax({
        url: env.backendUrl + "api/v1/users/company",
        headers: {
          "Authorization": "Bearer " + this.token
        },
        data: {
          companyIdentifier: id
        },
        dataType: "json",
        contentType: "application/json; charset=utf-8",
        success: data => resolve(data),
        error: (err) => reject(404)
      })
    })
  }

  getAllUsers(){
    return new Promise((resolve, reject) => {
      $.ajax({
        url: env.backendUrl + "api/v1/users",
        headers: {
          "Authorization": "Bearer " + this.token
        },
        dataType: "json",
        contentType: "application/json; charset=utf-8",
        success: data => resolve(data),
        error: (err) => reject(err)
      })
    });
  }

  searchByCompanyName(companyName){
    return new Promise((resolve, reject) => {
      $.ajax({
        url: env.backendUrl + "api/v1/users?companyName="+companyName,
        headers: {
          "Authorization": "Bearer " + this.token
        },
        dataType: "json",
        contentType: "application/json; charset=utf-8",
        success: data => resolve(data),
        error: (err) => reject(err)
      })
    });
  }

  searchByName(name){
    return new Promise((resolve, reject) => {
      $.ajax({
        url: env.backendUrl + "api/v1/users?name="+name,
        headers: {
          "Authorization": "Bearer " + this.token
        },
        dataType: "json",
        contentType: "application/json; charset=utf-8",
        success: data => resolve(data),
        error: (err) => reject(err)
      })
    });
  }

  signUp(){
    return new Promise((resolve, reject) => {
      $.ajax( env.backendUrl + "api/v1/users", {
        type: "POST",
        crossDomain: true,
        headers: {
          "Authorization": "Bearer " + this.token
        },
        success: data => resolve(data),
        error: err => reject(err),
        data: {
          name: this.name,
          firstname: this.firstname,
          email: this.email,
          phonenumber: this.phonenumber,
          plainRole: this.plainRole,
          plainPassword: this.plainPassword,
          companyIdentifier: this.companyIdentifier,
          birth: this.birth,
          whiteCard: this.whiteCard
        }
      });
    })
  }

  update(id){
    return new Promise((resolve, reject) => {
      $.ajax( env.backendUrl + "api/v1/users/" + id, {
        type: "POST",
        crossDomain: true,
        headers: {
          "Authorization": "Bearer " + this.token
        },
        success: data => resolve(data),
        error: err => reject(err),
        data: {
          plainRole: this.plainRole,
          plainPassword: this.plainPassword,
          towTruckIdentifier: this.towTruckIdentifier
        }
      });
    })
  }

}

export default User;
