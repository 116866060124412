import React from 'react';
import PropTypes from 'prop-types';

import './styles.scss';

class DetailsView extends React.Component{
  render(){
    if(this.props.isLoading){
      return (
        <div className={this.props.className ? "details-view loading " + this.props.className : "details-view loading"}>
          <div>
            {this.context.t('loading')}
          </div>
        </div>
      )
    }
    return(
      <div className={this.props.className ? "details-view " + this.props.className : "details-view"}>
        <div>
          {this.props.children}
        </div>
      </div>
    );
  }
}

DetailsView.contextTypes = {
  t: PropTypes.func.isRequired
}

export default DetailsView;
