import React from 'react';
import PropTypes from 'prop-types';

import './styles.scss';

import MaterialIcon from 'material-icons-react';

class SearchBar extends React.Component{

  constructor(props){
    super(props);
    this.state = {
      openMenu: false
    }
  }

  render(){

    let searchOptions = this.props.searchOptions && Array.isArray(this.props.searchOptions) ? this.props.searchOptions : [];
    let borderTop = this.state.openMenu ? "1px solid rgba(28, 107, 177, 0.1)" : "0px solid rgba(28, 107, 177, 0)";
    let borderBottom = this.state.openMenu ? "1px solid rgba(0, 0, 0, 0.1)" : "0px solid rgba(28, 107, 177, 0)";

    return (
      <div className="search-bar">
        <input
          type="text"
          placeholder={this.context.t('make_search_' + (this.props.current.label ? this.props.current.label : ""))}
          // onChange={(event) => this.props.getSearchValue(event.target.value)}
          onChange={(event) => this.props.current.action(event.target.value)}
          onClick={() => this.setState({openMenu: false})}
        />
        <div>
          {/*<MaterialIcon icon="search" size={"tiny"} color="#1c6bb1" />*/}
          <MaterialIcon icon="subject" size={"tiny"} color="#1c6bb1" onClick={() => this.setState({openMenu: !this.state.openMenu})} />
          <ul style={{height: this.state.openMenu ? searchOptions.length * 100 + "%" : "0", borderTop: borderTop, borderBottom: borderBottom}}>
            {searchOptions.map((option, i) => {
              return (
                <li key={i} className={this.props.current.label === option.label ? "selected" : ""} onClick={() => this.setState({openMenu: false}, () => this.props.onChooseSearchOption(option))}>{ this.context.t(option.label) }</li>
              )
            })}
          </ul>
        </div>
      </div>
    );
  }
}

SearchBar.contextTypes = {
  t: PropTypes.func.isRequired
}

export default SearchBar;
