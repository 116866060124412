import { EventSourcePolyfill } from 'event-source-polyfill';
import env from '../../env.js'

class Mercure {
  constructor(){
    this.token = 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJtZXJjdXJlIjp7InB1Ymxpc2giOlsiKiJdfX0.6uAWGoioQSR8uac53F0LyEIjrn9eQZD8x_oTum7qTdM';
  }

  realTimeConversationMessage() {
    let url = new URL("https://hub.depannmoi.com/hub");
    url.searchParams.append('topic', "https://hub.depannmoi.com/conversations/message");
    let espf = new EventSourcePolyfill(url, {
      heartbeatTimeout: 8640000,
      headers: {
        'Authorization': this.token
      }
    });
    espf.addEventListener('open', () => {if(env.debug) console.log(`Connexion ouverte realTimeConversationMessage`)})
    espf.addEventListener('error', () => {
      console.log("realTimeConversationMessage ERROR... closing !");
      espf.close();
      espf = this.realTimeConversationMessage();
    })
    return espf;
  }

  realTimeConversation(){
    let url = new URL("https://hub.depannmoi.com/hub");
    url.searchParams.append('topic', "https://hub.depannmoi.com/conversations");
    let espf = new EventSourcePolyfill(url, {
      heartbeatTimeout: 8640000,
      headers: {
        'Authorization': this.token
      }
    });
    espf.addEventListener('open', () => {if(env.debug) console.log(`Connexion ouverte realTimeConversation`)})
    espf.addEventListener('error', () => {
      console.log("realTimeConversation ERROR... closing !");
      espf.close();
      espf = this.realTimeConversation();
    })
    return espf;
  }

  realTimeOrders(){
    let url = new URL("https://hub.depannmoi.com/hub");
    url.searchParams.append('topic', 'https://hub.depannmoi.com/new_order');
    let espf = new EventSourcePolyfill(url, {
      heartbeatTimeout: 8640000,
      headers: {
        'Authorization': this.token
      }
    });
    espf.addEventListener('open', () => {if(env.debug) console.log("Connexion ouverte realTimeOrders")})
    espf.addEventListener('error', () => {
      console.log("realTimeOrders ERROR... closing !");
      espf.close();
      espf = this.realTimeOrders();
    })
    return espf;
  }

  updateCurrentUser(userId){
    let url = new URL("https://hub.depannmoi.com/hub");
    url.searchParams.append('topic', "https://hub.depannmoi.com/user_updated/" + userId);
    let espf = new EventSourcePolyfill(url, {
      heartbeatTimeout: 8640000,
      headers: {
        'Authorization': this.token
      }
    });
    espf.addEventListener('open', () => {if(env.debug) console.log("Connexion ouverte updateCurrentUser")});
    espf.addEventListener('error', () => {
      console.log("updateCurrentUser ERROR... closing !");
      espf.close();
      espf = this.updateCurrentUser(userId);
    })
    return espf;
  }

  realTimeDispatch(companyId){
    let url = new URL("https://hub.depannmoi.com/hub");
    url.searchParams.append('topic', "https://hub.depannmoi.com/new_order_for_company/" + companyId);
    let espf = new EventSourcePolyfill(url, {
      heartbeatTimeout: 8640000,
      headers: {
        'Authorization': this.token,
      }
    });
    espf.addEventListener('open', () => {if(env.debug) console.log("Connexion ouverte realTimeDispatch")});
    espf.addEventListener('error', () => {
      console.log("realTimeDispatch ERROR... closing !");
      espf.close();
      espf = this.realTimeDispatch(companyId);
    });
    return espf;
  }

  realTimeTowTrucks(){
    let url = new URL("https://hub.depannmoi.com/hub");
    url.searchParams.append('topic', "https://hub.depannmoi.com/towtruck_updated/");
    let espf = new EventSourcePolyfill(url, {
      heartbeatTimeout: 8640000,
      headers: {
        'Authorization': this.token
      }
    });
    espf.addEventListener('open', () => {if(env.debug) console.log("Connexion ouverte realTimeTowTrucks")});
    espf.addEventListener('error', () => {
      console.log("realTimeTowTrucks ERROR... closing !");
      espf.close();
      espf = this.realTimeTowTrucks();
    });
    return espf;
  }


  realTimeOrdersUpdated(){
    let url = new URL("https://hub.depannmoi.com/hub");
    url.searchParams.append('topic', "https://hub.depannmoi.com/new_order_updated/");
    let espf = new EventSourcePolyfill(url, {
      heartbeatTimeout: 8640000,
      headers: {
        'Authorization': this.token
      }
    });
    espf.addEventListener('open', () => {if(env.debug) console.log("Connexion ouverte realTimeOrdersUpdated")});
    espf.addEventListener('error', () => {
      console.log("realTimeOrdersUpdated ERROR... closing !");
      espf.close();
      espf = this.realTimeOrdersUpdated();
    });
    return espf;
  }
}

export default Mercure;
