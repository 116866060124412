import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import _ from 'lodash';

import { vehicleType } from '../../constants';

import Button from '../../components/Buttons/SubmitButton';
import HeaderButtons from '../../components/HeaderButtons';
import Problem from './problem';
import ReasonChoice from './reasonChoice';
import VehicleType from './vehicleType';
import LimitedHeight from './limitedHeight';
import VehicleInfos from './vehicleInfos';
import Path from './path';
import CustomerInfos from './customer';
import Price from './price';
import Comment from './comment';
import DateInfos from './date';
import PoliceStation from './police';
import IsB2B from './b2b';
import Rolling from './rolling';
import Dispatch from './dispatch';

import './styles.scss';
import OrderType from '../../redux/types/order';
import Order from '../../core/Order';

import Template from '../../templates/Backoffice';
import Form from '../../templates/Form';
import AdvanceOfCost from './advanceOfCost';

class OrderCreate extends React.Component{

  constructor(props){
    super(props);
    this.state = {
      order: {}
    }
  }

  componentDidUpdate(prevProps){
    if(prevProps.order !== this.props.order){
      this.setState({
        order: this.props.order
      });
    }
  }

  onClickConfirm = () => {
    let order = this.props.order;
    new Order({
      vehicleBrand: order.vehicle_infos.brand,
      vehicleModel: order.vehicle_infos.model,
      vehicleRegistration: order.vehicle_infos.registration,
      problem: order.problem,
      reason: order.reason,
      vehicleType: order.vehicle_type,
      departureAddress: order.path.departure.address,
      departureLat: order.path.departure.coords.lat,
      departureLng: order.path.departure.coords.lng,
      arrivalAddress: order.path.arrival.address,
      arrivalLat: order.path.arrival.coords.lat,
      arrivalLng: order.path.arrival.coords.lng,
      limitedHeight: order.limited_height ? 1 : 0,
      firstname: order.customer_infos.firstname,
      name: order.customer_infos.name,
      email: order.customer_infos.email,
      phonenumber: order.customer_infos.phonenumber,
      billAddress: order.customer_infos.bill_address,
      price: order.price,
      companyIdentifier: order.companyIdentifier,
      driverIdentifier: order.driverIdentifier,
      paymentMethod: order.payment_method !== undefined ? order.payment_method : undefined,
      paid: order.paid ? 1 : 0,
      note: order.note,
      wishedInterventionDate: order.date,
      addressPoliceStation: _.get(this.props, 'order.path.police_station.address', undefined),
      policeLng: _.get(this.props, 'order.path.police_station.coords.lng', undefined),
      policeLat: _.get(this.props, 'order.path.police_station.coords.lat', undefined),
      b2bCompanyIdentifier: _.get(this.props, 'order.customer_infos.b2b_company_id', undefined),
      rolling: this.props.order.rolling,
      advanceOfCost: _.get(this.props, 'order.advance_of_cost', 0),
      advanceOfCostTva: _.get(this.props, 'order.advance_of_cost_tva', undefined),
      discount: _.get(this.props, 'order.discount', 0),
      ref: _.get(this.props, 'order.ref', undefined)
    }).setToken(this.props.user.token).createOrder().then(data => {
      this.props.clearOrder();
      this.props.push('/order');
    });
  }

  render(){
    console.log("Order : ", this.props.order);
    return(
      <Template>
        <Form className="create-order">
          <HeaderButtons
            tabList={[
              {
                label: this.context.t('intervention_request'),
                route: 'order',
                onClick: () => this.props.push("/order")
              },
              {
                label: this.context.t('create_interventions'),
                route: 'order/create',
                onClick: () => this.props.push("/order/create")
              }
            ]}
          />
          <h1>{this.context.t('create_interventions')}</h1>
          <div className="section">
            <IsB2B />
            { this.props.is_b2b && <Rolling /> }
            {
              !this.props.rolling && (
                <>
                  <Problem />
                  <ReasonChoice />
                </>
              )
            }
            <DateInfos />
            <VehicleInfos />
            <VehicleType />
            {
              _.get(this.props, 'order.vehicle_type', undefined) < vehicleType.LIGHT_PL && <LimitedHeight />
            }
            <Path />
            { this.props.is_b2b && <PoliceStation /> } 
            { this.props.is_b2b && <AdvanceOfCost /> } 

            <Dispatch />
            
            <CustomerInfos />
            <Price />
            <Comment />
            <div>
              <div>
                <Button
                  isCancel
                  label={this.context.t("cancel")}
                  onClick={() => this.props.clearOrder()}
                />
                <Button
                  label={this.context.t("confirm")}
                  onClick={() => this.onClickConfirm()}
                />
              </div>
            </div>
          </div>
        </Form>
      </Template>
    );
  }
}

OrderCreate.contextTypes = {
  t: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
  order: state.order,
  is_b2b: state.order.is_b2b,
  rolling: state.order.rolling,
  user: state.currentUser
})

const mapDispatchToProps = (dispatch) => ({
  clearOrder: () => dispatch({
    type: OrderType.ORDER_CANCEL_CREATE_ORDER,
    payload: {}
  }),
  push: (route) => dispatch(push(route))
})

export default connect(mapStateToProps, mapDispatchToProps)(OrderCreate);
