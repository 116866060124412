import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';

import Autocomplete from '../../components/Autocomplete';

import './styles.scss';
import CompanyType from '../../redux/types/company';

class CompanyLocation extends React.Component{

    constructor(props){
        super(props);
        this.state = { error_location: false }
    }

    _locationOnBlur = (e) => {
        if (this.state.error_location && /[0-9A-Za-z-.,_]{5,}/.test(this.props.address)) {
            this.setState({ error_location: false })
        } else {
            if (!/[0-9A-Za-z-.,_]{5,}/.test(this.props.address)) {
                this.setState({ error_location: true })
            } else {
                this.setState({ error_location: false })
            }
        }
    }

  render(){
    return(
      <div>
        <h2>{this.context.t('question_company_location')}</h2>
        {
            this.state.error_location && <span className="error-message">{this.context.t('error_departure')}</span>
        }
        <Autocomplete
          placeholder={this.context.t("company_address")}
          getValue={(value) => this.props.setLocation(value)}
          priorityValue={this.props.address}
          onBlur={this._locationOnBlur}
          error={this.state.error_location}
        />
      </div>
    );
  }
}

CompanyLocation.contextTypes = {
  t: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
  address: _.get(state, "company.currentCompany.address") ? state.company.currentCompany.address : ""
})

const mapDispatchToProps = (dispatch) => ({
  setLocation: (location) => dispatch({
    type: CompanyType.COMPANY_CREATE_SET_LOCATION,
    payload: {
      address: _.get(location, 'address', undefined),
      latitude: _.get(location, 'coords[1]', undefined),
      longitude: _.get(location, 'coords[0]', undefined)
    }
  })
})

export default connect(mapStateToProps, mapDispatchToProps)(CompanyLocation);
