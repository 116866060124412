import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';

import Autocomplete from '../../components/Autocomplete';
import Button from '../../components/Buttons/FormButton';
import Label from '../../components/Label';

import './styles.scss';
import OrderType from '../../redux/types/order';

class PoliceStation extends React.Component{

  constructor(props){
    super(props);
    this.state = {
      police_station: false,
      police_station_address: "",
    };
  }

  setPoliceStation = (ps) => {
    if (ps) {
        this.setState({
            police_station_address: ps.address,
        }, () => {
          this.props.setPoliceStation({
            address: ps.address,
            coords: {
              lng: ps.coords[0],
              lat: ps.coords[1]
            }
          })
        })
    }
  }

  render(){
    return(
        <div>
          <h2>{this.context.t('question_about_police_station')}</h2>
          <Button 
            onClick={e => this.setState({ police_station: !this.state.police_station }, () => {
              if (!this.state.police_station) {
                this.props.unsetPoliceStation();
              }
            })}
            label={this.state.police_station ? this.context.t('yes') : this.context.t('no')}
            selected={this.state.police_station}
          />
          { this.state.police_station && (
              <Autocomplete
                placeholder={this.context.t("police_station_address")}
                getValue={(value) => this.setPoliceStation(value)}
                defaultValue={this.state.police_station_address}
              />
          )}
        </div>
    );
  }
}

PoliceStation.contextTypes = {
  t: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
  order: state.order,
})

const mapDispatchToProps = (dispatch) => ({
  unsetPoliceStation: () => dispatch({
    type: OrderType.ORDER_UNSET_POLICE_STATION
  }),
  setPoliceStation: (depObj) => dispatch({
    type: OrderType.ORDER_SELECT_POLICE_STATION,
    payload: {
      address: _.get(depObj, 'address', ""),
      coords: {
        lat: _.get(depObj, 'coords.lat', undefined),
        lng: _.get(depObj, 'coords.lng', undefined)
      }
    }
  }),
})

export default connect(mapStateToProps, mapDispatchToProps)(PoliceStation);
