import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';

import Input from '../../components/Input';

import './styles.scss';
import CompanyType from '../../redux/types/company';

class CompanyInfos extends React.Component{

  constructor(props){
      super(props);
      this.state = {};
  }

  _emailOnBlur = (e) => {
      if (this.state.error_email && /\S+@\S+\.\S{1,4}/.test(this.props.email)) {
          this.setState({ error_email: false })
      } else {
          if (!/\S+@\S+\.\S{1,4}/.test(this.props.email)) {
              this.setState({ error_email: true })
          } else {
              this.setState({ error_email: false })
          }
      }
  }

  _companyNameOnBlur = (e) => {
      if (this.state.error_company_name && /[a-zA-Z ]{2,}/.test(this.props.name)) {
          this.setState({ error_company_name: false })
      } else {
          if (!/[a-zA-Z ]{2,}/.test(this.props.name)) {
              this.setState({ error_company_name: true })
          } else {
              this.setState({ error_company_name: false })
          }
      }
  }

  _phonenumberOnBlur = (e) => {
      if (this.state.error_phonenumber && /[+0-9]{10,12}/.test(this.props.phonenumber)) {
          this.setState({ error_phonenumber: false })
      } else {
          if (!/[+0-9]{10,12}/.test(this.props.phonenumber)) {
              this.setState({ error_phonenumber: true })
          } else {
              this.setState({ error_phonenumber: false })
          }
      }
  }

  _siretOnBlur = (e) => {
      if (this.state.error_siret && /[0-9]{14}/.test(this.props.siret)) {
          this.setState({ error_siret: false })
      } else {
          if (!/[0-9]{14}/.test(this.props.siret)) {
              this.setState({ error_siret: true })
          } else {
              this.setState({ error_siret: false })
          }
      }
  }

  render(){
      return(
        <div>
          <h2>{this.context.t('question_company_infos')}</h2>
          {
            this.state.error_company_name && <span className="error-message">{this.context.t('error_company_name')}</span>
          }
          <Input
            getValue={value => this.props.setName(value)}
            placeholder={this.context.t('company_name')}
            priorityValue={this.props.name}
            autoComplete={false}
            onBlur={this._companyNameOnBlur}
            error={this.state.error_company_name}
          />
          <Input 
            type="file"
            getValue={this.props.setLogo}
          />
          {
            this.state.error_phonenumber && <span className="error-message">{this.context.t('error_phonenumber')}</span>
          }
          <Input
            getValue={value => this.props.setPhonenumber(value)}
            placeholder={this.context.t('phonenumber')}
            priorityValue={this.props.phonenumber}
            autoComplete={false}
            onBlur={this._phonenumberOnBlur}
            error={this.state.error_phonenumber}
          />
          {
            this.state.error_email && <span className="error-message">{this.context.t('error_email')}</span>
          }
          <Input
            getValue={value => this.props.setEmail(value)}
            placeholder={this.context.t('company_email')}
            priorityValue={this.props.email}
            autoComplete={false}
            onBlur={this._emailOnBlur}
            error={this.state.error_email}
          />
          {
            this.state.error_siret && <span className="error-message">{this.context.t('error_siret')}</span>
          }
          <Input
            getValue={value => this.props.setSiret(value)}
            placeholder={this.context.t('company_siret')}
            priorityValue={this.props.siret}
            autoComplete={false}
            onBlur={this._siretOnBlur}
            error={this.state.error_siret}
          />
        </div>
      );
    }
}

CompanyInfos.contextTypes = {
  t: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
  name: _.get(state, 'company.currentCompany.name') ? state.company.currentCompany.name : "",
  phonenumber: _.get(state, 'company.currentCompany.phonenumber') ? state.company.currentCompany.phonenumber : "",
  email: _.get(state, 'company.currentCompany.email') ? state.company.currentCompany.email : "",
  siret: _.get(state, 'company.currentCompany.siret') ? state.company.currentCompany.siret : "",
})

const mapDispatchToProps = (dispatch) => ({
  setName: (name) => dispatch({
    type: CompanyType.COMPANY_CREATE_SET_NAME,
    payload: name
  }),
  setPhonenumber: (phonenumber) => dispatch({
    type: CompanyType.COMPANY_CREATE_SET_PHONENUMBER,
    payload: phonenumber
  }),
  setEmail: (email) => dispatch({
    type: CompanyType.COMPANY_CREATE_SET_EMAIL,
    payload: email
  }),
  setSiret: (siret) => dispatch({
    type: CompanyType.COMPANY_CREATE_SET_SIRET,
    payload: siret
  }),
  setLogo: (logo) => dispatch({
    type: CompanyType.COMPANY_CREATE_SET_LOGO,
    payload: logo
  })
})

export default connect(mapStateToProps, mapDispatchToProps)(CompanyInfos);
