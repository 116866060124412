import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';

import B2BCreateType from '../../redux/types/b2b_create_type';

import Input from '../../components/Input';

import './styles.scss';
import Autocomplete from '../../components/Autocomplete';

class B2BInfos extends React.Component{

  constructor(props){
      super(props);
      this.state = {};
  }

  _emailOnBlur = (e) => {
      if (this.state.error_email && /\S+@\S+\.\S{1,4}/.test(this.props.email)) {
          this.setState({ error_email: false })
      } else {
          if (!/\S+@\S+\.\S{1,4}/.test(this.props.email)) {
              this.setState({ error_email: true })
          } else {
              this.setState({ error_email: false })
          }
      }
  }

  _companyNameOnBlur = (e) => {
      if (this.state.error_company_name && /[a-zA-Z ]{2,}/.test(this.props.name)) {
          this.setState({ error_company_name: false })
      } else {
          if (!/[a-zA-Z ]{2,}/.test(this.props.name)) {
              this.setState({ error_company_name: true })
          } else {
              this.setState({ error_company_name: false })
          }
      }
  }

  _phonenumberOnBlur = (e) => {
      if (this.state.error_phonenumber && /[+0-9]{10,12}/.test(this.props.phonenumber)) {
          this.setState({ error_phonenumber: false })
      } else {
          if (!/[+0-9]{10,12}/.test(this.props.phonenumber)) {
              this.setState({ error_phonenumber: true })
          } else {
              this.setState({ error_phonenumber: false })
          }
      }
  }

  setLocation = (location) => {
    if (location) {
        this.setState({
            location: location.address,
        }, () => {
          this.props.setLocation({
            address: location.address,
            coords: {
              lng: location.coords[0],
              lat: location.coords[1]
            }
          })
        })
    }
  }

  render(){
      return(
        <div>
          <h2>{this.context.t('question_company_infos')}</h2>
          {
            this.state.error_company_name && <span className="error-message">{this.context.t('error_company_name')}</span>
          }
          <Input
            getValue={value => this.props.setCompanyName(value)}
            placeholder={this.context.t('company_name')}
            priorityValue={this.props.companyName}
            autoComplete={false}
            onBlur={this._companyNameOnBlur}
            error={this.state.error_company_name}
          />
          {
            this.state.error_phonenumber && <span className="error-message">{this.context.t('error_phonenumber')}</span>
          }
          <Input
            getValue={value => this.props.setPhonenumber(value)}
            placeholder={this.context.t('phonenumber')}
            priorityValue={this.props.phonenumber}
            autoComplete={false}
            onBlur={this._phonenumberOnBlur}
            error={this.state.error_phonenumber}
          />
          {
            this.state.error_email && <span className="error-message">{this.context.t('error_email')}</span>
          }
          <Input
            getValue={value => this.props.setEmail(value)}
            placeholder={this.context.t('company_email')}
            priorityValue={this.props.email}
            autoComplete={false}
            onBlur={this._emailOnBlur}
            error={this.state.error_email}
          />
          <Input
            getValue={value => this.props.setTVANumber(value)}
            placeholder={this.context.t('tva_number')}
            priorityValue={this.props.tvaNumber}
            autoComplete={false}
          />
          <Autocomplete 
            placeholder={this.context.t("company_address")}
            getValue={(value) => this.setLocation(value)}
            priorityValue={this.props.location}
          />
        </div>
      );
    }
}

B2BInfos.contextTypes = {
  t: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
  companyName: _.get(state.b2bCreate, 'infos.companyName', ""),
  email: _.get(state.b2bCreate, "infos.email", ""),
  phonenumber: _.get(state.b2bCreate, 'infos.phonenumber', ""),
  location: _.get(state.b2bCreate, "infos.location.address", ""),
  tvaNumber: _.get(state.b2bCreate, "infos.tvaNumber", "")
})

const mapDispatchToProps = (dispatch) => ({
  setCompanyName: (companyName) => dispatch({
      type: B2BCreateType.B2B_CREATE_SET_COMPANY_NAME,
      payload: companyName
  }),
  setPhonenumber: (phone) => dispatch({
    type: B2BCreateType.B2B_CREATE_SET_PHONENUMBER,
    payload: phone
  }),
  setEmail: (email) => dispatch({
    type: B2BCreateType.B2B_CREATE_SET_EMAIL,
    payload: email
  }),
  setLocation: (location) => dispatch({
    type: B2BCreateType.B2B_CREATE_SET_LOCATION,
    payload: location
  }),
  setTVANumber: (tvaNumber) => dispatch({
    type: B2BCreateType.B2B_CREATE_SET_TVA_NUMBER,
    payload: tvaNumber
  })
})

export default connect(mapStateToProps, mapDispatchToProps)(B2BInfos);
