import $ from 'jquery';

import env from '../../env';

class Conversation {
    setToken(token) {
        this.token = token;
        return this;
    }

    findAll(){
        return new Promise((resolve, reject) => {
            $.ajax(env.backendUrl + "api/v1/conversations", {
                crossDomain: true,
                headers: {
                    "Authorization": "Bearer " + this.token
                }
            }).done(data => resolve(data)).catch(err => console.error("Une erreur est survenue : ", err));
        })
    }

    create(title, participants) {
        return new Promise((resolve, reject) => {
            $.ajax( env.backendUrl + "api/v1/conversations", {
                method: 'POST',
                crossDomain: true,
                headers: {
                  "Authorization": "Bearer " + this.token
                },
                data: {
                    title,
                    participants
                }
              }).done(data => resolve(data)).catch(err => console.error("Une erreur est survenue : ", err));
        })
    }

    leave(id) {
        return new Promise((resolve, reject) => {
            $.ajax(env.backendUrl + "api/v1/conversations/"+id+"/leave", {
                method: "POST",
                crossDomain: true,
                headers: {
                    "Authorization": "Bearer " + this.token
                }
            }).done(data => resolve(data)).catch(err => console.error("Une erreur est survenue : ", err));
        })
    }

    sendMessage(id, message) {
        return new Promise((resolve, reject) => {
            $.ajax( env.backendUrl + "api/v1/conversations/"+id+"/messages", {
                method: "POST",
                crossDomain: true,
                headers: {
                    "Authorization": "Bearer " + this.token
                },
                data: {
                    message
                }
            }).done(data => resolve(data)).catch(err => console.error("Une erreur est survenue : ", err));
        })
    }
}

export default Conversation;