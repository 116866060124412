import React from 'react';
import PropTypes from "prop-types";
import MaterialIcon from 'material-icons-react';

import './styles.scss';

class Accordion extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      open: false
    }
  }

  render(){
    return (
      <div className={"accordion" + (!this.state.open ? " close" : "")}>
        <div className={"badge" + (!this.props.badgeNumber ? " none" : "")}>{this.props.badgeNumber ? (this.props.badgeNumber > 9 ? "9+" : this.props.badgeNumber) : "0"}</div>
        <div>
          <h1>{this.props.title}</h1>
          <span onClick={()=>this.setState({open: !this.state.open}, () => {if (this.state.open && this.props.onOpen) this.props.onOpen()} )}>
            {this.state.open ? <MaterialIcon icon="keyboard_arrow_up" size={"tiny"} color="#1c6bb1" /> : ""}
            {!this.state.open ? <MaterialIcon icon="keyboard_arrow_down" size={"tiny"} color="#1c6bb1" /> : ""}
          </span>
        </div>
        <div>
          {this.state.open ? this.props.children : ""}
        </div>
      </div>
    );
  }
}

Accordion.contextTypes = {
  t: PropTypes.func.isRequired
}

export default Accordion;
