export const status = {
  RECEIVED: 0,
  ON_THE_WAY: 10,
  IN_PROGRESS: 20,
  FINISHED: 30
}

export const paymentMethod = {
  PAYMENT_SPECIES: 0,
  PAYMENT_CARD: 10,
  PAYMENT_CHECK: 20,
  PAYMENT_TRANSFER: 30
}

export const distanceCalculation = {
  REDUCED_CALCULATION: 0,
  FULL_CALCULATION: 10,
  DEPOSIT_CALCULATION: 20
}

export const problem = {
  BREAKDOWN: 0,
  RUGGED: 10,
  OTHER: 20
}

export const vehicleType = {
  LIGHT_VEHICLE: 0,
  VAN_VEHICLE: 10,
  COMMERCIAL: 20,
  LIGHT_PL: 30,
  MEDIUM_PL: 40,
  HEAVY_PL: 50
}

export const reason = {
  BATTERY: 0,
  PUNCTURE: 10,
  WITHOUT_WHEELS: 20,
  FUEL: 30,
  DOOR_OPENING: 40,
  AIR_PROBLEM: 50,
  OTHER: 60,
  RUGGED_DITCH: 70,
  RUGGED_STUCK: 80,
  RUGGED_BURNED: 90,
  RUGGED_WATER: 100,
  RUGGED_ROOF: 110
}

export const towTruckType = {
  TYPE_QUATRE_X_QUATRE: 0,
  TYPE_PLATEAU_VL: 10,
  TYPE_PLATEAU_PL: 20,
  TYPE_SPL: 30,
  TYPE_GRUE: 40
}
