import React from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import PropTypes from 'prop-types';
import MaterialIcon from 'material-icons-react';
import _ from 'lodash';
import './styles.scss';

import Company from '../../core/Company';
import CompanyType from '../../redux/types/company';

import Template from '../../templates/Backoffice';
import HeaderButtons from '../../components/HeaderButtons';
import Label from '../../components/Label';
import SearchBar from '../../components/SearchBar';
import B2BCompany from '../../core/B2BCompany';

class B2B extends React.Component{

  constructor(props){
      super(props);
      this.state = {
          searchOption: {label: "company_name", action: this.searchCompanyName},
          searchList: undefined,
          list: [],
          loading: true
      }
  }

  searchCompanyName = (companyName) => {
    if (companyName && companyName !== "") {
      new B2BCompany().setToken(this.props.user.token).search(companyName).then(searchList => {
        this.setState({searchList})
      })
    } else {
      this.setState({searchList: undefined})
    }
  }

  componentDidMount(){
    new B2BCompany().setToken(this.props.user.token).findAll().then((list) => {
      this.setState({ list, loading: false })
    })
  }

  _onClickCancel = (id) => {
    new B2BCompany().setToken(this.props.user.token).delete(id).then((response) => {
      console.log("Response : ", response);
    })
  } 

  renderCompany = (elem, i) => {
    return (
      <div className="company" key={i}>
        <MaterialIcon color="rgb(34, 109, 175)" icon="apartment" />
        <div onClick={() => this.props.push("/b2b/" + elem.id)}>
           {elem.company_name}
        </div>
        <span onClick={() => this._onClickCancel(elem.id)}><MaterialIcon color={"rgb(192, 57, 43)"} icon="clear" /></span>
      </div>
    );
  }

  render(){
    return(
      <Template>
        <div className="b2b-page">
          <HeaderButtons tabList={[
            {
              label: this.context.t('list_of_b2b'),
              route: "b2b"
            },
            {
              label: this.context.t("create_b2b"),
              route: "b2b/create"
            }
          ]}
        />
        <SearchBar
          searchOptions={[
              {label: "company_name", action: this.searchCompanyName }
          ]}
          onChooseSearchOption={(option) => this.setState({searchOption: option})}
          getSearchValue={(value) => this.setState({searchValue: value})}
          current={this.state.searchOption}
        />
        {
          this.state.loading && <Label value={this.context.t('loading')} />
        }
        {
          !this.state.loading && !this.state.searchList && this.state.list.length > 0 && this.state.list.map(this.renderCompany)
        }
        {
          !this.state.loading && !this.state.searchList && this.state.list.length === 0 && <Label value={this.context.t('no_b2b_company')} />
        }
        {
          !this.state.loading && this.state.searchList && this.state.searchList.length > 0 && this.state.searchList.map(this.renderCompany)
        }
        {
          !this.state.loading && this.state.searchList && this.state.searchList.length === 0 && <Label value={this.context.t('no_company_in_search')} />
        }
        {/* <SearchBar
          searchOptions={[
              {label: "company_name", action: this.searchCompanyName}
          ]}
          onChooseSearchOption={(option) => this.setState({searchOption: option})}
          getSearchValue={(value) => this.setState({searchValue: value})}
          current={this.state.searchOption}
        />
        {
          this.props.companies === undefined ?
            <Label value={this.context.t('loading')} /> :
            this.props.companies.length === 0 ? <Label value={this.context.t('no_companie')} /> : this.showCompaniesList()
        } */}
        </div>
      </Template>
    );
  }
}

B2B.contextTypes = {
  t: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
  user: state.currentUser
})
const mapDispatchToProps = (dispatch) => ({
  push: (route) => dispatch(push(route))
})

export default connect(mapStateToProps, mapDispatchToProps)(B2B);
