import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import MaterialIcon from 'material-icons-react';

import './styles.scss';

import Label from '../../components/Label';
import Button from '../../components/Buttons/SubmitButton';
import TowTruck from '../../core/TowTruck';
import TowTruckType from '../../redux/types/towtruck';

import { vehicleType } from '../../constants';

import Template from '../../templates/Backoffice';
import DetailsView from '../../templates/Details';

class VehicleDetails extends React.Component{

  constructor(props){
    super(props);
    this.state = {
      id: undefined,
    }
  }

  componentDidMount(){
      if (this.props.towtruck) {
          this.setState(this.props.towtruck);
      } else {
          new TowTruck().setToken(this.props.token).findOne(this.props.id).then(towtruck => {
              if (towtruck) {
                  this.setState(towtruck);
              } else {
                  this.props.push("/vehicle");
              }
          })
      }
  }

  remove = () => {
    new TowTruck().setToken(this.props.token).remove(window.location.pathname.split('/')[2]).then(truck => {
      let index = this.props.trucks.findIndex(truck => truck.id === parseInt(window.location.pathname.split('/')[2]));
      this.props.updateTowTruckList(index);
      this.props.push("/vehicle");
    })
  }

  getSupportedVehicles(){
    let string = "", i = 0;

    for (i = 0; i < this.state.supported_vehicles.length; i++) {
      if (i > 0 && i < this.state.supported_vehicles.length)
        string += " - ";

      switch (this.state.supported_vehicles[i]) {
        case vehicleType.LIGHT_VEHICLE :
          string += this.context.t('light_vehicle');
          break;
        case vehicleType.VAN_VEHICLE :
          string += this.context.t('van_vehicle');
          break;
        case vehicleType.COMMERCIAL :
          string += this.context.t('commercial_vehicle');
          break;
        case vehicleType.LIGHT_PL :
          string += this.context.t('light_pl');
          break;
        case vehicleType.MEDIUM_PL :
          string += this.context.t('medium_pl');
          break;
        default :
          string += this.context.t('heavy_pl');
          break;
      }
    }

    return string;
  }

  render(){
    if(this.state.id === undefined || this.state.id === null || this.state.id === -1 || isNaN(this.state.id)){
      return(
        <Template>
          <DetailsView isLoading />
        </Template>
      );
    }

    return(
        <Template>
          <DetailsView className="vehicles-details">
            <h2>{this.state.brand + " " + this.state.model}</h2>
            <Label value={this.context.t('basic_infos')} />
            <ul>
              <li>
                <span><MaterialIcon icon="navigate_next" size={"tiny"} color="#1c6bb1" /></span>{this.state.registration}
              </li>
              <li>
                <span><MaterialIcon icon="keyboard_arrow_down" size={"tiny"} color="#1c6bb1" /></span>{this.context.t(this.state.can_do_basements ? "can_do_basements" : "can_not_do_basements")}
              </li>
              <li>
                <span><MaterialIcon icon="commute" size={"tiny"} color="#1c6bb1" /></span>{this.getSupportedVehicles()}
              </li>
            </ul>
            <Label value={this.context.t('company')} />
            <ul>
              <li>
                <span><MaterialIcon icon="room" size={"tiny"} color="#1c6bb1" /></span>{this.state.company.name}
              </li>
              {
                this.state.driver ? <li><span><MaterialIcon icon="person" size={"tiny"} color="#1c6bb1" /></span>{this.state.driver.fullname}</li> : ""
              }
              {
                !this.state.driver ? <li><span><MaterialIcon icon="person_outline" size={"tiny"} color="#1c6bb1" /></span>{this.context.t('no_driver')}</li> : ""
              }
            </ul>
            <Button
              isCancel
              onClick={() => this.remove()}
              label={this.context.t('remove')}
            />
          </DetailsView>
        </Template>
    );
  }
}

VehicleDetails.contextTypes = {
  t: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
  token: state.currentUser.token,
  trucks: state.towtruck.list,
  id: parseInt(window.location.pathname.split('/')[2]),
  towtruck: state.towtruck.list.find(truck => truck.id === parseInt(window.location.pathname.split('/')[2]))
})
const mapDispatchToProps = (dispatch) => ({
  push: (route) => dispatch(push(route)),
  updateTowTruckList: (index) => dispatch({
    type: TowTruckType.TOWTRUCK_REMOVE_ONE,
    payload: index
  })
})

export default connect(mapStateToProps, mapDispatchToProps)(VehicleDetails);
