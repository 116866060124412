import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';

import Button from '../../components/Buttons/FormButton';
import CompanyAutocomplete from '../../components/Autocomplete/companyname';

import './styles.scss';

import OrderType from '../../redux/types/order';

class VehicleInfos extends React.Component{

  constructor(props){
    super(props);
    this.state = {
      dispatch: false,
      drivers: []
    };
  }

  selectDriver = () => {
    if (this.state.dispatch === true && this.props.companyName !== "" && this.state.drivers.length > 0) {
      let tabRes = [this.state.drivers.length%2 === 0 ? this.state.drivers.length/2 : (this.state.drivers.length/2)+1];
      let index = -1;
      for(let i = 0; i < this.state.drivers.length; i++){
        if(i%2 === 0){
          index++;
          tabRes[index] = [];
          tabRes[index].push(this.state.drivers[i])
        } else {
          tabRes[index].push(this.state.drivers[i])
        }
      }
      return (
        <div>
        {
          tabRes.map((parentElem, i) => {
            return (
              <div>
                {
                  tabRes[i].map((elem, j) => {
                    return (
                      <Button
                        label={elem.fullname}
                        selected={this.props.driverIdentifier === elem.id}
                        onClick={() => this.props.setDriver(elem)}
                      />
                    );
                  })
                }
              </div>
            )
          })
        }
        </div>
      );


    }
  }

  getValue = (company) => {
    this.props.setCompany(company);
    this.setState({drivers: company.employes})
  }

  render(){
    return(
      <div className="dispatch">
        <h2>{this.context.t('question_company_dispatch')}</h2>
        <div>
          <Button
            onClick={() => this.setState({dispatch: true})}
            label={this.context.t('yes')}
            selected={this.state.dispatch === true ? true : false}
          />
          <Button
            onClick={() => this.setState({dispatch: false}, () => this.props.setCompany({}))}
            label={this.context.t('no')}
            selected={this.state.dispatch === false ? true : false}
          />
        </div>
        {
          this.state.dispatch ? <CompanyAutocomplete token={this.props.token} getValue={company => this.getValue(company)} priorityValue={this.props.companyName} placeholder={this.context.t('company_name')} /> : ""
        }
      </div>
    )
  }
}

VehicleInfos.contextTypes = {
  t: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
  driverIdentifier: _.get(state, 'order.driverIdentifier', undefined),
  companyName: _.get(state, 'order.company_name', undefined) ? state.order.company_name : "",
  token: state.currentUser.token
})

const mapDispatchToProps = (dispatch) => ({
  setCompany: (company) => dispatch({
    type: OrderType.COMPANY_CREATE_SET_COMPANY,
    payload: {
      id: company.id ? company.id : undefined,
      name: company.name ? company.name : undefined
    }
  })
})

export default connect(mapStateToProps, mapDispatchToProps)(VehicleInfos);
