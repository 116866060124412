const B2BCreateType = {
    B2B_CREATE_SET_COMPANY_NAME: "B2B_CREATE_SET_COMPANY_NAME",
    B2B_CREATE_SET_PHONENUMBER: "B2B_CREATE_SET_PHONENUMBER",
    B2B_CREATE_SET_EMAIL: "B2B_CREATE_SET_EMAIL",
    B2B_CREATE_SET_LOCATION: "B2B_CREATE_SET_LOCATION",
    B2B_CREATE_SET_TVA_NUMBER: "B2B_CREATE_SET_TVA_NUMBER",

    B2B_CREATE_SET_PRICE_VL_ROLLING: "B2B_CREATE_SET_PRICE_VL_ROLLING",
    B2B_CREATE_SET_PRICE_4X4_ROLLING: "B2B_CREATE_SET_PRICE_4X4_ROLLING",
    B2B_CREATE_SET_PRICE_VU_ROLLING: "B2B_CREATE_SET_PRICE_VU_ROLLING",
    B2B_CREATE_SET_PRICE_LIGHT_PL_ROLLING: "B2B_CREATE_SET_PRICE_LIGHT_PL_ROLLING",
    B2B_CREATE_SET_PRICE_MEDIUM_PL_ROLLING: "B2B_CREATE_SET_PRICE_MEDIUM_PL_ROLLING",
    B2B_CREATE_SET_PRICE_HEAVY_PL_ROLLING: "B2B_CREATE_SET_PRICE_HEAVY_PL_ROLLING",

    B2B_CREATE_SET_PRICE_VL_NOT_ROLLING: "B2B_CREATE_SET_PRICE_VL_NOT_ROLLING",
    B2B_CREATE_SET_PRICE_4X4_NOT_ROLLING: "B2B_CREATE_SET_PRICE_4X4_NOT_ROLLING",
    B2B_CREATE_SET_PRICE_VU_NOT_ROLLING: "B2B_CREATE_SET_PRICE_VU_NOT_ROLLING",
    B2B_CREATE_SET_PRICE_LIGHT_PL_NOT_ROLLING: "B2B_CREATE_SET_PRICE_LIGHT_PL_NOT_ROLLING",
    B2B_CREATE_SET_PRICE_MEDIUM_PL_NOT_ROLLING: "B2B_CREATE_SET_PRICE_MEDIUM_PL_NOT_ROLLING",
    B2B_CREATE_SET_PRICE_HEAVY_PL_NOT_ROLLING: "B2B_CREATE_SET_PRICE_HEAVY_PL_NOT_ROLLING",

    B2B_CREATE_SET_PRICE_VL_REDELIVERY: "B2B_CREATE_SET_PRICE_VL_REDELIVERY",
    B2B_CREATE_SET_PRICE_4X4_REDELIVERY: "B2B_CREATE_SET_PRICE_4X4_REDELIVERY",
    B2B_CREATE_SET_PRICE_VU_REDELIVERY: "B2B_CREATE_SET_PRICE_VU_REDELIVERY",
    B2B_CREATE_SET_PRICE_LIGHT_PL_REDELIVERY: "B2B_CREATE_SET_PRICE_LIGHT_PL_REDELIVERY",
    B2B_CREATE_SET_PRICE_MEDIUM_PL_REDELIVERY: "B2B_CREATE_SET_PRICE_MEDIUM_PL_REDELIVERY",
    B2B_CREATE_SET_PRICE_HEAVY_PL_REDELIVERY: "B2B_CREATE_SET_PRICE_HEAVY_PL_REDELIVERY",

    B2B_CREATE_SET_PRICE_POLICE_STATION: "B2B_CREATE_SET_PRICE_POLICE_STATION",
    B2B_CREATE_SET_PRICE_BASEMENT: "B2B_CREATE_SET_PRICE_BASEMENT",
    B2B_CREATE_SET_PRICE_HANDLING: "B2B_CREATE_SET_PRICE_HANDLING",
    B2B_CREATE_SET_PRICE_ADVANCE_OF_COST: "B2B_CREATE_SET_PRICE_ADVANCE_OF_COST",
    B2B_CREATE_SET_PRICE_CLEANING: "B2B_CREATE_SET_PRICE_CLEANING",

    B2B_CREATE_SET_DISTANCE_MODE_FULL: "B2B_CREATE_SET_DISTANCE_MODE_FULL",
    B2B_CREATE_SET_KM_INCLUDED: "B2B_CREATE_SET_KM_INCLUDED",

    B2B_CREATE_RESET_STATE: "B2B_CREATE_RESET_STATE"
}
  
export default B2BCreateType;