import React from 'react';
import PropTypes from "prop-types";

import Input from "../Input";
import Company from '../../core/Company';

import './styles.scss';

class Autocomplete extends React.Component{

  constructor(props){
    super(props);
    this.state = {
      value: this.props.valueText !== undefined || this.props.valueText !== null ? this.props.valueText : "",
      companies: []
    }
  }

  handleOnClickSuggestion = (elem) => {
    this.props.getValue(elem);
    this.setState({visible: false, value: elem});
  }

  componentDidUpdate(prevProps) {
    if(this.props.priorityValue !== prevProps.priorityValue){
      this.setState({value: this.props.priorityValue});
    }
  }

  onChangeValue = (value) => {
    // this.props.getValue(value);
    this.setState({visible: true}, () => {
      if(value.length > 0){
        new Company().setToken(this.props.token ? this.props.token : "").findByName(value).then(data => {
          this.setState({companies: data})
        })
      }
      else {
        this.setState({visible: false, companies: []});
      }
    })
  }

  render(){
    var companies = this.state.companies;
    let value = "";

    if(this.props.priorityValue !== undefined && this.props.priorityValue !== null && this.props.priorityValue !== ""){
      value = this.props.priorityValue;
    }
    else{
      value = this.props.defaultValue && this.state.value === ""  ? this.props.defaultValue : this.state.value
    }
    return(
      <div className="autocomplete-container">
        <Input
          priorityValue={value}
          placeholder={this.props.placeholder}
          getValue={this.onChangeValue}
          autoComplete={false}
          error={this.props.error ? true : false}
        />
        <div>
          <ul>
            {
              companies.map((elem, i) => (
                this.state.visible === true ? <li key={i} onClick={(e) => this.handleOnClickSuggestion(elem)}>{elem.name}</li> : ""
              ))
            }
          </ul>
        </div>
      </div>
    );
  }
}

Autocomplete.contextTypes = {
  t: PropTypes.func.isRequired
}

export default Autocomplete;
