import OrderListType from '../types/order_list.js';
import { status } from '../../constants';

export default (state = {orders: [], orders_not_finished: [], page: 0, refresh: true}, action) => {
  switch (action.type){
    case OrderListType.ORDERLIST_UPDATE_LIST:
      return {
        ...state,
        page: 0,
        refresh: action.payload.length === 10 ? true : false,
        orders: action.payload
      }
    case OrderListType.ORDERLIST_UPDATE_NOT_FINISHED_LIST:
      return {
        ...state,
        orders_not_finished: action.payload
      }
    case OrderListType.ORDERLIST_UPDATE_AN_ORDER:
        try {
            if (action.payload.id) {
              let orders = state.orders;
              let result_not_finished = state.orders_not_finished;
              let orderIndex = orders.findIndex(order => order.id === action.payload.id);

              if (orderIndex >= 0) orders[orderIndex] = action.payload;

              orders = orders.filter(order => !order.deleted);

              if (action.payload.status < status.FINISHED && !action.payload.cancelled && !action.payload.deleted) {
                  let orderNFIndex = result_not_finished.findIndex(order => order.id === action.payload.id);

                  if (orderNFIndex >= 0) result_not_finished[orderNFIndex] = action.payload;
                  else result_not_finished.push(action.payload);

              } else {
                  result_not_finished = result_not_finished.filter(order => order.id !== action.payload.id);
              }

              return {
                  ...state,
                  orders: orders,
                  orders_not_finished: result_not_finished
              }
            } else {
              return state;
            }
        } catch (e) {
            return state;
        }

    case OrderListType.ORDERLIST_ADD_ORDERS_TO_THE_LIST:
      return {
        ...state,
        orders: [
          ...state.orders,
          ...action.payload
        ]
      }
    case OrderListType.ORDERLIST_ADD_ORDER:
      if(!state.orders.find(order => order.id === action.payload.id)){
        return {
          ...state,
          orders_not_finished: [
            action.payload,
            ...state.orders_not_finished
          ],
          orders: [
            action.payload,
            ...state.orders
          ]
        }
      } else {
        return state;
      }
    case OrderListType.ORDERLIST_UPDATE_PAGE_REFRESH:
      return {
        ...state,
        page: (action.payload.page !== undefined ? action.payload.page : state.page),
        refresh: (action.payload.refresh !== undefined ? action.payload.refresh : state.refresh)
      }
    case OrderListType.ORDERLIST_UPDATE_SEARCH_LIST:
      return {
        ...state,
        searchOrders: (action.payload ? action.payload : undefined)
      }
    default:
      return {
        ...state,
      }
  }
}
