import React from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import PropTypes from 'prop-types';
import MaterialIcon from 'material-icons-react';
import _ from 'lodash';
import './styles.scss';

import Company from '../../core/Company';
import CompanyType from '../../redux/types/company';

import Template from '../../templates/Backoffice';
import HeaderButtons from '../../components/HeaderButtons';
import Label from '../../components/Label';
import SearchBar from '../../components/SearchBar';
import B2BCompany from '../../core/B2BCompany';
import Bill from '../../core/Bill';
import moment from 'moment';

class Bills extends React.Component{

  constructor(props){
      super(props);
      this.state = {
          searchOption: {label: "bill_number", action: this.searchBillNumber },
          searchList: undefined,
          list: [],
          loading: true
      }
  }

  componentDidMount(){
    new Bill().setToken(this.props.user.token).findAll().then(list => this.setState({ list, loading: false }))
  }

  searchBillNumber = (billNumber) => {
    if (billNumber !== "" && billNumber !== null && billNumber !== undefined) {
      new Bill().setToken(this.props.user.token).searchByBillNumber(billNumber).then(searchList => this.setState({ searchList }))
    } else {
      this.setState({ searchList: undefined })
    }
  }

  renderBill = (bill, i) => {
    let paid = false;
    if (bill.paid) {
      paid = true;
    } else if (bill.order_history) {
      let amountUn = Math.round(bill.order_history.final_price*1.2*100)/100 + bill.order_history.advance_of_cost;
      let amountDeux = Math.round(bill.order_history.last_amount_paid*1.2*100)/100;
      if (amountUn-amountDeux <= 0.01 || amountDeux-amountUn <= 0.01) {
        paid = true;
      }
    }  
    return (
      <div className="bill" key={i}>
                <MaterialIcon color="rgb(34, 109, 175)" icon="receipt_long" />
                <div onClick={() => this.props.push("/bills/"+bill.bill_number)}>
                  Facture {bill.bill_number} le {moment(bill.created_at).format("D/MM/y")} {bill.credits.length > 0 ? (bill.credits.length === 1 ? "(1 avoir)" : "("+bill.credits.length+" avoirs)") : ""}
                </div>
                <div>
                  { 
                    paid && (
                      <MaterialIcon color="rgb(34, 109, 175)" icon="check" />
                    )
                  }
                  
                </div>
              </div>
            )
  }

  render(){
    return(
      <Template>
        <div className="bills-page">
          <HeaderButtons tabList={[
            {
              label: this.context.t('list_of_bills'),
              route: "bills"
            },
            {
              label: this.context.t("create_bill"),
              route: "bills/create"
            }
          ]}
        />
        
        <SearchBar
          searchOptions={[
              {label: "bill_number", action: this.searchBillNumber }
          ]}
          onChooseSearchOption={(option) => this.setState({searchOption: option})}
          getSearchValue={(value) => this.setState({searchValue: value})}
          current={this.state.searchOption}
        />
        {
          this.state.loading && !this.state.searchList && <Label value={this.context.t('loading')} />
        }
        {
          !this.state.loading && this.state.list.length === 0 && !this.state.searchList && <Label value="Aucune facture" />
        }
        {
          !this.state.loading && !this.state.searchList && this.state.list.map(this.renderBill)
        }


        </div>
      </Template>
    );
  }
}

Bills.contextTypes = {
  t: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
  user: state.currentUser
})
const mapDispatchToProps = (dispatch) => ({
  push: (route) => dispatch(push(route))
})

export default connect(mapStateToProps, mapDispatchToProps)(Bills);
