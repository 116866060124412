import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Button from '../Buttons/SubmitButton';

import { paymentMethod } from '../../constants';

import './styles.scss';

class Modal extends Component {

  generateText(){
    var reasonTrans = (this.props.order.reason === 0 ? "battery" : (this.props.order.reason === 10 ? "puncture" : (this.props.order.reason === 20 ? "without_wheels" : (this.props.order.reason === 30 ? "fuel" : (this.props.order.reason === 40 ? "door_opening" : (this.props.order.reason === 50 ? "air_problem" : (this.props.order.reason === 60 ? "other_problem" : (this.props.order.reason === 70 ? "rugged_ditch" : (this.props.order.reason === 80 ? "rugged_stuck" : (this.props.order.reason === 90 ? "rugged_burned" : (this.props.order.reason === 100 ? "rugged_water" : "rugged_roof")))))))))))
    return (
      <p>
        Nouvelle intervention pour un véhicule <strong>{this.props.order.vehicle_brand + " " + this.props.order.vehicle_model}</strong>.
        Il est {this.props.order.problem === 0 ? "en " : ""} <strong>{(this.props.order.problem === 0 ? "panne (" : "accidenté (") + this.context.t(reasonTrans).toLowerCase() + ")"}</strong>
        {
          this.props.order.limited_height ? ". Le véhicule se trouve en " : ""
        }
        {
          this.props.order.limited_height ? <strong>sous-sol</strong> : ""
        }
        .
        {
          this.props.order.distance === 0 ? " Il s'agit d'un dépannage sur place à " : " Il s'agit d'un remorquage au départ de "
        }
        <strong>{this.props.order.departure_address.split(",")[1].replace(/([0-9])*/g, '').trim()}</strong>
        {
          this.props.order.distance === 0 ? ". Le client" : " pour arriver à "
        }
        {
          this.props.order.distance === 0 ? "" : <strong>{this.props.order.arrival_address.split(",")[1].replace(/([0-9])*/g, '').trim() + ' (' + Math.round(this.props.order.distance)/1000 + 'km)'}</strong>
        }
        {
          this.props.order.distance === 0 ? "" : ". Le client"
        }

        {
          this.props.order.paid === true ? <strong> a déjà été prélevé</strong> : <strong> payera sur place</strong>
        }
        {
          this.props.order.payment_method === paymentMethod.PAYMENT_CARD && this.props.order.paid === false ? <strong> par carte bancaire</strong> : ""
        }
        {
          this.props.order.payment_method === paymentMethod.PAYMENT_SPECIES && this.props.order.paid === false ? <strong> en espèce</strong> : ""
        }
        {
          this.props.order.payment_method === undefined ? <strong> (moyen de payement inconnu pour le moment)</strong> : ""
        }
        .
      </p>
    )
  }

  render(){
    if (!this.props.order) {
      return (<div></div>);
    }

    return (
      <div className="modal">
        <div>
          <h1>Intervention à {this.props.order.final_price*1.2 + "€ TTC"}</h1>
          { this.generateText() }
          <Button label={this.context.t('confirm')} onClick={this.props.onConfirm}/>
          <span className="cancel" onClick={this.props.onCancel}>{this.context.t('cancel')}</span>
        </div>
      </div>
    );
  }

}

Modal.contextTypes = {
  t: PropTypes.func.isRequired
}

export default Modal;
