import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

import Button from '../../components/Buttons/FormButton';

import { problem, reason } from '../../constants';

import './styles.scss';
import OrderType from '../../redux/types/order';

class ReasonChoice extends React.Component{
  render(){
    if (_.get(this.props, 'order.problem') === problem.RUGGED) {
      return(
        <div>
          <h2>{this.context.t('question_reason')}</h2>
          <div>
            <Button
              onClick={() => this.props.updateOrder(reason.RUGGED_DITCH)}
              label={this.context.t('rugged_ditch')}
              selected={_.get(this.props, 'order.reason') === reason.RUGGED_DITCH ? true : false}
            />
            <Button
              onClick={() => this.props.updateOrder(reason.RUGGED_STUCK)}
              label={this.context.t('rugged_stuck')}
              selected={_.get(this.props, 'order.reason') === reason.RUGGED_STUCK ? true : false}
            />
          </div>
          <div>
            <Button
              onClick={() => this.props.updateOrder(reason.RUGGED_BURNED)}
              label={this.context.t('rugged_burned')}
              selected={_.get(this.props, 'order.reason') === reason.RUGGED_BURNED ? true : false}
            />
            <Button
              onClick={() => this.props.updateOrder(reason.RUGGED_WATER)}
              label={this.context.t('rugged_water')}
              selected={_.get(this.props, 'order.reason') === reason.RUGGED_WATER ? true : false}
            />
          </div>
          <div>
            <Button
              onClick={() => this.props.updateOrder(reason.RUGGED_ROOF)}
              label={this.context.t('rugged_roof')}
              selected={_.get(this.props, 'order.reason') === reason.RUGGED_ROOF ? true : false}
            />
            <Button
              onClick={() => this.props.updateOrder(reason.OTHER)}
              label={this.context.t('other_rugged')}
              selected={_.get(this.props, 'order.reason') === reason.OTHER ? true : false}
            />
          </div>
        </div>
      );
    }
    else{
      return(
        <div>
          <h2>{this.context.t('question_reason')}</h2>
          <div>
            <Button
              onClick={() => this.props.updateOrder(reason.BATTERY)}
              label={this.context.t('battery')}
              selected={_.get(this.props, 'order.reason') === reason.BATTERY ? true : false}
            />
            <Button
              onClick={() => this.props.updateOrder(reason.PUNCTURE)}
              label={this.context.t('puncture')}
              selected={_.get(this.props, 'order.reason') === reason.PUNCTURE ? true : false}
            />
          </div>
          <div>
            <Button
              onClick={() => this.props.updateOrder(reason.WITHOUT_WHEELS)}
              label={this.context.t('without_wheels')}
              selected={_.get(this.props, 'order.reason') === reason.WITHOUT_WHEELS ? true : false}
            />
            <Button
              onClick={() => this.props.updateOrder(reason.FUEL)}
              label={this.context.t('fuel')}
              selected={_.get(this.props, 'order.reason') === reason.FUEL ? true : false}
            />
          </div>
          <div>
            <Button
              onClick={() => this.props.updateOrder(reason.DOOR_OPENING)}
              label={this.context.t('door_opening')}
              selected={_.get(this.props, 'order.reason') === reason.DOOR_OPENING ? true : false}
            />
            <Button
              onClick={() => this.props.updateOrder(reason.AIR_PROBLEM)}
              label={this.context.t('air_problem')}
              selected={_.get(this.props, 'order.reason') === reason.AIR_PROBLEM ? true : false}
            />
          </div>
          <div className="one">
            <Button
              onClick={() => this.props.updateOrder(reason.OTHER)}
              label={this.context.t('other_problem')}
              selected={_.get(this.props, 'order.reason') === reason.OTHER ? true : false}
            />
          </div>
        </div>
      );
    }
  }
}

ReasonChoice.contextTypes = {
  t: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
  order: state.order,
  user: state.currentUser
})

const mapDispatchToProps = (dispatch) => ({
  updateOrder: (choice) => dispatch({
    type: OrderType.ORDER_SELECT_REASON,
    payload: {reason: choice}
  }),
  push: (route) => dispatch(push(route))
})

export default connect(mapStateToProps, mapDispatchToProps)(ReasonChoice);
