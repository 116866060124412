import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { push } from 'react-router-redux'
import './styles.scss';

import env from '../../env';

import Auth from '../../core/Auth';
import SoundFile from "../../assets/sounds/alerte.wav";
import IconFile from "../../assets/images/favicon.png";

import Input from '../../components/Input';
import Image from '../../components/Image';
import LoginButton from '../../components/Buttons/LoginButton';
import Label from '../../components/Label';

import UserTypes from '../../redux/types/user';
import Conversation from '../../core/Conversation';
import ConversationsType from '../../redux/types/conversations';

class Login extends React.Component{

  constructor(props){
    super(props);
    this.state = {
      login: undefined,
      password: undefined,
      splashScreen: true
    }
  }

  componentDidMount(){
      let token = localStorage.getItem('depannmoi_user_token');
      if (token) {
          new Auth().setToken(token).getUser().then(userData => {
              new Conversation().setToken(userData.token).findAll().then(conversations => {
                this.props.setToken(userData.token);
                this.props.setConversations(conversations)
                this.props.setUserData(userData.user);
              });
              this.props.push('/');
          }).catch(err => {
              localStorage.removeItem('depannmoi_user_token');
              this.setState({splashScreen: false});
          })
      } else {
          localStorage.removeItem('depannmoi_user_token');
          this.setState({splashScreen: false});
      }
  }

  handleOnClickConnexion = () => {
    let isError = false;
    let error = {}
    if(_.get(this.state, 'login', undefined) === "" || _.get(this.state, 'login', undefined) === undefined){
      error.login = this.context.t("form_required");
      isError = true;
    }
    if(_.get(this.state, 'password', undefined) === "" || _.get(this.state, 'password', undefined) === undefined){
      error.password = this.context.t("form_required");
      isError = true;
    }
    if(isError){
      this.setState({error});
      if(env.debug) console.log("Il y a un problème");
    }
    else{
      if(env.debug) console.log("Il n'y a pas de problème");
      new Auth(this.state.login, this.state.password).connexion().then(userData => {
        if(userData){
          this.setState({token: userData.token}, () => {
            localStorage.setItem('depannmoi_user_token', userData.token);

            new Conversation().setToken(userData.token).findAll().then(conversations => {
              this.props.setToken(userData.token);
              this.props.setConversations(conversations);
              this.props.setUserData(userData.user);
            });

            setTimeout(() => {
              new Notification("Dépann'moi", { body: this.context.t('logged_out_in_5_minutes'), icon: IconFile })
              // alert(this.context.t('logged_out_in_5_minutes'));
            }, 4290000)

            setTimeout(() => {
              this.props.resetUser();
              let sound = new Audio(SoundFile);
              let notif = new Notification("Dépann'moi", {body: this.context.t('your_were_logged_out'), icon: IconFile});
              notif.onclose = () => sound.stop();
              sound.play();
              this.props.push('/login')
            }, 4320000);

            this.props.push('/');
          })
        }
      }).catch(err => {
        if(err === 401){
          this.setState({
            error: {
              login: this.context.t("wrong_login"),
              password: this.context.t("wrong_password")
            }
          })
        }
      })
    }
  }

  render(){
      if (this.state.splashScreen) {
          return (
            <div className="login">
                <Image
                  src="/logo.svg"
                  alt={"Logo"}
                  width="60%"
                  margin="auto"
                />
            </div>
          );
      }
    return(
      <div className="login">
        <form>
          <Image
            src="/logo.svg"
            alt={"Logo"}
            width="60%"
            margin="auto"
          />
          {
            _.get(this.state, 'error.login', false) === this.context.t('wrong_login') ? <Label customStyle={{color: "white", background: "red", lineHeight: "30px"}} value={this.context.t('wrong_login')} /> : ""
          }
          <Input
            defaultValue={env.debug ? "alexandre@depannmoi.com" : ""}
            getValue={(value) => this.setState({login: value})}
            error={_.get(this.state, 'error.login', undefined)}
            placeholder={this.context.t('login')}
          />
          <Input
            type="password"
            error={_.get(this.state, 'error.password', undefined)}
            getValue={(value) => this.setState({password: value})}
            placeholder={this.context.t('password')}
          />
          <LoginButton
            onClickSubmit={this.handleOnClickConnexion}
            label="Connexion"
          />
        </form>
      </div>
    );
  }
}

Login.contextTypes = {
  t: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
  user: state.currentuser
})
const mapDispatchToProps = (dispatch) => ({
  setConversations: (conv) => dispatch({
    type: ConversationsType.CONVERSATIONS_SET_LIST,
    payload: conv
  }),
  setUserData: (userData) => {
    dispatch({
      type: UserTypes.USER_SET_DATA,
      payload: userData
    })
  },
  setToken: (token) => {
    dispatch({
      type: UserTypes.USER_LOGIN,
      payload: token
    })
  },
  resetUser: () => {
    dispatch({type: UserTypes.USER_RESET})
  },
  push: (route) => dispatch(push(route))
})

export default connect(mapStateToProps, mapDispatchToProps)(Login);
