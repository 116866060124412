import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import MaterialIcon from 'material-icons-react';
import moment from 'moment'

import './styles.scss';
import Company from '../../core/Company';

import Label from '../../components/Label';
import Input from '../../components/Input';
import Accordion from '../../components/Accordion';

import Template from '../../templates/Backoffice';
import DetailsView from '../../templates/Details';

import LeftColumn from './left';
import RightColumn from './right';
import Conversation from '../../core/Conversation';
 
class Conversations extends React.Component{

  constructor(props){
    super(props);
    this.state = { 
      participants: [],
      active: 0
    }
  }

  componentDidMount(){
    // console.log(this.props.conversations)
    if (!this.props.moreThanOneEmploye) {
      this.props.push("/");
    }
  }

  render(){
    return(
        <Template>
          <DetailsView className="messages">
            <LeftColumn active={this.state.active} onChangeActive={active => this.setState({ active })} />
            <RightColumn creation={this.state.active === 0} conversation={this.props.conversations.find(c => c.id === this.state.active)} onChangeActive={active => this.setState({ active })} />
          </DetailsView>
        </Template>
    );
  }
}

Conversations.contextTypes = {
  t: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
  user: state.currentUser,
  conversations: _.get(state, 'messages.conversations', []),
  moreThanOneEmploye: _.get(state, "currentUser.user.company.employes.length", 1) > 1
})
const mapDispatchToProps = (dispatch) => ({
  push: (route) => dispatch(push(route))
})

export default connect(mapStateToProps, mapDispatchToProps)(Conversations);
