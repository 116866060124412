import UserTypes from '../types/user.js';

export default (state = {}, action) => {
  switch (action.type){
    case UserTypes.USER_LOGIN:
      return {
        ...state,
        token: action.payload
      }
    case UserTypes.USER_SET_DATA:
      return {
        ...state,
        user: action.payload
      }
    case UserTypes.USER_RESET:
      return {}
    default:
      return {
        ...state
      }
  }
}
