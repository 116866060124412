import React from 'react';
import _ from 'lodash';

import './styles.scss';

class FormButton extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      selected: _.get(this.props, 'selected', false)
    }
  }

  render(){
    return(
      <button
        className="common-submitbutton"
        onClick={() => this.props.onClick()}
        style={{background: (this.props.isCancel ? "#c0392b" : "#27ae60"), color: (this.props.isCancel ? "white" : "white"), border: (this.props.isCancel ? "1px solid #c0392b" : "1px solid #27ae60"), ...this.props.style}}
      >
        {this.props.label}
      </button>
    );
  }
}

export default FormButton;
