import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';

import Button from '../../components/Buttons/FormButton';

import { problem } from '../../constants';

import './styles.scss';
import OrderType from '../../redux/types/order';

class Problem extends React.Component{

  constructor(props){
    super(props);
    this.state = {};
  }

  render(){
      return(
        <div>
          <h2>{this.context.t('question_problem')}</h2>
          <div>
            <Button
              onClick={() => this.props.selectProblem(problem.BREAKDOWN)}
              label={this.context.t('breakdown')}
              selected={_.get(this.props, 'order.problem') === problem.BREAKDOWN ? true : false}
            />
            <Button
              onClick={() => this.props.selectProblem(problem.RUGGED)}
              label={this.context.t('rugged')}
              selected={_.get(this.props, 'order.problem') === problem.RUGGED ? true : false}
            />
          </div>
        </div>
      );
    }
}

Problem.contextTypes = {
  t: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
  order: state.order
})

const mapDispatchToProps = (dispatch) => ({
  selectProblem: (problem) => dispatch({
    type: OrderType.ORDER_SELECT_PROBLEM,
    payload: {
      problem: problem
    }
  })
})

export default connect(mapStateToProps, mapDispatchToProps)(Problem);
