import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import './styles.scss';

import Order from '../../core/Order';
import TowTruck from '../../core/TowTruck';
import Vehicle from '../../core/Vehicle';

import TowTruckType from '../../redux/types/towtruck';
import OrderListType from '../../redux/types/order_list';
import VehicleToUpdateType from '../../redux/types/vehicles_to_update';

import Template from '../../templates/Backoffice';
import Map from '../../components/Map';

class Home extends React.Component{

  componentDidMount(){
    if(_.get(this.props, 'user.token', undefined) !== undefined){
      this.props.updateTowTruckList(this.props.user.token);
      this.props.updateOrderList(this.props.user.token);
      this.props.updateOrderListNotFinished(this.props.user.token);
      this.props.updateVehicleToUpdateList(this.props.user.token);
    }
  }

  render(){
    return(
      <Template>
        <Map
          orderList={this.props.orderList ? this.props.orderList : []}
          onClickOrderMarker={(id) => this.props.push('/order/' + id)}
        />
      </Template>
    );
  }
}

Home.contextTypes = {
  t: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
  orderList: state.orderList.orders_not_finished,
  user: state.currentUser
})
const mapDispatchToProps = (dispatch) => ({
  push: (route) => dispatch(push(route)),
  updateTowTruckList: (token) => {
    new TowTruck({}).setToken(token).findAll().then(data => {
      dispatch({
        type: TowTruckType.TOWTRUCK_UPDATE_LIST,
        payload: data
      })
    });
  },
  updateOrderList: (token) => {
    new Order().setToken(token).findAll().then(orders => {
      dispatch({
        type: OrderListType.ORDERLIST_UPDATE_LIST,
        payload: orders
      })
    })
  },
  updateOrderListNotFinished: (token) => {
    new Order().setToken(token).findAllNotFinished().then(orders => {
      dispatch({
        type: OrderListType.ORDERLIST_UPDATE_NOT_FINISHED_LIST,
        payload: orders
      })
    })
  },
  updateVehicleToUpdateList: (token) => {
    new Vehicle().setToken(token).findVehiclesToBeUpdated().then(vehicles => {
      dispatch({
        type: VehicleToUpdateType.SET_VEHICLES_TO_UPDATE_LIST,
        payload: vehicles
      })
    })
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(Home);
