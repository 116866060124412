import React from 'react';
import PropTypes from 'prop-types';
import _, { times } from 'lodash';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import MaterialIcon from 'material-icons-react';

import './styles.scss';
import { status, vehicleType, paymentMethod } from '../../constants';
import OrderListType from '../../redux/types/order_list';

import Label from '../../components/Label';
import Button from '../../components/Buttons/SubmitButton';
import FormButton from '../../components/Buttons/FormButton';
import SubmitButton from '../../components/Buttons/SubmitButton';
import CompanyAutocomplete from '../../components/Autocomplete/companyname';
import Input from '../../components/Input';

import Order from '../../core/Order';
import Company from '../../core/Company';

import Template from '../../templates/Backoffice';
import DetailsView from '../../templates/Details';
import env from '../../env';

class OrderDetails extends React.Component{

  constructor(props){
    super(props);
    this.state = {
      id: undefined,
      companyConfirmed: true,
      tolls: 0,
      tmpData: {
        drivers: []
      },
      loading: true,
      update: {
        vehicleRegistration: false,
        createCredit: false,
        creditAmount: 0
      }
    }
  }

  componentDidUpdate(prevProps){
      if (prevProps.order && !this.props.order) {
          this.props.push("/order");
      } else if (!this.state.update.vehicleRegistrationValue) {
        this.setState({
          update: {
            ...this.state.update,
            vehicleRegistrationValue: this.props.order.vehicle_registration
          }
        })
      }
  }

  componentWillUnmount(){
    if (this.props.order && this.props.order.id) {
      new Order({}).setToken(this.props.user.token).generateNewBill(this.props.order.id).then(this.props.updateAnOrder)
    }
  }

  addHourPoliceStation = () => {
    new Order({}).setToken(this.props.user.token)
      .updateHourPoliceStation(this.props.order.id, this.props.order.waiting_time_police + 1)
  }
  subtractHourPoliceStation = () => {
    new Order({}).setToken(this.props.user.token)
      .updateHourPoliceStation(this.props.order.id, this.props.order.waiting_time_police - 1)
  }
  addHourHandling = () => {
    new Order({}).setToken(this.props.user.token)
      .updateHourHandling(this.props.order.id, (this.props.order.hour_handling ? this.props.order.hour_handling : 0) + 1)
  }
  subtractHourHandling = () => {
    new Order({}).setToken(this.props.user.token)
      .updateHourHandling(this.props.order.id, (this.props.order.hour_handling ? this.props.order.hour_handling : 0) - 1)
  }
  addAdditionalDisplacement = () => {
    new Order({}).setToken(this.props.user.token)
      .updateAdditionalDisplacement(this.props.order.id, (this.props.order.additional_displacement ? this.props.order.additional_displacement : 0) + 1)
  }
  subtractAdditionalDisplacement = () => {
    new Order({}).setToken(this.props.user.token)
      .updateAdditionalDisplacement(this.props.order.id, (this.props.order.additional_displacement ? this.props.order.additional_displacement : 0) - 1)
  }
  addCleaning = () => {
    new Order({}).setToken(this.props.user.token)
      .updateCleaning(this.props.order.id, 1)
  }
  removeCleaning = () => {
    new Order({}).setToken(this.props.user.token)
      .updateCleaning(this.props.order.id, 0)
  }

  changeVehicleRegistration = () => {
    new Order({}).setToken(this.props.user.token)
      .changeVehicleRegistration(this.props.order.id, this.state.update.vehicleRegistrationValue)
      .then(order => {
        this.setState({ update: {
          ...this.state.update,
          vehicleRegistration: false,
          vehicleRegistrationValue: order.vehicle_registration
        }}, () => this.props.updateAnOrder(order))
      })
  }

  onClickDownloadBill = (bill_number = this.props.order.bill_number) => {
    new Order({}).setToken(this.props.user.token).downloadBill(this.props.order.id, bill_number).then(url => {
      let date = new Date(this.props.order.wished_intervention_date);
      let date_string = date.getUTCFullYear() + "" + (date.getMonth() < 10 ? "0"+(date.getMonth()+1):date.getMonth()+1) + "" + (date.getDate() < 10 ? "0"+date.getDate(): date.getDate())
      let file = document.createElement("a");
      file.setAttribute("href", url);
      let fileName = date_string + " . FACTURE " + bill_number + " ";
      if (this.props.order.b2_bcompany) {
        fileName += this.props.order.b2_bcompany.company_name.toUpperCase() + " . ";
      } else {
        fileName += this.props.order.name.toUpperCase() + " " + this.props.order.firstname.toUpperCase() + " . ";
      }
      fileName += "REMORQUAGE " + this.props.order.vehicle_registration;
      file.setAttribute("download", fileName+".pdf");
      file.click();
    });
  }

  onClickDownloadCredit = (credit_number) => {
    if (credit_number) {
      new Order({}).setToken(this.props.user.token).downloadCredit(this.props.order.id, credit_number).then(url => {
        let date = new Date(this.props.order.wished_intervention_date);
        let date_string = date.getUTCFullYear() + "" + (date.getMonth() < 10 ? "0"+(date.getMonth()+1):date.getMonth()+1) + "" + (date.getDate() < 10 ? "0"+date.getDate(): date.getDate())
        let file = document.createElement("a");
        file.setAttribute("href", url);
        let fileName = date_string + " . AVOIR " + credit_number + " ";
        if (this.props.order.b2_bcompany) {
          fileName += this.props.order.b2_bcompany.company_name.toUpperCase() + " . ";
        } else {
          fileName += this.props.order.name.toUpperCase() + " " + this.props.order.firstname.toUpperCase() + " . ";
        }
        fileName += "REMORQUAGE " + this.props.order.vehicle_registration;
        file.setAttribute("download", fileName+".pdf");
        file.click();
      });
    }
  }

  componentDidMount(){
      try {
          if(_.get(this.props, 'user.token', undefined) !== undefined){
            let order = { ...this.state, ...this.props.order };
            if (this.props.order.company && this.props.order.company.id && !this.props.order.driver) {
              new Company().setToken(this.props.user.token).getEmployes(this.props.order.company.id).then(employes => {
                order.company = {
                    ...this.props.order.company,
                    employes: employes
                };
                this.setState({...order, loading: false});
              })
            }
            else if (this.props.order.company && this.props.order.company.id && this.props.order.company.employes) {
              order.company = {
                ...this.props.order.company
              }
              this.setState({...order, loading: false});
            } else {
              this.setState({...order, loading: false})
            }
          }
      } catch (e) {
          new Order({}).setToken(this.props.user.token).findOne(this.props.params.id).then(order => {
              this.setState({...order, loading: false})
          })
      }
  }

  onClickDispatchToCompany = () => {
    this.setState({loading: true}, () => {
      new Order({}).setToken(this.props.user.token).dispatchOrderToCompany(this.props.order.id, this.state.company.id).then(() => {
        new Company().setToken(this.props.user.token).getEmployes(this.state.company.id).then(employes => {
          let order = { ...this.state, ...this.props.order };
          order.company = {
              ...this.props.order.company,
              employes: employes
          };
          this.setState({...order, loading: false});
        })
      });
      this.setState({companyConfirmed: true});
    })
  }

  onClickDispatchToDriver = () => {
    new Order({}).setToken(this.props.user.token).dispatchOrderToDriver(this.props.order.id, this.state.driver.id);
  }

  selectDriver = () => {
    if (this.state.loading) {
      return (<div><Label value={this.context.t('loading')} /></div>)
    }

    let employes = _.get(this.props, 'order.company.employes', []);
    employes = employes.filter((employe) => employe.available === true);
    let tabRes = employes.length > 0 ? [employes.length%2 === 0 ? employes.length/2 : (employes.length/2)+1] : [];
    let index = -1;
    for(let i = 0; i < employes.length; i++){
      if(i%2 === 0){
        index++;
        tabRes[index] = [];
        tabRes[index].push(employes[i])
      } else {
        tabRes[index].push(employes[i])
      }
    }

    return (
      <div>
      {
        tabRes.length > 0 ? tabRes.map((parentElem, i) => {
          return (
            <div key={i}>
              {
                parentElem.map((elem, j) => {
                  return (
                    <FormButton
                      key={j}
                      label={elem.fullname}
                      selected={_.get(this.state, 'driver.id', -1) === elem.id}
                      onClick={() => this.setState({driver: elem}, () => this.onClickDispatchToDriver())}
                    />
                  );
                })
              }
            </div>
          );
        }) : <Label value={this.context.t('no_driver_available')} />
      }
      </div>
    );

  }

  onClickEditStatus = () => {
    if(this.props.order.company && this.props.order.driver){
      if (this.state.status === status.RECEIVED){
        new Order({}).setToken(this.props.user.token).changeStatus(this.props.order.id, status.ON_THE_WAY).then(data => this.setState({status: status.ON_THE_WAY}));
      }
      else if (this.state.status === status.ON_THE_WAY){
        new Order({}).setToken(this.props.user.token).changeStatus(this.props.order.id, status.IN_PROGRESS).then(data => this.setState({status: status.IN_PROGRESS}));
      }
      else if (this.state.status === status.IN_PROGRESS){
        new Order({}).setToken(this.props.user.token).changeStatus(this.props.order.id, status.FINISHED).then(data => this.setState({status: status.FINISHED}));
      }
    }
    else if(this.state.status === status.UNPAID)
      new Order({}).setToken(this.props.user.token).changeStatus(this.props.order.id, status.PAID).then(data => this.setState({status: status.PAID}));
  }

  onClickOrderIsPaid = () => {
    this.setState({paid: true}, () => new Order().setToken(this.props.user.token).orderIsPaid(this.props.order.id));
  }

  showCancelledInformations = () => {
    if (this.props.order.cancelled && this.props.order.paid) {
      if (this.props.order.status >= status.ON_THE_WAY) {
        return (
          <ul>
            <li>
              <span>
                <MaterialIcon icon="assignment" size={"tiny"} color="#1c6bb1" />
              </span>
              { this.props.order.cancelled_by.name + " reçoit 50 € de frais de dossier" }
            </li>
            <li>
              <span>
                <MaterialIcon icon="forward" size={"tiny"} color="#1c6bb1" />
              </span>
              { this.props.order.company.name + " reçoit 99 € de frais de déplacement" }
            </li>
          </ul>
        );
      }
      return (
        <ul>
          <li>
            <span>
              <MaterialIcon icon="assignment" size={"tiny"} color="#1c6bb1" />
            </span>
            { "Intervention annulée par la société " + this.props.order.cancelled_by.name }
          </li>
        </ul>
      );
    }
    return null;
  }

  render(){
    if(this.props.order === undefined || this.props.order.id === undefined || this.props.order.id === null || this.props.order.id === -1 || isNaN(this.props.order.id)){
      return(
        <Template>
          <DetailsView isLoading />
        </Template>
      );
    }

    var about_vehicle_icon = "time_to_leave";
    if(this.props.order.vehicle_type === vehicleType.VAN_VEHICLE || this.props.order.vehicle_type === vehicleType.COMMERCIAL || this.props.order.vehicle_type === vehicleType.LIGHT_PL)
        about_vehicle_icon = "local_shipping";
    else if (this.props.order.vehicle_type === vehicleType.MEDIUM_PL || this.props.order.vehicle_type === vehicleType.HEAVY_PL)
        about_vehicle_icon = "directions_bus";

    var statusTrans = (this.props.order.status === 0 ? "received" : (this.props.order.status === 10 ? "on_the_way" : (this.props.order.status === 20 ? "in_progress" : "finished")));

    var problemTrans = (this.props.order.problem === 0 ? "breakdown" : this.props.order.problem === 10 ? "rugged" : "");
    var reasonTrans = (this.props.order.reason === 0 ? "battery" : (this.props.order.reason === 10 ? "puncture" : (this.props.order.reason === 20 ? "without_wheels" : (this.props.order.reason === 30 ? "fuel" : (this.props.order.reason === 40 ? "door_opening" : (this.props.order.reason === 50 ? "air_problem" : (this.props.order.reason === 60 ? "other_problem" : (this.props.order.reason === 70 ? "rugged_ditch" : (this.props.order.reason === 80 ? "rugged_stuck" : (this.props.order.reason === 90 ? "rugged_burned" : (this.props.order.reason === 100 ? "rugged_water" : "rugged_roof")))))))))))

    var vehicleTypeTrans = (this.props.order.vehicle_type === 0 ? "light_vehicle" : (this.props.order.vehicle_type === 10 ? "van_vehicle" : (this.props.order.vehicle_type === 20 ? "commercial_vehicle" : (this.props.order.vehicle_type === 30 ? "light_pl" : (this.props.order.vehicle_type === 40 ? "medium_pl" : "heavy_pl")))))

    let date = this.props.order.wished_intervention_date.split('T');
    let day = date[0].split("-")[2];
    let month = date[0].split("-")[1];
    let year = date[0].split("-")[0];
    let hours = date[1].split(":")[0];
    let minutes = date[1].split(":")[1];

    let payment;
    if (this.props.order.payment_method === paymentMethod.PAYMENT_CARD) {
      payment = "payment_card";
    } else if (this.props.order.payment_method === paymentMethod.PAYMENT_SPECIES) {
      payment = "payment_species";
    } else if (this.props.order.payment_method === paymentMethod.PAYMENT_CHECK) {
      payment = "payment_check";
    } else {
      payment = "payment_transfer";
    }

    if (env.debug) console.log("Order Details : ", this.props.order);

    return(
        <Template>
          <DetailsView className="order-details">
            <h2>{this.context.t('order_details') + " #" + this.props.order.id}</h2>
            { this.props.order.cancelled ? <Label value={this.context.t('order_is_cancelled')} /> : "" }
            { this.showCancelledInformations() }
            {
              this.props.order.mark && (
                <>
                  <Label value={this.context.t("mark")} />
                  <ul style={{ marginTop: 0 }}>
                    <li style={{ justifyContent: "center", marginTop: 0 }}>
                      <MaterialIcon icon={this.props.order.mark > 0 ? "star" : "star_border"} color="#1c6bb1" size={16} />
                      <MaterialIcon icon={this.props.order.mark > 1 ? "star" : "star_border"} color="#1c6bb1" size={16} />
                      <MaterialIcon icon={this.props.order.mark > 2 ? "star" : "star_border"} color="#1c6bb1" size={16} />
                      <MaterialIcon icon={this.props.order.mark > 3 ? "star" : "star_border"} color="#1c6bb1" size={16} />
                      <MaterialIcon icon={this.props.order.mark > 4 ? "star" : "star_border"} color="#1c6bb1" size={16} />
                    </li>
                  {
                    this.props.order.mark_comment && (
                      <li>
                        <span><MaterialIcon icon="insert_comment" size={"tiny"} color="#1c6bb1" /></span>
                        { this.props.order.mark_comment }
                      </li>
                    )
                  }
                  </ul>
                </>
              )
            }
            { (this.props.order.status === status.FINISHED || this.props.order.cancelled) && (
              <>
                <Label value={this.context.t("bill")} />
                <ul style={{ width: "100%" }}>
                  {
                    this.props.order.bills.map((bill, i) => (
                      <li>
                        <span>
                          <MaterialIcon icon="savings" size={"tiny"} color="#1c6bb1" />
                        </span>
                        <ul>
                          <li>
                            <a onClick={() => this.onClickDownloadBill(bill.bill_number)}>
                              {this.context.t("download bill") + " N° " + bill.bill_number}
                            </a>
                          </li>
                          { bill.credits.map((credit, i) => (
                            <li>
                              <a onClick={() => this.onClickDownloadCredit(credit.credit_number)}>
                                {this.context.t("download credit") + " N° " + credit.credit_number}
                              </a>
                            </li>
                          ))}
                        </ul>
                      </li>
                    ))
                  }
                  <li style={{ width: "100%" }}>
                    {!this.state.update.createCredit && <Button label="Créer un avoir" onClick={() => this.setState({update: { ...this.state.update, createCredit: true }})} />}
                    {this.state.update.createCredit && (
                      <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                        <Input style={{ width: "100%" }} placeholder="Montant de l'avoir (TTC)" priorityValue={this.state.update.creditAmount} getValue={(value) => this.setState({ update: {...this.state.update, creditAmount: value} })} />
                        <div style={{ display: "flex", width: "100%", marginTop: 10 }}>
                          <Button style={{marginRight: 5}} isCancel label="Annuler" onClick={(e) => this.setState({ update: {...this.state.update, createCredit: false, creditAmount: 0 }})} />
                          <Button style={{marginLeft: 5}} label="Confirmer" onClick={(e) => {
                            let creditAmount = this.state.update.creditAmount;
                            this.setState({ update: {...this.state.update, createCredit: false, creditAmount: 0 }}, () => {
                              new Order({}).setToken(this.props.user.token).generateNewBill(this.props.order.id, creditAmount).then(order => this.props.updateAnOrder(order));
                            })
                          }} />
                        </div>
                      </div>
                    )}
                  </li>
                </ul>
                
              </>
            )}
            <Label value={this.context.t('path')} />
            <ul>
              <li>
                <span>
                  <MaterialIcon icon="place" size={"tiny"} color="#1c6bb1" />
                </span>
                <a target="_blank" rel="noopener noreferrer" href={"https://maps.google.com/?q=" + _.get(this.props.order, "departure_lat") + "," + _.get(this.props.order, "departure_lng")}>
                  {(this.props.order.departure_address ? this.props.order.departure_address : "") + (this.props.order.distance === 0 ? " (Dépannage sur place)" : "")}
                </a>
              </li>
              {
                this.props.order.address_police_station && (
                  <li>
                    <span>
                      <MaterialIcon icon="local_police" size={"tiny"} color="#1c6bb1" />
                    </span>
                    <a target="_blank" rel="noopener noreferrer" href={"https://maps.google.com/?q=" + _.get(this.props.order, "police_lat") + "," + _.get(this.props.order, "police_lng")}>
                      Commissariat {this.props.order.address_police_station}
                    </a>
                  </li>
                )
              }
              {
                this.props.order.arrival_address !== "on_site" ? (this.props.order.arrival_address !== "garage" ? <li><span><MaterialIcon icon="navigation" size={"tiny"} color="#1c6bb1" /></span><a target="_blank" rel="noopener noreferrer" href={"https://maps.google.com/?q=" + _.get(this.props.order, "arrival_lat") + "," + _.get(this.props.order, "arrival_lng")}>
                  {this.props.order.arrival_address}
                </a></li> : <li><span><MaterialIcon icon="navigation" size={"tiny"} color="#1c6bb1" /></span>{this.context.t('garage')}</li>) : ""
              }
              {
                this.props.order.distance > 0 ? <li><span><MaterialIcon icon="settings_ethernet" size={"tiny"} color="#1c6bb1" /></span>{Math.round(this.props.order.distance)/1000 + " km"}</li> : ""
              }
              <li>
                <span><MaterialIcon icon="schedule" size={"tiny"} color="#1c6bb1" /></span>{"Pour le " + day + "/" + month + "/" + year + " à " + hours + "h" + minutes}
              </li>
            </ul>
            <Label value={this.context.t('about_the_problem')} />
            <ul>
              <li><span><MaterialIcon icon="build" size={"tiny"} color="#1c6bb1" /></span>{this.context.t(problemTrans)}{problemTrans !== "" ? " - " : ""}{this.context.t(reasonTrans) + (this.props.order.limited_height !== 0 && this.props.order.limited_height !== "0" ? " - " + this.context.t("limited_height") : "")}</li>
              { this.props.order.note && (
                <li><span><MaterialIcon icon="article" size={"tiny"} color="#1c6bb1" /></span>{this.props.order.note}</li>
              )}
            </ul>
            <Label value={this.context.t('about_the_vehicle')} />
            <ul style={{width: "100%"}}>
              <li style={{width: "100%"}}>
                <span><MaterialIcon icon={about_vehicle_icon} size={"tiny"} color="#1c6bb1" /></span>
                <ul style={{width: "100%"}}>
                  <li>{this.context.t(vehicleTypeTrans)}</li>
                  <li>{this.props.order.vehicle_brand}</li>
                  <li>{this.props.order.vehicle_model}</li>
                  { !this.state.update.vehicleRegistration && <li style={{width: "100%"}}><div style={{width: "100%", display: "flex", justifyContent: "space-between"}}>{this.props.order.vehicle_registration}<span onClick={() => this.setState({update:{...this.state.update, vehicleRegistration: true}})} style={{cursor:"pointer"}}><MaterialIcon icon={"create"} size={"tiny"} color="#1c6bb1" /></span></div></li>}
                  { this.state.update.vehicleRegistration && (<li>
                    <Input style={{ flex: 1, marginRight: 10, marginTop: 0 }} placeholder={this.context.t("vehicle_registration")} priorityValue={this.state.update.vehicleRegistrationValue}  getValue={vehicleRegistrationValue => this.setState({update:{...this.state.update, vehicleRegistrationValue}})} />
                    <FormButton style={{ width: 75 }} selected={true} label={"OK"} onClick={this.changeVehicleRegistration}/>
                  </li>)}
                </ul>
              </li>
            </ul>
            <Label value={this.context.t('about_the_customer')} />
            <ul>
              {
                this.props.order.b2_bcompany && this.props.order.b2_bcompany.company_name && (
                  <>
                    <li>
                      <span><MaterialIcon icon="business" size={"tiny"} color="#1c6bb1" /></span>
                      {this.props.order.b2_bcompany.company_name}
                    </li>
                  </>
                )
              }
              {
                this.props.order.firstname && this.props.order.name &&
                (<li><span><MaterialIcon icon="person" size={"tiny"} color="#1c6bb1" /></span>{this.props.order.firstname + " " + this.props.order.name}</li>)
              }
              
              {
                this.props.order.email ?
                (<li><span><MaterialIcon icon="mail" size={"tiny"} color="#1c6bb1" /></span>{this.props.order.email}</li>) : ""
              }
              <li><span><MaterialIcon icon="call" size={"tiny"} color="#1c6bb1" /></span>{this.props.order.phonenumber}</li>
              {
                this.props.order.bill_address && (
                  <li><span><MaterialIcon icon="home" size={"tiny"} color="#1c6bb1" /></span>{this.props.order.bill_address}</li>
                )
              }
              {
                this.props.order.b2_bcompany && this.props.order.b2_bcompany.deleted && (
                  <li style={{color: "red"}}>
                    <span><MaterialIcon icon="clear" size={"tiny"} color="red" /></span>
                      Ce client B2B a été supprimé de la base de données.
                  </li>
                )
              }
            </ul>
            <Label value={this.context.t("order_status")} />
            <ul>
              <li>
                <span><MaterialIcon icon="whatshot" size={"tiny"} color="#1c6bb1" /></span>
                <div>
                  {/* (this.props.order.police_station_is_done || (!this.props.order.police_station_is_done && !this.props.order.address_police_station)) */}
                  {
                    this.props.order.driver && this.props.order.status !== status.FINISHED && !this.props.order.cancelled && !this.props.order.address_police_station ? (
                      <>
                        {/* Dans le cas où il n'y a pas de commissariat, c'est le comportement habituel */}
                        {this.context.t(statusTrans)}
                        <span onClick={() => this.onClickEditStatus()}><MaterialIcon icon="create" size={"tiny"} color="#1c6bb1" /></span>
                      </>
                    ) : ""
                  }
                  {
                    this.props.order.driver && this.props.order.status !== status.FINISHED && !this.props.order.cancelled && this.props.order.address_police_station && this.props.order.police_station_is_done ? (
                      <>
                        {/* Dans le cas où il y a un commissariat et qu'on y est allé */}
                        {this.context.t(statusTrans)}
                        <span onClick={() => this.onClickEditStatus()}><MaterialIcon icon="create" size={"tiny"} color="#1c6bb1" /></span>
                      </>
                    ) : ""
                  }
                  {
                    this.props.order.driver && this.props.order.status !== status.FINISHED && this.props.order.status !== status.IN_PROGRESS && !this.props.order.cancelled && this.props.order.address_police_station && !this.props.order.police_station_is_done ? (
                      <>
                        {/* Dans le cas où il y a un commissariat et qu'on y est allé */}
                        {this.context.t(statusTrans)}
                        <span onClick={() => this.onClickEditStatus()}><MaterialIcon icon="create" size={"tiny"} color="#1c6bb1" /></span>
                      </>
                    ) : ""
                  }
                  {
                    this.props.order.driver && this.props.order.status === status.IN_PROGRESS && !this.props.order.cancelled && this.props.order.address_police_station && !this.props.order.police_station_is_done ? (
                      <>
                        {/* Dans le cas où il y a un commissariat mais qu'on n'y est pas encore allé */}
                        {"Commissariat en cours"}
                        <span onClick={() => new Order({}).setToken(this.props.user.token).setPoliceStationIsDone(this.props.order.id)}><MaterialIcon icon="create" size={"tiny"} color="#1c6bb1" /></span>
                      </>
                    ) : ""
                  }

                  {
                    (!this.props.order.company || !this.props.order.driver || this.props.order.status === status.FINISHED || this.props.order.cancelled === true) ? (
                      <>{this.context.t(statusTrans)}<span></span></>
                    ) : ""
                  }
                </div>
              </li>
              {
                this.props.order.advance_of_cost ? (
                  <li>
                    <span><MaterialIcon icon="credit_card" size="tiny" color="#1c6bb1"/></span>
                      {
                        this.props.order.advance_of_cost_is_done ? (
                          <>
                            L'avance de frais a été effectuée.
                          </>
                        ) : (
                          <div>
                            L'avance de frais n'a pas été effectuée.
                            {
                              this.props.order.status >= status.IN_PROGRESS ? (
                                <span onClick={() => new Order({}).setToken(this.props.user.token).setAdvanceOfCostIsDone(this.props.order.id)}><MaterialIcon icon="create" size={"tiny"} color="#1c6bb1" /></span>
                              ) : (
                                <span></span>
                              )
                            }
                          </div>
                        )
                      }
                  </li>
                ) : ""
              }
              {
                this.props.order.company && this.state.companyConfirmed === true ? <li><span><MaterialIcon icon="fingerprint" size={"tiny"} color="#1c6bb1" /></span>{this.props.order.company.name}</li> : ""
              }
              {
                this.state.driver && !this.props.order.driver ? <li><span><MaterialIcon icon="chevron_right" size={"tiny"} color="#1c6bb1" /></span>{this.state.driver.fullname}</li> : ""
              }
              {
                !this.state.driver && this.props.order.driver ? <li><span><MaterialIcon icon="chevron_right" size={"tiny"} color="#1c6bb1" /></span>{this.props.order.driver.fullname}</li> : ""
              }
              {
                this.state.driver && this.props.order.driver ? <li><span><MaterialIcon icon="chevron_right" size={"tiny"} color="#1c6bb1" /></span>{this.props.order.driver.fullname}</li> : ""
              }
              {
                this.props.order.company === undefined && !this.props.order.cancelled ? <li><span><MaterialIcon icon="fingerprint" size={"tiny"} color="#1c6bb1" /></span><CompanyAutocomplete placeholder={this.context.t('company_to_be_mandated')} getValue={(value) => this.setState({...this.state, companyConfirmed: false, company: value})} priorityValue={_.get(this.state, 'company.name', undefined)} token={this.props.user.token}/></li> : ""
              }
              {
                this.state.company && this.state.companyConfirmed === false && !this.props.order.cancelled ? <li><Button isCancel label={this.context.t('cancel')} onClick={() => this.setState({company: undefined, companyConfirmed: true})}/></li> : ""
              }
              {
                this.state.company && this.state.companyConfirmed === false && !this.props.order.cancelled ? <li><Button label={this.state.company.name} onClick={() => this.onClickDispatchToCompany()}/></li> : ""
              }
              {
                !this.state.driver && !this.props.order.driver && this.props.order.company && this.state.companyConfirmed && !this.props.order.cancelled ? <Label value={this.context.t('question_driver_dispatch')}/> : ""
              }
              {
                !this.state.driver && !this.props.order.driver && this.props.order.company && this.state.companyConfirmed && !this.props.order.cancelled ? <li className="driver-selection">{this.selectDriver()}</li> : ""
              }
            </ul>
            {
              this.props.order.b2_bcompany && (
                <>
                  <Label value={this.context.t('question_options')}/>
                  <ul>
                    {
                      this.props.order.address_police_station && this.props.order.waiting_time_police >= 0 && (
                        <li><span><MaterialIcon icon="local_police" size={"tiny"} color="#1c6bb1" /></span>
                          <div>
                            { this.props.order.waiting_time_police <= 1 ? "1 heure de commissariat" : this.props.order.waiting_time_police + " heures de commissariat" }
                            <span style={{ display: "flex" }}>
                              <span onClick={this.addHourPoliceStation} style={{color: "white", background: "#1c6bb1", width: 20, height: 20, borderRadius: 10, display: "flex", justifyContent: "center"}}>+</span>
                              { this.props.order.waiting_time_police > 1 && <span onClick={this.subtractHourPoliceStation} style={{color: "white", background: "#1c6bb1", width: 20, height: 20, borderRadius: 10, display: "flex", justifyContent: "center", marginLeft: 10}}>-</span> }
                            </span>
                          </div>
                        </li>
                      )
                    }
                    <li><span><MaterialIcon icon="sync_alt" size={"tiny"} color="#1c6bb1" /></span>
                      <div>
                        { this.props.order.additional_displacement === 0 ? "Aucun déplacement supplémentaire" : (this.props.order.additional_displacement === 1 ? "1 déplacement supplémentaire" : this.props.order.additional_displacement + " déplacements supplémentaires") }
                        <span style={{ display: "flex" }}>
                          <span onClick={this.addAdditionalDisplacement} style={{color: "white", background: "#1c6bb1", width: 20, height: 20, borderRadius: 10, display: "flex", justifyContent: "center"}}>+</span>
                          { this.props.order.additional_displacement > 0 && <span onClick={this.subtractAdditionalDisplacement} style={{color: "white", background: "#1c6bb1", width: 20, height: 20, borderRadius: 10, display: "flex", justifyContent: "center", marginLeft: 10}}>-</span> }
                        </span>
                      </div>
                    </li>
                    <li><span><MaterialIcon icon="local_car_wash" size={"tiny"} color="#1c6bb1" /></span>
                      <div>
                        { this.props.order.cleaning === false ? "Pas de lavage dépanneuse" : "Lavage dépanneuse" }
                        <span style={{ display: "flex" }}>
                          { this.props.order.cleaning === false && <span onClick={this.addCleaning} style={{color: "white", background: "#1c6bb1", width: 20, height: 20, borderRadius: 10, display: "flex", justifyContent: "center"}}>+</span> }
                          { this.props.order.cleaning === true && <span onClick={this.removeCleaning} style={{color: "white", background: "#1c6bb1", width: 20, height: 20, borderRadius: 10, display: "flex", justifyContent: "center", marginLeft: 10}}>-</span> }
                        </span>
                      </div>
                    </li>
                    <li><span><MaterialIcon icon="handyman" size={"tiny"} color="#1c6bb1" /></span>
                      <div>
                      { !this.props.order.hour_handling ? "Pas de manutention" : (this.props.order.hour_handling === 1 ? "1 heure de manutention" : this.props.order.hour_handling + " heures de manutention") }
                        <span style={{ display: "flex" }}>
                          <span onClick={this.addHourHandling} style={{color: "white", background: "#1c6bb1", width: 20, height: 20, borderRadius: 10, display: "flex", justifyContent: "center"}}>+</span>
                          { this.props.order.hour_handling > 0 && <span onClick={this.subtractHourHandling} style={{color: "white", background: "#1c6bb1", width: 20, height: 20, borderRadius: 10, display: "flex", justifyContent: "center", marginLeft: 10}}>-</span> }
                        </span>
                      </div>
                    </li>
                    <li>
                      <span><MaterialIcon icon="traffic" size={"tiny"} color="#1c6bb1" /></span>
                      <div>
                      { !this.props.order.tolls ? "Pas de péage" : this.props.order.tolls + " € TTC de péages (" + Math.round(this.props.order.tolls/1.2*100)/100 + " € HT)"}
                      { this.props.order.tolls > 0 && !this.props.order.paid && <span onClick={() => new Order({}).setToken(this.props.user.token).updateTrolls(this.props.order.id, 0)} style={{color: "white", background: "#1c6bb1", width: 20, height: 20, borderRadius: 10, display: "flex", justifyContent: "center", marginLeft: 10}}>-</span> }
                      </div>
                    </li>
                    <li>      
                      <div className="flex" style={{ alignItems: "center" }}>
                        <Input style={{ flex: 1, marginRight: 10, marginTop: 0 }} placeholder={this.context.t('tolls')} type="number" getValue={(tolls) => this.setState({ tolls })} />
                        <FormButton style={{ width: 75 }} selected={true} label={"OK"} onClick={() => {
                          new Order({}).setToken(this.props.user.token).updateTrolls(this.props.order.id, this.state.tolls);
                        }}/>
                      </div>
                    </li>
                  </ul>
                </>
              )
            }
            <Label value={this.context.t('question_price')}/>
            <ul>
              <li><span><MaterialIcon icon="euro_symbol" size={"tiny"} color="#1c6bb1" /></span>
                  { this.props.order.price > 0 ? "Prix annoncé : " + this.props.order.price + " € (HT) - " + Math.round(this.props.order.price*1.2*100)/100 + " € (TTC)" : this.context.t('price_loading') }
              </li>
              <li><span><MaterialIcon icon="euro_symbol" size={"tiny"} color="#1c6bb1" /></span>
                { this.props.order.final_price ? "Prix final : " + this.props.order.final_price + " € (HT) - " + Math.round(this.props.order.final_price*1.2*100)/100 + " € (TTC)": this.context.t('price_loading') }
                { this.props.order.price !== this.props.order.final_price && this.props.order.final_price === 50 ? " : frais de dossier et d'annulation" : ""}
                { this.props.order.cancelled && this.props.order.final_price && this.props.order.final_price > 50 ? " : frais de dossier, d'annulation et de mise à disposition d'une dépanneuse" : ""}
              </li>
              {
                this.props.order.advance_of_cost > 0 ? (
                  <li>
                    <span><MaterialIcon icon="payments" size={"tiny"} color="#1c6bb1" /></span>
                    {
                      this.props.order.advance_of_cost_tva ? (
                        <>
                          Avance de frais : {Math.round(this.props.order.advance_of_cost/1.2*100)/100} € (HT) - {this.props.order.advance_of_cost} € (TTC) 
                        </>
                      ) : (
                        <>
                          Avance de frais : {this.props.order.advance_of_cost} € (TVA 0%) 
                        </>
                      )
                    }
                    
                  </li>
                ) : ""
              }
              {
                this.props.order.payment_method !== undefined ? <li><span><MaterialIcon icon="payment" size={"tiny"} color="#1c6bb1" /></span>{this.context.t(payment)}</li> : ""
              }
              {
                this.props.order.payment_method === undefined ? (
                  <>
                    <li><FormButton label={this.context.t('payment_species')} onClick={() => new Order().setToken(this.props.user.token).setPaymentMethod(this.props.order.id, paymentMethod.PAYMENT_SPECIES)}/></li>
                    <li><FormButton label={this.context.t('payment_card')} onClick={() => new Order().setToken(this.props.user.token).setPaymentMethod(this.props.order.id, paymentMethod.PAYMENT_CARD)}/></li>
                    <li><FormButton label={this.context.t('payment_check')} onClick={() => new Order().setToken(this.props.user.token).setPaymentMethod(this.props.order.id, paymentMethod.PAYMENT_CHECK)}/></li>
                    <li><FormButton label={this.context.t('payment_transfer')} onClick={() => new Order().setToken(this.props.user.token).setPaymentMethod(this.props.order.id, paymentMethod.PAYMENT_TRANSFER)}/></li>
                  </>
                ) : ""
              }
              {
                this.props.order.last_amount_paid === this.props.order.final_price + (Math.round(this.props.order.advance_of_cost/1.2*100)/100) ? <li><span><MaterialIcon icon="thumb_up" size={"tiny"} color="#1c6bb1" /></span>{this.context.t('customer_has_paid')} {Math.round(this.props.order.last_amount_paid*1.2*100)/100} € TTC</li> : ""
              }
              {
                !this.props.order.last_amount_paid && this.props.order.last_amount_paid !== this.props.order.final_price + (Math.round(this.props.order.advance_of_cost/1.2*100)/100) ? <li><span><MaterialIcon icon="thumb_down" size="tiny" color="#1c6bb1" /></span><div>{this.context.t('customer_needs_to_pay')}<span onClick={() => this.onClickOrderIsPaid()}><MaterialIcon icon="check" size={"tiny"} color="#1c6bb1" /></span></div></li> : ""
              }
              {
                this.props.order.last_amount_paid > 0 && this.props.order.last_amount_paid < this.props.order.final_price + (Math.round(this.props.order.advance_of_cost/1.2*100)/100) ? <li><span><MaterialIcon icon="thumb_down" size="tiny" color="#1c6bb1" /></span><div>{Math.round(this.props.order.last_amount_paid*1.2*100)/100 + " € TTC ont été payés, le client doit régler " + Math.round((this.props.order.final_price+(this.props.order.advance_of_cost/1.2)-this.props.order.last_amount_paid)*1.2*100)/100 + " € TTC"}<span onClick={() => this.onClickOrderIsPaid()}><MaterialIcon icon="check" size={"tiny"} color="#1c6bb1" /></span></div></li> : ""
              }
              {
                this.props.order.last_amount_paid > 0 && this.props.order.last_amount_paid > this.props.order.final_price + (Math.round(this.props.order.advance_of_cost/1.2*100)/100) ? <li><span><MaterialIcon icon="thumb_down" size="tiny" color="#1c6bb1" /></span><div>{Math.round((this.props.order.final_price+(this.props.order.advance_of_cost/1.2)-this.props.order.last_amount_paid)*(-1)*1.2*100)/100 + " € TTC à rembourser au client"}<span onClick={() => this.onClickOrderIsPaid()}><MaterialIcon icon="check" size={"tiny"} color="#1c6bb1" /></span></div></li> : ""
              }
              {
                !this.props.order.cancelled && this.props.order.status < status.FINISHED ? <li><SubmitButton isCancel label={this.context.t('cancel_order')} onClick={() => new Order().setToken(this.props.user.token).cancelOrder(this.props.order.id).then(() => this.props.push("/order"))}/></li> : ""
              }
              <li><SubmitButton isCancel label={this.context.t('delete_order')} onClick={() => new Order().setToken(this.props.user.token).deleteOrder(this.props.order.id).then(() => this.props.push("/order"))}/></li>
            </ul>
          </ DetailsView>
        </Template>
    );
  }
}

OrderDetails.contextTypes = {
  t: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
  user: state.currentUser,
  order: function(){
    // console.log("state.orderList", state.orderList)
    let order = state.orderList.orders.find(order => order && order.id === parseInt(window.location.pathname.split('/')[2]));
    if (!order) {
        order = state.orderList.orders_not_finished.find(order => order.id === parseInt(window.location.pathname.split('/')[2]));
    }
    if (!order && state.orderList.searchOrders) {
      order = state.orderList.searchOrders.find(order => order.id === parseInt(window.location.pathname.split('/')[2]))
    }
    if (order && order.company && (!order.company.employes || order.company.employes.length === 0) && !order.driver) {
      new Company().setToken(state.currentUser.token).getEmployes(order.company.id).then(employes => order.company.employes = employes);
      return order;
    } else {
      return order;
    }
  }()
})

const mapDispatchToProps = (dispatch) => ({
  push: (route) => {
    dispatch(push(route));
  },
  updateAnOrder: (order) => dispatch({
    type: OrderListType.ORDERLIST_UPDATE_AN_ORDER,
    payload: order
  }),
})

export default connect(mapStateToProps, mapDispatchToProps)(OrderDetails);
