import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';

import Input from '../../components/Input';
import Label from '../../components/Label';

import B2BCreateType from '../../redux/types/b2b_create_type';

import './styles.scss';

class B2BPrices extends React.Component{

  constructor(props){
      super(props);
      this.state = {};
  }

  render(){
      return(
        <>
        <div>
          <h2>{this.context.t('question_b2b_company_prices_vehicles_rolling')}</h2>
          <div className="flex">
            <Input type="number" priorityValue={this.props.rollingLightVL} placeholder={this.context.t("light_vehicle")} getValue={this.props.setRollingLightVL} />
            <Input type="number" priorityValue={this.props.rollingVanVehicle} placeholder={this.context.t("van_vehicle")} getValue={this.props.setRollingVanVehicle} />
          </div>
          <div className="flex">
            <Input type="number" priorityValue={this.props.rollingCommercial} placeholder={this.context.t("commercial_vehicle")} getValue={this.props.setRollingCommercial} />
            <Input type="number" priorityValue={this.props.rollingLightPL} placeholder={this.context.t("light_pl")} getValue={this.props.setRollingLightPL} />
          </div>
          <div className="flex">
            <Input type="number" priorityValue={this.props.rollingMediumPL} placeholder={this.context.t("medium_pl")} getValue={this.props.setRollingMediumPL} />
            <Input type="number" priorityValue={this.props.rollingHeavyPL} placeholder={this.context.t("heavy_pl")} getValue={this.props.setRollingHeavyPL} />
          </div>
          <Label value={this.context.t("b2b_prices_vehicles_explanation")} />
        </div>
        <div>
          <h2>{this.context.t('question_b2b_company_prices_vehicles_not_rolling')}</h2>
          <div className="flex">
            <Input type="number" priorityValue={this.props.notRollingLightVL} placeholder={this.context.t("light_vehicle")} getValue={this.props.setNotRollingLightVL} />
            <Input type="number" priorityValue={this.props.notRollingVanVehicle} placeholder={this.context.t("van_vehicle")} getValue={this.props.setNotRollingVanVehicle} />
          </div>
          <div className="flex">
            <Input type="number" priorityValue={this.props.notRollingCommercial} placeholder={this.context.t("commercial_vehicle")} getValue={this.props.setNotRollingCommercial} />
            <Input type="number" priorityValue={this.props.notRollingLightPL} placeholder={this.context.t("light_pl")} getValue={this.props.setNotRollingLightPL} />
          </div>
          <div className="flex">
            <Input type="number" priorityValue={this.props.notRollingMediumPL} placeholder={this.context.t("medium_pl")} getValue={this.props.setNotRollingMediumPL} />
            <Input type="number" priorityValue={this.props.notRollingHeavyPL} placeholder={this.context.t("heavy_pl")} getValue={this.props.setNotRollingHeavyPL} />
          </div>
          <Label value={this.context.t("b2b_prices_vehicles_explanation")} />
        </div>
        <div>
          <h2>{this.context.t('question_b2b_company_prices_vehicles_redelivery')}</h2>
          <div className="flex">
            <Input type="number" priorityValue={this.props.redeliveryLightVL} placeholder={this.context.t("light_vehicle")} getValue={this.props.setRedeliveryLightVL} />
            <Input type="number" priorityValue={this.props.redeliveryVanVehicle} placeholder={this.context.t("van_vehicle")} getValue={this.props.setRedeliveryVanVehicle} />
          </div>
          <div className="flex">
            <Input type="number" priorityValue={this.props.redeliveryCommercial} placeholder={this.context.t("commercial_vehicle")} getValue={this.props.setRedeliveryCommercial} />
            <Input type="number" priorityValue={this.props.redeliveryLightPL} placeholder={this.context.t("light_pl")} getValue={this.props.setRedeliveryLightPL} />
          </div>
          <div className="flex">
            <Input type="number" priorityValue={this.props.redeliveryMediumPL} placeholder={this.context.t("medium_pl")} getValue={this.props.setRedeliveryMediumPL} />
            <Input type="number" priorityValue={this.props.redeliveryHeavyPL} placeholder={this.context.t("heavy_pl")} getValue={this.props.setRedeliveryHeavyPL} />
          </div>
          <Label value={this.context.t("b2b_prices_vehicles_explanation")} />
        </div>
        </>
      );
    }
}

B2BPrices.contextTypes = {
  t: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
  rollingLightVL: _.get(state.b2bCreate, 'rolling.light_vehicle', ""),
  rollingVanVehicle: _.get(state.b2bCreate, 'rolling.van_vehicle', ""),
  rollingCommercial: _.get(state.b2bCreate, 'rolling.commercial_vehicle', ""),
  rollingLightPL: _.get(state.b2bCreate, 'rolling.light_pl', ""),
  rollingMediumPL: _.get(state.b2bCreate, 'rolling.medium_pl', ""),
  rollingHeavyPL: _.get(state.b2bCreate, 'rolling.heavy_pl', ""),

  notRollingLightVL: _.get(state.b2bCreate, 'not_rolling.light_vehicle', ""),
  notRollingVanVehicle: _.get(state.b2bCreate, 'not_rolling.van_vehicle', ""),
  notRollingCommercial: _.get(state.b2bCreate, 'not_rolling.commercial_vehicle', ""),
  notRollingLightPL: _.get(state.b2bCreate, 'not_rolling.light_pl', ""),
  notRollingMediumPL: _.get(state.b2bCreate, 'not_rolling.medium_pl', ""),
  notRollingHeavyPL: _.get(state.b2bCreate, 'not_rolling.heavy_pl', ""),

  redeliveryLightVL: _.get(state.b2bCreate, 'redelivery.light_vehicle', ""),
  redeliveryVanVehicle: _.get(state.b2bCreate, 'redelivery.van_vehicle', ""),
  redeliveryCommercial: _.get(state.b2bCreate, 'redelivery.commercial_vehicle', ""),
  redeliveryLightPL: _.get(state.b2bCreate, 'redelivery.light_pl', ""),
  redeliveryMediumPL: _.get(state.b2bCreate, 'redelivery.medium_pl', ""),
  redeliveryHeavyPL: _.get(state.b2bCreate, 'redelivery.heavy_pl', ""),
})

const mapDispatchToProps = (dispatch) => ({
  setRollingLightVL: (price) => dispatch({ type: B2BCreateType.B2B_CREATE_SET_PRICE_VL_ROLLING, payload: price }),
  setRollingVanVehicle: (price) => dispatch({ type: B2BCreateType.B2B_CREATE_SET_PRICE_4X4_ROLLING, payload: price }),
  setRollingCommercial: (price) => dispatch({ type: B2BCreateType.B2B_CREATE_SET_PRICE_VU_ROLLING, payload: price}),
  setRollingLightPL: (price) => dispatch({ type: B2BCreateType.B2B_CREATE_SET_PRICE_LIGHT_PL_ROLLING, payload: price }),
  setRollingMediumPL: (price) => dispatch({ type: B2BCreateType.B2B_CREATE_SET_PRICE_MEDIUM_PL_ROLLING, payload: price }),
  setRollingHeavyPL: (price) => dispatch({ type: B2BCreateType.B2B_CREATE_SET_PRICE_HEAVY_PL_ROLLING, payload: price }),

  setNotRollingLightVL: (price) => dispatch({ type: B2BCreateType.B2B_CREATE_SET_PRICE_VL_NOT_ROLLING, payload: price }),
  setNotRollingVanVehicle: (price) => dispatch({ type: B2BCreateType.B2B_CREATE_SET_PRICE_4X4_NOT_ROLLING, payload: price }),
  setNotRollingCommercial: (price) => dispatch({ type: B2BCreateType.B2B_CREATE_SET_PRICE_VU_NOT_ROLLING, payload: price }),
  setNotRollingLightPL: (price) => dispatch({ type: B2BCreateType.B2B_CREATE_SET_PRICE_LIGHT_PL_NOT_ROLLING, payload: price }),
  setNotRollingMediumPL: (price) => dispatch({ type: B2BCreateType.B2B_CREATE_SET_PRICE_MEDIUM_PL_NOT_ROLLING, payload: price }),
  setNotRollingHeavyPL: (price) => dispatch({ type: B2BCreateType.B2B_CREATE_SET_PRICE_HEAVY_PL_NOT_ROLLING, payload: price }),

  setRedeliveryLightVL: (price) => dispatch({ type: B2BCreateType.B2B_CREATE_SET_PRICE_VL_REDELIVERY, payload: price }),
  setRedeliveryVanVehicle: (price) => dispatch({ type: B2BCreateType.B2B_CREATE_SET_PRICE_4X4_REDELIVERY, payload: price }),
  setRedeliveryCommercial: (price) => dispatch({ type: B2BCreateType.B2B_CREATE_SET_PRICE_VU_REDELIVERY, payload: price }),
  setRedeliveryLightPL: (price) => dispatch({ type: B2BCreateType.B2B_CREATE_SET_PRICE_LIGHT_PL_REDELIVERY, payload: price }),
  setRedeliveryMediumPL: (price) => dispatch({ type: B2BCreateType.B2B_CREATE_SET_PRICE_MEDIUM_PL_REDELIVERY, payload: price }),
  setRedeliveryHeavyPL: (price) => dispatch({ type: B2BCreateType.B2B_CREATE_SET_PRICE_HEAVY_PL_REDELIVERY, payload: price }),
})

export default connect(mapStateToProps, mapDispatchToProps)(B2BPrices);
