import React from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import './styles.scss';

import Template from '../../templates/Backoffice';
import HeaderButtons from '../../components/HeaderButtons';
import Button from '../../components/Buttons/SubmitButton';

import Infos from './infos';
import VehiclePrices from './pricesVehicle';
import Prices from './prices';
import Distance from './distance';
import B2BCreateType from '../../redux/types/b2b_create_type';
import B2BCompany from '../../core/B2BCompany';

class B2BCreate extends React.Component{

  constructor(props){
      super(props);
      this.state = {
          searchOption: {label: "company_name", action: this.searchCompanyName},
          searchList: undefined
      }
  }

  _handleConfirm = () => {
    new B2BCompany()
      .setToken(this.props.user.token)
      .create(this.props.b2b)
      .then(b2bcompany => {
        this.props.push("/b2b")
      })
  }

  render(){
    return(
      <Template>
        <div className="form b2b-create-page">
          <HeaderButtons tabList={[
            {
              label: this.context.t('list_of_b2b'),
              route: "b2b"
            },
            {
              label: this.context.t("create_b2b"),
              route: "b2b/create"
            }
          ]}
          />

          <h1>{ this.context.t("create_b2b") }</h1>
          <div className="section">
            <Infos />
            <VehiclePrices />
            <Prices />
            <Distance />
            <div style={{ paddingBottom: 20 }}>
              <div>
                <Button
                  isCancel
                  label={this.context.t("cancel")}
                  onClick={this.props.resetForm}
                />
                <Button
                  label={this.context.t("confirm")}
                  onClick={this._handleConfirm}
                />
              </div>
            </div>
          </div>
        </div>
      </Template>
    );
  }
}

B2BCreate.contextTypes = {
  t: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
  user: state.currentUser,
  b2b: {
    companyName: _.get(state.b2bCreate, 'infos.companyName', undefined),
    phonenumber: _.get(state.b2bCreate, 'infos.phonenumber', undefined),
    email: _.get(state.b2bCreate, 'infos.email', undefined),
    address: _.get(state.b2bCreate, 'infos.location.address', undefined),
    
    rollingLightVL: _.get(state.b2bCreate, 'rolling.light_vehicle', undefined),
    rollingVanVehicle: _.get(state.b2bCreate, 'rolling.van_vehicle', undefined),
    rollingCommercial: _.get(state.b2bCreate, 'rolling.commercial_vehicle', undefined),
    rollingLightPL: _.get(state.b2bCreate, 'rolling.light_pl', undefined),
    rollingMediumPL: _.get(state.b2bCreate, 'rolling.medium_pl', undefined),
    rollingHeavyPL: _.get(state.b2bCreate, 'rolling.heavy_pl', undefined),
    
    notRollingLightVL: _.get(state.b2bCreate, 'not_rolling.light_vehicle', undefined),
    notRollingVanVehicle: _.get(state.b2bCreate, 'not_rolling.van_vehicle', undefined),
    notRollingCommercial: _.get(state.b2bCreate, 'not_rolling.commercial_vehicle', undefined),
    notRollingLightPL: _.get(state.b2bCreate, 'not_rolling.light_pl', undefined),
    notRollingMediumPL: _.get(state.b2bCreate, 'not_rolling.medium_pl', undefined),
    notRollingHeavyPL: _.get(state.b2bCreate, 'not_rolling.heavy_pl', undefined),

    redeliveryLightVL: _.get(state.b2bCreate, 'redelivery.light_vehicle', undefined),
    redeliveryVanVehicle: _.get(state.b2bCreate, 'redelivery.van_vehicle', undefined),
    redeliveryCommercial: _.get(state.b2bCreate, 'redelivery.commercial_vehicle', undefined),
    redeliveryLightPL: _.get(state.b2bCreate, 'redelivery.light_pl', undefined),
    redeliveryMediumPL: _.get(state.b2bCreate, 'redelivery.medium_pl', undefined),
    redeliveryHeavyPL: _.get(state.b2bCreate, 'redelivery.heavy_pl', undefined),

    hourPoliceStation: _.get(state.b2bCreate, 'other.police_station', undefined),
    hourBasement: _.get(state.b2bCreate, 'other.basement', undefined),
    hourHandling: _.get(state.b2bCreate, 'other.handling', undefined),
    advanceOfCostCommission: _.get(state.b2bCreate, 'other.advance_of_cost', undefined),
    kmIncluded: _.get(state.b2bCreate, 'km_included', undefined),
    fullDistanceCalculation: _.get(state.b2bCreate, 'full_distance', undefined)
  }
})
const mapDispatchToProps = (dispatch) => ({
  resetForm: () => dispatch({ type: B2BCreateType.B2B_CREATE_RESET_STATE }),
  push: (route) => dispatch(push(route))
})

export default connect(mapStateToProps, mapDispatchToProps)(B2BCreate);
