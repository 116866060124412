import React from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import './styles.scss';

import Company from '../../core/Company';
import CompanyType from '../../redux/types/company';

import HeaderButtons from '../../components/HeaderButtons';
import Button from '../../components/Buttons/SubmitButton';

import CompanyInfos from './infos';
import CompanyLocation from './location';
import CompanyGarage from './garage';
import CompanyActivity from './activity';
import CompanyBank from './bank';

import Template from '../../templates/Backoffice';
import Form from '../../templates/Form';

class CompaniesCreate extends React.Component{

  constructor(props){
    super(props);
    this.state = this.props.company;
  }

  onClickConfirm = () => {
    console.log(this.props.company);
    new Company({
      name: this.props.company.name,
      logo: this.props.company.logo,
      siret: this.props.company.siret,
      phonenumber: this.props.company.phonenumber,
      address: this.props.company.address,
      latitude: this.props.company.latitude,
      longitude: this.props.company.longitude,
      email: this.props.company.email,
      garage: this.props.company.garage ? this.props.company.garage : 0,
      bodyWork: this.props.company.bodyWork ? this.props.company.bodyWork : 0,
      mechanics: this.props.company.mechanics ? this.props.company.mechanics : 0,
      paint: this.props.company.paint ? this.props.company.paint : 0,
      rentalCar: this.props.company.rentalCar ? this.props.company.rentalCar : 0,
      defaultCommission: this.props.company.defaultCommission ? this.props.company.defaultCommission : 15,
      iban: _.get(this.props, 'company.iban', undefined)
    }).setToken(this.props.user.token).create().then(data => this.props.push('/company'));
  }

  render(){
    return(
      <Template>
        <Form className="companies-create-page">
          <HeaderButtons tabList={
            [
              {
                label: this.context.t('list_of_companies'),
                route: "company"
              },
              {
                label: this.context.t("create_company"),
                route: "company/create"
              }
            ]}
          />
          <h1>{this.context.t('create_company')}</h1>
          <div className="section">
            <CompanyInfos />
            <CompanyLocation />
            <CompanyBank />
            <CompanyGarage />
            <CompanyActivity />
            <div>
              <div>
                <Button
                  isCancel
                  label={this.context.t("cancel")}
                  onClick={() => this.props.clearCurrentCompany()}
                />
                <Button
                  label={this.context.t("confirm")}
                  onClick={() => this.onClickConfirm()}
                />
              </div>
            </div>
          </div>
        </Form>
      </Template>
    );
  }
}

CompaniesCreate.contextTypes = {
  t: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
  user: state.currentUser,
  company: state.company.currentCompany ? state.company.currentCompany : {}
})
const mapDispatchToProps = (dispatch) => ({
  push: (route) => dispatch(push(route)),
  clearCurrentCompany: () => dispatch({
    type: CompanyType.COMPANY_CREATE_CLEAR_CURRENT
  })
})

export default connect(mapStateToProps, mapDispatchToProps)(CompaniesCreate);
