import ConversationsType from '../types/conversations';

export default (state = {notif: 0}, action) => {
  let conversations, conv, user;
  switch (action.type){
    case ConversationsType.CONVERSATIONS_ADD_MESSAGE :
      conv = state.conversations.find(c => c.id === action.payload.conversation.id);
      if (conv) {
        conv.messages = [...conv.messages, action.payload];
        conversations = state.conversations.filter(c => c.id !== conv.id);
        conversations = [conv, ...conversations];
      } else {
        conv = action.payload.conversation;
        conv.messages = [...conv.messages, action.payload]
        conversations = [conv, ...state.conversations]
      }
      return {
        ...state,
        conversations
      }
    case ConversationsType.SET_CONVERSATION : 
      conv = action.payload.conversation;
      user = action.payload.user;
      conversations = state.conversations;
      if (conv) {
        conversations = conversations.filter(c => c.id !== conv.id);
        for(let i = 0; i < conv.participants.length; i++) {
          if (conv.participants[i].id === user.id) {
            conversations = [conv, ...conversations];
            break;
          }
        }
      }
      return {
        ...state,
        conversations
      }
    case ConversationsType.CONVERSATIONS_SET_LIST:
        conversations = action.payload;
        return {
            ...state,
            conversations
        }
    default:
      return {
        ...state
      }
  }
}
