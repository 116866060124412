import React from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import PropTypes from 'prop-types';
import MaterialIcon from 'material-icons-react';
import _ from 'lodash';
import './styles.scss';

import Company from '../../core/Company';
import CompanyType from '../../redux/types/company';

import Template from '../../templates/Backoffice';
import HeaderButtons from '../../components/HeaderButtons';
import Label from '../../components/Label';
import SearchBar from '../../components/SearchBar';

class Companies extends React.Component{

  constructor(props){
      super(props);
      this.state = {
          searchOption: {label: "company_name", action: this.searchCompanyName},
          searchList: undefined
      }
  }

  componentDidMount(){
    if(_.get(this.props, 'user.token', undefined) !== undefined)
      this.props.updateCompanyList(this.props.user.token);
  }

  searchCompanyName = (companyName) => {
    if (companyName !== null && companyName !== undefined && companyName !== "") {
      this.setState({ loading: true }, () => {
          new Company().setToken(this.props.user.token).findByName(companyName).then(companies => {
            this.setState({ searchList: companies, loading: false });
          })
      })
    } else {
      this.setState({ searchList: undefined, loading: false });
    }
  }

  renderCompany = (elem, i) => {
    return (
      <div className="company" key={i}>
        <MaterialIcon color="rgb(34, 109, 175)" icon="apartment" />
        <div onClick={() => this.props.push("/company/" + elem.id)}>
           {elem.name + (elem.garage ? elem.rental_car ? " (Garage avec location)" : " (Garage)" : "")}
        </div>
        <div><a target="_blank" rel="noopener noreferrer" href={"https://www.google.com/maps?q=" + elem.latitude + "," + elem.longitude }><MaterialIcon color="rgb(34, 109, 175)" icon="place" /></a></div>
      </div>
    );
  }

  showCompaniesList = () => {
    return (
      <div>
        {
          this.state.loading && <Label value={this.context.t('loading')} />
        }
        {
          !this.state.loading && !this.state.searchList && <h2>{this.context.t('my_company')}</h2>
        }
        {
          !this.state.loading && !this.state.searchList && this.props.companies.filter(company => company.id === this.props.user.user.company.id).map(this.renderCompany)
        }
        {
          !this.state.loading && !this.state.searchList && <h2>{this.context.t('others_companies')}</h2>
        }
        {
          !this.state.loading && !this.state.searchList && this.props.companies.filter(company => company.id !== this.props.user.user.company.id).map(this.renderCompany)
        }
        {
          !this.state.loading && this.state.searchList && this.state.searchList.length > 0 && this.state.searchList.map(this.renderCompany)
        }
        {
          !this.state.loading && this.state.searchList && this.state.searchList.length === 0 && <Label value={this.context.t('no_company_in_search')} />
        }
      </div>
    )
  }

  render(){
    return(
      <Template>
        <div className="companies-page">
          <HeaderButtons tabList={[
            {
              label: this.context.t('list_of_companies'),
              route: "company"
            },
            {
              label: this.context.t("create_company"),
              route: "company/create"
            }
          ]}
        />
        <SearchBar
          searchOptions={[
              {label: "company_name", action: this.searchCompanyName}
          ]}
          onChooseSearchOption={(option) => this.setState({searchOption: option})}
          getSearchValue={(value) => this.setState({searchValue: value})}
          current={this.state.searchOption}
        />
        {
          this.props.companies === undefined ?
            <Label value={this.context.t('loading')} /> :
            this.props.companies.length === 0 ? <Label value={this.context.t('no_companie')} /> : this.showCompaniesList()
        }
        </div>
      </Template>
    );
  }
}

Companies.contextTypes = {
  t: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
  user: state.currentUser,
  companies: state.company.list
})
const mapDispatchToProps = (dispatch) => ({
  push: (route) => dispatch(push(route)),
  updateCompanyList: (token) => {
    new Company().setToken(token).findAll().then(companies => {
      dispatch({
        type: CompanyType.COMPANY_UPDATE_LIST,
        payload: companies
      })
    })
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(Companies);
