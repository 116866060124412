import UsersTypes from '../types/users.js';

export default (state = {}, action) => {
  switch (action.type){
    case UsersTypes.USERS_UPDATE_LIST:
      return {
        ...state,
        list: action.payload
      }
    default:
      return {
        ...state
      }
  }
}
