import React from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import PropTypes from 'prop-types';

import OrderListType from '../../redux/types/order_list';
import OrderType from '../../redux/types/order';
import TowTruckType from '../../redux/types/towtruck';
import VehicleToUpdateType from '../../redux/types/vehicles_to_update';

import Order from '../../core/Order';

import NavBar from '../../components/NavBar';
import StatusBar from '../../components/StatusBar';
import Modal from '../../components/OrderModal';

import './styles.scss';

class BackofficeTemplate extends React.Component{

  constructor(props){
    super(props);
    this.state = {

    }
  }

  componentDidMount(){
      if (!this.props.user.token) this.props.push('/login');
  }

  setOrderDispatchModalData = () => {
    let orders = this.props.modalData.orders;
    let modalOrder = undefined;
    if (this.props.modalData.modalOrder === undefined || this.props.modalData.modalOrder === null) {
      if (orders.length > 0) {
        modalOrder = orders.pop();
        this.props.setModalData({ orders, modalOrder })
      }
    }
  }

  onConfirmModal = () => {
    new Order().setToken(this.props.user.token).dispatchOrderToCompany(this.props.modalData.modalOrder.id, this.props.user.user.company.id).then(() => {
      this.props.push("/order/" + this.props.modalData.modalOrder.id);
      this.props.setModalData({modalOrder: undefined});
      this.setOrderDispatchModalData();
    })
  }

  render(){
    return(
      <div className="backoffice">
        <NavBar settingsBadgeNumber={this.props.settingsBadgeNumber}/>
        <Modal order={this.props.modalData.modalOrder} onConfirm={this.onConfirmModal} onCancel={() => this.props.setModalData({modalOrder: undefined}, () => this.setOrderDispatchModalData())}/>
        <div className="container">
          <StatusBar />
          {this.props.children}
        </div>
      </div>
    );
  }
}

BackofficeTemplate.contextTypes = {
  t: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
  settingsBadgeNumber: state.vehicleToUpdate.length,
  user: state.currentUser,
  orders: state.orderList,
  towtrucks: state.towtruck,
  modalData: state.order.modalData
});
const mapDispatchToProps = (dispatch) => ({
  push: (route) => dispatch(push(route)),
  addOrderToList: (order) => dispatch({
    type: OrderListType.ORDERLIST_ADD_ORDER,
    payload: order
  }),
  updateAnOrder: (order) => dispatch({
    type: OrderListType.ORDERLIST_UPDATE_AN_ORDER,
    payload: order
  }),
  updateATowTruck: (towtruck) => dispatch({
    type: TowTruckType.TOWTRUCK_UPDATE_A_TOWTRUCK,
    payload: towtruck
  }),
  setModalData: (modalData = {}) => dispatch({
    type: OrderType.ORDER_FILL_ORDER_MODAL,
    payload: modalData
  }),
  setVehicleToUpdate: (vehicles) => dispatch({
    type: VehicleToUpdateType.SET_VEHICLES_TO_UPDATE_LIST,
    payload: vehicles
  })
})

export default connect(mapStateToProps, mapDispatchToProps)(BackofficeTemplate);
