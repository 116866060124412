import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Input from '../../components/Input';
import Button from '../../components/Buttons/FormButton';

import './styles.scss';
import OrderType from '../../redux/types/order';

class AdvanceOfCost extends React.Component{

  constructor(props){
    super(props);
    this.state = {
        commission: 0
    };
  }

  render(){
      return(
        <div>
          <h2>{this.context.t('question_advance_of_cost')}</h2>
          <div className="flex">
            <Input
              type="number"
              getValue={value => this.props.setAdvanceOfCost(value)}
              placeholder={this.context.t('advance_of_cost')}
              priorityValue={this.props.advanceOfCost}
              style={{ flex: 1, marginRight: 10 }}
            />
            <Button
              label={this.props.advanceOfCostTva ? "TVA 20%" : "TVA 0%"}
              style={{ width: 100, marginTop: 10 }}
              selected={this.props.advanceOfCostTva}
              onClick={() => this.props.setAdvanceOfCostTva(!this.props.advanceOfCostTva)}
            />
          </div>
        </div>
      );
    }
}

AdvanceOfCost.contextTypes = {
  t: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
  token: state.currentUser.token,
  advanceOfCost: state.order.advance_of_cost,
  advanceOfCostTva: state.order.advance_of_cost_tva,
  order: state.order,
})

const mapDispatchToProps = (dispatch) => ({
    setAdvanceOfCost: (aoc) => dispatch({
        type: OrderType.ORDER_SET_ADVANCE_OF_COST,
        payload: aoc < 0 || aoc === "" ? 0 : aoc
    }),
    setAdvanceOfCostTva: (tva) => dispatch({
      type: OrderType.ORDER_SET_ADVANCE_OF_COST_TVA,
      payload: tva ? 1 : 0
    })
})

export default connect(mapStateToProps, mapDispatchToProps)(AdvanceOfCost);
