import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';

import Autocomplete from '../../components/Autocomplete';
import Button from '../../components/Buttons/FormButton';
import Label from '../../components/Label';

import './styles.scss';
import OrderType from '../../redux/types/order';

class Path extends React.Component{

  constructor(props){
    super(props);
    this.state = {
      arrival_address: this.props.arrival_address,
      departure_address: this.props.departure_address
    };
  }

  getDeparture = (departure) => {
    if (departure) {
        this.setState({
          departure_address: departure.address
        }, () => {
          if(this.state.arrival_address === ""){
            this.props.setDeparture({
              address: departure.address,
              coords: {
                lng: departure.coords[0],
                lat: departure.coords[1]
              }
            });
            this.props.setArrival({
              address: "on_site",
              coords: {
                lng: departure.coords[0],
                lat: departure.coords[1]
              }
            })
          }
          else {
            this.props.setDeparture({
              address: departure.address,
              coords: {
                lng: departure.coords[0],
                lat: departure.coords[1]
              }
            })
          }
        })
    } else {
        this.setState({ departure_address: undefined }, () => {
            this.props.setDeparture(undefined);
        })
    }
  }

  onClickOnSite = () => {
    this.setState({
      arrival_address: ""
    }, () => {
      this.props.setArrival({
        address: "on_site",
        coords: {
          lng: _.get(this.props, "order.path.departure.coords.lng", ""),
          lat: _.get(this.props, "order.path.departure.coords.lat", "")
        }
      })
    })
  }

  getArrival = (arrival) => {
    if (arrival) {
        this.setState({
          arrival_address: arrival.address,
        }, () => {
          this.props.setArrival({
            address: arrival.address,
            coords: {
              lng: arrival.coords[0],
              lat: arrival.coords[1]
            }
          })
        })
    }
  }

  componentDidUpdate(prevProps){
    if(_.get(this.props, "order.path.departure.address", "") !== _.get(prevProps, "order.path.departure.address", "") && _.get(this.props, "order.path.arrival.address", "") !== _.get(prevProps, "order.path.arrival.address", "")){
      this.setState({
        departure_address: _.get(this.props, "order.path.departure.address", ""),
        arrival_address: _.get(this.props, "order.path.arrival.address", "")
      })
    }
    else if (_.get(this.props, "order.path.departure.address", "") !== _.get(prevProps, "order.path.departure.address", "")) {
      this.setState({
        departure_address: _.get(this.props, "order.path.departure.address", "")
      })
    }
    else if (_.get(this.props, "order.path.arrival.address", "") !== _.get(prevProps, "order.path.arrival.address", "")) {
      this.setState({
        arrival_address: _.get(this.props, "order.path.arrival.address", "")
      })
    }
  }

  _handleDepartureOnBlur = (e) => {
      if (this.state.error_departure && /[0-9A-Za-z-.,_]{5,}/.test(this.props.departure_address)) {
          this.setState({ error_departure: false })
      } else {
          if (!/[0-9A-Za-z-.,_]{5,}/.test(this.props.departure_address)) {
              this.setState({ error_departure: true })
          } else {
              this.setState({ error_departure: false })
          }
      }
  }

  render(){
    if(this.props.departure_address === ""){
      return(
        <div>
          <h2>{this.context.t('question_about_path')}</h2>
          {
              this.state.error_departure && <span className="error-message">{this.context.t("error_departure")}</span>
          }
          <Autocomplete
            placeholder={this.context.t("departure_address")}
            getValue={(value) => this.getDeparture(value)}
            priorityValue={this.state.departure_address}
            onBlur={this._handleDepartureOnBlur}
            error={this.state.error_departure}
          />
        </div>
      );
    }
    else {
      return(
        <div>
          <h2>{this.context.t('question_about_path')}</h2>
          {
              this.state.error_departure && <span className="error-message">{this.context.t("error_departure")}</span>
          }
          <Autocomplete
            placeholder={this.context.t("departure_address")}
            getValue={(value) => this.getDeparture(value)}
            defaultValue={this.state.departure_address}
            onBlur={this._handleDepartureOnBlur}
            error={this.state.error_departure}
          />
          <Label
            value={"Pour un dépannage sur place, ne pas remplir le champ : " + this.context.t('arrival_address')}
          />

          <Button
            label={this.context.t("on_site")}
            onClick={() => this.onClickOnSite()}
            selected={_.get(this.props, "order.path.arrival.address", "") === "on_site" ? true : false}
          />
          <Autocomplete
            placeholder={this.context.t("arrival_address")}
            getValue={(value) => this.getArrival(value)}
            defaultValue={this.state.arrival_address !== "on_site" ? this.state.arrival_address : ""}
          />
        </div>
      );
    }
  }
}

Path.contextTypes = {
  t: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
  order: state.order,
  departure_address: _.get(state, "order.path.departure.address", ""),
  arrival_address: _.get(state, "order.path.arrival.address", ""),
})

const mapDispatchToProps = (dispatch) => ({
  setDeparture: (depObj) => dispatch({
    type: OrderType.ORDER_SELECT_DEPARTURE,
    payload: {
      address: _.get(depObj, 'address', ""),
      coords: {
        lat: _.get(depObj, 'coords.lat', undefined),
        lng: _.get(depObj, 'coords.lng', undefined)
      }
    }
  }),
  setArrival: (arrObj) => dispatch({
    type: OrderType.ORDER_SELECT_ARRIVAL,
    payload: {
      address: _.get(arrObj, 'address', ""),
      coords: {
        lat: _.get(arrObj, 'coords.lat', undefined),
        lng: _.get(arrObj, 'coords.lng', undefined)
      }
    }
  })
})

export default connect(mapStateToProps, mapDispatchToProps)(Path);
