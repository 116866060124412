import React from 'react';

import './styles.scss';

class Image extends React.Component{
  render(){
    return(
      <div className="basic-image" style={{
        width: this.props.width ? this.props.width : "auto",
        height: this.props.height ? this.props.height : "auto",
        margin: this.props.margin ? this.props.margin : 0
      }}>
        <img
          src={this.props.src}
          alt={this.props.alt}
        />
      </div>
    )
  }
}

export default Image;
