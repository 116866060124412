import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Input from '../../components/Input';

import './styles.scss';
import B2BCreateType from '../../redux/types/b2b_create_type';

class B2BPrices extends React.Component{

  constructor(props){
      super(props);
      this.state = {};
  }

  render(){
      return(
        <div>
          <h2>{this.context.t('question_b2b_company_prices_options')}</h2>
          <Input priorityValue={this.props.b2b.hourPoliceStation > 0 && this.props.b2b.hourPoliceStation} type="number" min={0} placeholder={this.context.t("hour_police_station")} getValue={(value) => this.props.updateB2B("hourPoliceStation", value)} />
          <Input priorityValue={this.props.b2b.hourBasement > 0 && this.props.b2b.hourBasement} type="number" min={0} placeholder={this.context.t("price_basement")} getValue={(value) => this.props.updateB2B("hourBasement", value)} />
          <Input priorityValue={this.props.b2b.cleaning > 0 && this.props.b2b.cleaning} type="number" min={0} placeholder={this.context.t("price_cleaning")} getValue={(value) => this.props.updateB2B("cleaning", value)} />
          <Input priorityValue={this.props.b2b.hourHandling > 0 && this.props.b2b.hourHandling} type="number" min={0} placeholder={this.context.t("hour_handling")} getValue={(value) => this.props.updateB2B("hourHandling", value)} />
          <Input priorityValue={this.props.b2b.advanceOfCostCommission} type="number" min={0} placeholder={this.context.t("commission_on_advance_of_costs")} getValue={(value) => this.props.updateB2B("advanceOfCostCommission", value)} />
        </div>
      );
    }
}

B2BPrices.contextTypes = {
  t: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = (dispatch) => ({
  setPricePoliceStation: (price) => dispatch({ type: B2BCreateType.B2B_CREATE_SET_PRICE_POLICE_STATION, payload: price }),
  setPriceHandling: (price) => dispatch({ type: B2BCreateType.B2B_CREATE_SET_PRICE_HANDLING, payload: price }),
  setPriceBasement: (price) => dispatch({ type: B2BCreateType.B2B_CREATE_SET_PRICE_BASEMENT, payload: price }),
  setPriceCommission: (price) => dispatch({ type: B2BCreateType.B2B_CREATE_SET_PRICE_ADVANCE_OF_COST, payload: price }),
})

export default connect(mapStateToProps, mapDispatchToProps)(B2BPrices);
