import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';

import OrderType from '../../redux/types/order';

import TextArea from '../../components/TextArea';

import './styles.scss';

class Comment extends React.Component{
  render(){
      return(
        <div>
          <h2>{this.context.t('question_comment')}</h2>
          <TextArea
            getValue={value => this.setState({note: value}, () => this.props.setOrderComment(value))}
            placeholder={this.context.t('comment')}
            priorityValue={this.props.note}
          />
        </div>
      );
    }
}

Comment.contextTypes = {
  t: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
  token: state.currentUser.token,
  note: _.get(state, "order.note", ""),
})

const mapDispatchToProps = (dispatch) => ({
  setOrderComment: (comment) => dispatch({
    type: OrderType.ORDER_ADD_COMMENT,
    payload: {
      note: comment
    }
  })
})

export default connect(mapStateToProps, mapDispatchToProps)(Comment);
