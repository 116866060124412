import OrderType from '../types/order';
import { vehicleType } from '../../constants';
import { problem, reason } from '../../constants';

const orderReducer = (state = {advance_of_cost: 0, advance_of_cost_tva: 1, is_b2b: false, modalData: {modalOrder: undefined, orders: []}}, action) => {
  switch(action.type){
    case OrderType.ORDER_SET_ADVANCE_OF_COST :
      return {
        ...state,
        advance_of_cost: action.payload
      }
    case OrderType.ORDER_SET_ADVANCE_OF_COST_TVA :
      return {
        ...state,
        advance_of_cost_tva: action.payload
      }
    case OrderType.ORDER_SET_REF_NUMBER : 
      return {
        ...state,
        ref: action.payload
      }
    case OrderType.ORDER_SELECT_DISCOUNT :
      return {
        ...state,
        discount: action.payload
      }
    case OrderType.ORDER_SELECT_B2B_COMPANY_INFOS :
      return {
        ...state,
        customer_infos: {
          ...state.customer_infos,
          b2b_company_id: action.payload.id,
          b2b_company_name: action.payload.company_name,
          phonenumber: action.payload.phonenumber,
          email: action.payload.email,
          bill_address: action.payload.address,
          b2b_company: action.payload
        }
      }
    case OrderType.ORDER_SELECT_ROLLING :
      return {
        ...state,
        rolling: action.payload.rolling ? true : false,
        problem: action.payload.rolling ? problem.OTHER : undefined,
        reason: action.payload.rolling ? reason.OTHER : undefined
      }
    case OrderType.ORDER_TOOGLE_B2B:
      return {
        ...state,
        advance_of_cost: 0,
        is_b2b: !state.is_b2b,
        rolling: !state.is_b2b === false ? undefined : state.rolling,
        problem: !state.is_b2b === false ? undefined : state.problem,
        reason: !state.is_b2b === false ? undefined : state.reason,
        ref: !state.is_b2b === false ? undefined : state.ref,
        customer_infos: {
          ...state.customer_infos,
          b2b_company_id: undefined,
          firstname: undefined,
          name: undefined,
          phonenumber: undefined,
          email: undefined,
          bill_address: undefined,
          b2b_company: undefined
        },
        path: {
          ...state.path,
          police_station: undefined
        }
      }
    case OrderType.ORDER_SELECT_PROBLEM:
      return {
        ...state,
        problem: action.payload.problem,
        reason: undefined
      }
    case OrderType.ORDER_SELECT_REASON:
      return {
        ...state,
        reason: action.payload.reason
      }
    case OrderType.ORDER_SELECT_DATE:
      return {
        ...state,
        date: action.payload
      }
    case OrderType.ORDER_ADD_COMMENT:
      return {
        ...state,
        note: action.payload.note
      }
    case OrderType.ORDER_SELECT_VEHICLE_TYPE:
      return {
        ...state,
        vehicle_type: action.payload.vehicle_type,
        limited_height: action.payload.vehicle_type >= vehicleType.LIGHT_PL ? false : state.limited_height
      }
    case OrderType.ORDER_SELECT_LIMITED_HEIGHT:
      return {
        ...state,
        limited_height: (action.payload.limited_height ? true : false)
      }
    case OrderType.ORDER_SELECT_VEHICLE_BRAND:
      return {
        ...state,
        vehicle_infos: {
          ...state.vehicle_infos,
          brand: action.payload.brand
        }
      }
    case OrderType.ORDER_SELECT_VEHICLE_MODEL:
      return {
        ...state,
        vehicle_infos: {
          ...state.vehicle_infos,
          model: action.payload.model
        }
      }
    case OrderType.ORDER_SELECT_VEHICLE_REGISTRATION:
      return {
        ...state,
        vehicle_infos: {
          ...state.vehicle_infos,
          registration: action.payload.registration
        }
      }
    case OrderType.ORDER_SELECT_ARRIVAL:
      return {
        ...state,
        path: {
          ...state.path,
          arrival: action.payload
        }
      }
    case OrderType.ORDER_SELECT_DEPARTURE:
      return {
        ...state,
        path: {
          ...state.path,
          departure: action.payload
        }
      }
    case OrderType.ORDER_UNSET_POLICE_STATION:
      return {
        ...state,
        path: {
          ...state.path,
          police_station: undefined
        }
      }
    case OrderType.ORDER_SELECT_POLICE_STATION:
        return {
          ...state,
          path: {
            ...state.path,
            police_station: action.payload
          }
        }
    case OrderType.ORDER_SELECT_CUSTOMER_BILL_ADDRESS :
        return {
          ...state,
          customer_infos: {
            ...state.customer_infos,
            bill_address: action.payload.bill_address
          }
        }
    case OrderType.ORDER_SELECT_B2B_COMPANY_NAME:
      return {
        ...state,
        customer_infos: {
          ...state.customer_infos,
          b2b_company_name: action.payload.b2b_company_name,
          name: undefined,
          firstname: undefined
        }
      }
    case OrderType.ORDER_SELECT_CUSTOMER_NAME:
      return {
        ...state,
        customer_infos: {
          ...state.customer_infos,
          name: action.payload.name,
          b2b_company_id: undefined
        }
      }
    case OrderType.ORDER_SELECT_CUSTOMER_FIRSTNAME:
      return {
        ...state,
        customer_infos: {
          ...state.customer_infos,
          firstname: action.payload.firstname,
          b2b_company_id: undefined
        }
      }
    case OrderType.ORDER_SELECT_CUSTOMER_PHONENUMBER:
      return {
        ...state,
        customer_infos: {
          ...state.customer_infos,
          phonenumber: action.payload.phonenumber
        }
      }
    case OrderType.ORDER_SELECT_CUSTOMER_EMAIL:
      return {
        ...state,
        customer_infos: {
          ...state.customer_infos,
          email: action.payload.email
        }
      }
    case OrderType.ORDER_SELECT_FINAL_PRICE:
      return {
        ...state,
        price: action.payload.price
      }
    case OrderType.COMPANY_CREATE_SET_COMPANY:
      return {
        ...state,
        companyIdentifier: action.payload.id,
        company_name: action.payload.name
      }
    case OrderType.ORDER_SELECT_PAYMENT_METHOD:
      return {
        ...state,
        payment_method: action.payload.payment_method
      }
    case OrderType.COMPANY_CREATE_SET_DRIVER:
      return {
        ...state,
        driverIdentifier: action.payload.id,
        driver_fullname: action.payload.fullname
      }
    case OrderType.ORDER_SELECT_IF_ITS_PAID:
      return {
        ...state,
        paid: action.payload.paid ? 1 : 0
      }
    case OrderType.ORDER_CANCEL_CREATE_ORDER:
      return {modalData: {modalOrder: state.modalData.modalOrder ? state.modalData.modalOrder : undefined, orders: state.modalData.orders ? state.modalData.orders : []}}
    case OrderType.ORDER_FILL_ORDER_MODAL:
      return {
        ...state,
        modalData: {
          modalOrder: action.payload.modalOrder ? action.payload.modalOrder : undefined,
          orders: action.payload.orders ? action.payload.orders : state.modalData.orders
        }
      }
    default:
      return state;
  }
}

export default orderReducer;
