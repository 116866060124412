import CompanyType from '../types/company';

const companyReducer = (state = {}, action) => {
  switch(action.type){
    case CompanyType.COMPANY_UPDATE_LIST:
      return {
        ...state,
        list: action.payload
      }
    case CompanyType.COMPANY_CREATE_SET_NAME:
      return {
        ...state,
        currentCompany: {
          ...state.currentCompany,
          name: action.payload
        }
      }
    case CompanyType.COMPANY_CREATE_SET_LOGO:
      return {
        ...state,
        currentCompany: {
          ...state.currentCompany,
          logo: action.payload
        }
      }
    case CompanyType.COMPANY_CREATE_SET_PHONENUMBER:
      return {
        ...state,
        currentCompany: {
          ...state.currentCompany,
          phonenumber: action.payload
        }
      }
    case CompanyType.COMPANY_CREATE_SET_EMAIL:
      return {
        ...state,
        currentCompany: {
          ...state.currentCompany,
          email: action.payload
        }
      }
    case CompanyType.COMPANY_CREATE_SET_SIRET:
      return {
        ...state,
        currentCompany: {
          ...state.currentCompany,
          siret: action.payload
        }
      }
    case CompanyType.COMPANY_CREATE_SET_LOCATION:
      return {
        ...state,
        currentCompany: {
          ...state.currentCompany,
          address: action.payload.address,
          latitude: action.payload.latitude,
          longitude: action.payload.longitude
        }
      }
    case CompanyType.COMPANY_CREATE_SET_GARAGE:
      return {
        ...state,
        currentCompany: {
          ...state.currentCompany,
          garage: action.payload ? 1 : 0,
          bodyWork: 0,
          mechanics: 0,
          paint: 0,
          rentalCar: 0
        }
      }
    case CompanyType.COMPANY_CREATE_SET_BODYWORK:
      return {
        ...state,
        currentCompany: {
          ...state.currentCompany,
          bodyWork: action.payload === true || action.payload === 1 ? 1 : 0
        }
      }
    case CompanyType.COMPANY_CREATE_SET_MECHANICS:
      return {
        ...state,
        currentCompany: {
          ...state.currentCompany,
          mechanics: action.payload === true || action.payload === 1 ? 1 : 0
        }
      }
    case CompanyType.COMPANY_CREATE_SET_PAINT:
      return {
        ...state,
        currentCompany: {
          ...state.currentCompany,
          paint: action.payload === true || action.payload === 1 ? 1 : 0
        }
      }
    case CompanyType.COMPANY_CREATE_SET_RENTALCAR:
      return {
        ...state,
        currentCompany: {
          ...state.currentCompany,
          rentalCar: action.payload === true || action.payload === 1 ? 1 : 0
        }
      }
    case CompanyType.COMPANY_CREATE_SET_IBAN:
      return {
        ...state,
        currentCompany: {
          ...state.currentCompany,
          iban: action.payload
        }
      }
    case CompanyType.COMPANY_CREATE_SET_DEFAULT_COMMISSION: {
      return {
        ...state,
        currentCompany: {
          ...state.currentCompany,
          defaultCommission: action.payload
        }
      }
    }
    case CompanyType.COMPANY_CREATE_CLEAR_CURRENT:
      return {
        ...state,
        currentCompany: {}
      }
    default:
      return state;
  }
}

export default companyReducer;
