import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

import './styles.scss';

class HeaderButtons extends React.Component{
  render(){
    let tabList = this.props.tabList ? this.props.tabList : [];
    return (
      <div className="header-buttons">
        {
          tabList.map((elem, i) => {
            return (
              <div key={i} className={this.props.route === "/" + elem.route || this.props.route === elem.route ? "active" : ""} onClick={() => this.props.push(elem.route.substr(0, 1) === "/" ? elem.route : "/" + elem.route)}>
                {elem.label}
              </div>
            );
          })
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  route: state.routing.locationBeforeTransitions.pathname
})

const mapDispatchToProps = (dispatch) => ({
  push: (route) => dispatch(push(route))
})

HeaderButtons.propTypes = {
  tabList: PropTypes.array.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(HeaderButtons);
