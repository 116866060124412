import React from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import _ from 'lodash';
import PropTypes from 'prop-types';
import env from '../../env';

import OrderListType from '../../redux/types/order_list';
import OrderType from '../../redux/types/order';
import TowTruckType from '../../redux/types/towtruck';
import VehicleToUpdateType from '../../redux/types/vehicles_to_update';

import Mercure from '../../core/Mercure';
import Company from '../../core/Company';
import Vehicle from '../../core/Vehicle';

import SoundFile from "../../assets/sounds/alertegenerale.mp3";
import IconFile from "../../assets/images/favicon.png";
import ConversationsType from '../../redux/types/conversations';

class MercureTemplate extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      eventSourceOrders: null,
      eventSourceOrdersUpdate: null,
      eventSourceTowTruck: null,
      eventSourceDispatch: null,
      eventSourceConversations: new Array()
    }
  }

  componentWillUnmount(){
    let token = localStorage.getItem('depannmoi_user_token');
    if(token || _.get(this.props, 'user.token', false)) {
        if (this.state.eventSourceOrders) this.state.eventSourceOrders.close();
        if (this.state.eventSourceOrdersUpdate) this.state.eventSourceOrdersUpdate.close();
        if (this.state.eventSourceTowTruck) this.state.eventSourceTowTruck.close();
        if (this.state.eventSourceDispatch) this.state.eventSourceDispatch.close();
        if (this.state.eventSourceConversation) this.state.eventSourceConversation.close();
        this.state.eventSourceConversations.forEach(eventSource => eventSource.close());
    }
  }

  componentDidUpdate(prevProps){
      if (!_.get(prevProps, 'user.user.company.id') && _.get(this.props, 'user.user.company.id')) {
          this.setState({
            eventSourceOrders: new Mercure().realTimeOrders(),
            eventSourceOrdersUpdate: new Mercure().realTimeOrdersUpdated(),
            eventSourceTowTruck: new Mercure().realTimeTowTrucks(),
            eventSourceDispatch: new Mercure().realTimeDispatch(this.props.user.user.company.id),
            eventSourceConversation: new Mercure().realTimeConversation()
          }, () => {
            this.state.eventSourceDispatch.addEventListener('message', (e) => {
              if (JSON.parse(e.data).cancelled === false) {
                this.props.setModalData({
                  orders: [JSON.parse(e.data), ...this.props.modalData.orders]
                });
                this.setOrderDispatchModalData();
              }
            });
            this.state.eventSourceConversation.addEventListener('message', (e) => {
              let conversation = JSON.parse(e.data);
              this.props.addOrUpdateConversation(conversation, this.props.user.user);
            })
            this.state.eventSourceOrders.addEventListener('message', (e) => {
              let order = JSON.parse(e.data);
              this.props.addOrderToList(order);
              if (env.debug) console.log("eventSourceOrders : ", order)
              new Vehicle().setToken(this.props.user.token).findVehiclesToBeUpdated().then(vehicles => {
                this.props.setVehicleToUpdate(vehicles);
              })
              try {
                new Notification("Dépann'moi", {body: this.context.t('notification_new_order_in_the_list'), icon: IconFile});
              } catch (e) {
                alert(this.context.t('notification_new_order_in_the_list'));
              }
              if(window.innerWidth > 500){
                try {
                  new Audio(SoundFile).play().catch(() => console.error("Audio file not working..."));
                } catch (e) {}
              }
            });
            this.state.eventSourceOrdersUpdate.addEventListener('message', (e) => {
              let order = JSON.parse(e.data);
              if (env.debug) console.log("Order Updated : ", order)
              if (order.company && order.company.id) {
                order.company.employes = [];
                new Company().setToken(this.props.user.token).getEmployes(order.company.id).then(data => {
                  order.company.employes = data;
                  this.props.updateAnOrder(order)
                });
              } else {
                this.props.updateAnOrder(order);
              }
            });
            this.state.eventSourceTowTruck.addEventListener('message', (e) => {
              this.props.updateATowTruck(JSON.parse(e.data));
            });
          });

          
          if (this.props.conversations) {
            let eventSourceConversations = new Mercure().realTimeConversationMessage();
            eventSourceConversations.addEventListener('message', (e) => {
                let message = JSON.parse(e.data);
                let user = this.props.user.user;
                let participants = message.conversation.participants;
                for(let i = 0; i < participants.length; i++) {
                  if (user.id === participants[i].id) {
                    return this.props.addConversationMessage(message);
                  }
                }
            });
            this.setState({ eventSourceConversations });
          }
      }
  }

  componentDidMount(){
    if(_.get(this.props, 'user.token', false)) this.props.push("/login");
  }

  setOrderDispatchModalData = () => {
    let orders = this.props.modalData.orders;
    let modalOrder = undefined;
    if (this.props.modalData.modalOrder === undefined || this.props.modalData.modalOrder === null) {
      if (orders.length > 0) {
        modalOrder = orders.pop();
        this.props.setModalData({ orders, modalOrder })
      }
    }
  }

  render(){ return this.props.children; }
}

MercureTemplate.contextTypes = {
  t: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
  settingsBadgeNumber: state.vehicleToUpdate.length,
  user: state.currentUser,
  orders: state.orderList,
  towtrucks: state.towtruck,
  modalData: state.order.modalData,
  conversations: state.messages.conversations
});
const mapDispatchToProps = (dispatch) => ({
  push: (route) => dispatch(push(route)),
  addOrUpdateConversation: (conversation, user) => dispatch({
    type: ConversationsType.SET_CONVERSATION,
    payload: {
      conversation, user
    }
  }),
  addConversationMessage: (message) => dispatch({
    type: ConversationsType.CONVERSATIONS_ADD_MESSAGE,
    payload: message
  }),
  addOrderToList: (order) => dispatch({
    type: OrderListType.ORDERLIST_ADD_ORDER,
    payload: order
  }),
  updateAnOrder: (order) => dispatch({
    type: OrderListType.ORDERLIST_UPDATE_AN_ORDER,
    payload: order
  }),
  updateATowTruck: (towtruck) => dispatch({
    type: TowTruckType.TOWTRUCK_UPDATE_A_TOWTRUCK,
    payload: towtruck
  }),
  setModalData: (modalData = {}) => dispatch({
    type: OrderType.ORDER_FILL_ORDER_MODAL,
    payload: modalData
  }),
  setVehicleToUpdate: (vehicles) => dispatch({
    type: VehicleToUpdateType.SET_VEHICLES_TO_UPDATE_LIST,
    payload: vehicles
  })
})

export default connect(mapStateToProps, mapDispatchToProps)(MercureTemplate);
